@use "../global" as *;
/*=================================
  sateiBox
=================================*/
.sateiBox {
  padding: 20px;
  margin: 85px 0 100px;
  border-radius: 20px;
  background: #fff;
  background-size: contain;
  box-shadow: 0 5px 25px rgba(48, 74, 133, .12);
  &__inner {
    border-radius: 10px;
    background: $color-theme-light;
    padding: 50px 40px;
    position: relative;
    &::after {
      content: "";
      width: 242px;
      height: 385px;
      display: inline-block;
      background: url(../img/common/img_sateiBox.png) no-repeat center center;
      background-size: contain;
      position: absolute;
      right: 0;
      bottom: 0;
      @include mq(tab) {
        width: vw(242);
        height: vw(385);
      }
    }
  }
  &__ttl {
    font-size: 4.5rem;
    font-weight: 700;
    line-height: 1.25;
    padding-left: 27px;
    position: relative;
    &::before {
      content: "";
      width: 12px;
      height: 12px;
      display: inline-block;
      vertical-align: middle;
      border-radius: 50px;
      background: $color-theme;
      position: absolute;
      top: 25px;
      left: 0;
    }
  }
  &__text {
    font-size: 1.6rem;
    line-height: 2;
    margin: 7px 0 0;
  }
  &__form {
    margin: 10px 0 0;
    form {
      display: flex;
      flex-wrap: wrap;
      position: relative;
      z-index: 1;
    }
    .selectWrap {
      display: flex;
      flex-wrap: wrap;
      max-width: 550px;
      @include mq(tab) {
        max-width: 500px;
      }
      .design-select-box {
        font-size: 1.6rem;
        border: none;
        background: url(../img/common/icon/icon_select_down.svg) no-repeat right 22px center #fff;
        background-size: 14px auto;
        border-radius: 5px;
        padding: 14px 40px 14px 20px;
        box-shadow: 0 2px 1px rgba(0, 0, 0, .16);
        width: 255px;
        margin: 20px 20px 0 0;
        cursor: pointer;
        appearance: none;
        @include mq(tab) {
          width: 230px;
        }
      }
    }
    .btn {
      font-size: 2.1rem;
      font-weight: 700;
      background: $color-yellow;
      border-radius: 10px;
      box-shadow: 0 3px 0 #957725;
      padding: 46px 24px 52px;
      margin: 20px 0 0;
      position: relative;
      cursor: pointer;
      width: 240px;
      text-align: center;
      @include mq(pc) {
        &:hover {
          box-shadow: none;
          transform: translateY(3px);
        }
      }
      &::before {
        content: "";
        width: 31px;
        height: 31px;
        display: inline-block;
        background: url(../img/common/icon/icon_home.svg) no-repeat center center;
        background-size: contain;
        vertical-align: middle;
        margin: -10px 14px 0 0;
      }
    }
  }
}
.sateiBoxWrap {
  background: $color-theme-light;
  padding: 100px 1%;
  text-align: center;
  &__ttl {
    font-size: 4.5rem;
    font-weight: 700;
    line-height: 1.25;
    padding-left: 27px;
    &::before {
      content: "";
      width: 12px;
      height: 12px;
      display: inline-block;
      vertical-align: middle;
      border-radius: 50px;
      background: $color-theme;
      margin: -10px 15px 0 0;
    }
  }
  .sateiBox {
    max-width: $base-width;
    margin: 65px auto 0;
    padding: 30px;
    &__inner {
      border-radius: 15px;
      padding: 30px;
      &::after {
        right: 8px;
        bottom: -30px;
      }
    }
    &__ttl {
      font-size: 2.5rem;
      color: #fff;
      line-height: 1.2;
      padding: 12px;
      background: $color-theme;
      border-radius: 20px 20px 0 0;
      width: 50%;
      position: absolute;
      top: -70px;
      left: -30px;
      &::before {
        content: none;
      }
    }
    &__text {
      font-size: 1.8rem;
      font-weight: 700;
      text-align: left;
      line-height: 1.77777;
      margin: 0 0 7px;
    }
    &__form {
      margin: 10px 0 0;
    }
  }
}
/*=================================
  zeroensatei
=================================*/
.zeroensatei {
  margin: 65px auto 0;
  padding: 30px;
  border-radius: 20px;
  background: #fff;
  background-size: contain;
  box-shadow: 0 5px 25px rgba(48, 74, 133, .12);
  max-width: $base-width;
  &__inner {
    border-radius: 15px;
    background: $color-theme-light;
    padding: 30px;
    position: relative;
    &::after {
      content: "";
      width: 242px;
      height: 385px;
      display: inline-block;
      background: url(../img/common/img_sateiBox.png) no-repeat center center;
      background-size: contain;
      position: absolute;
      right: 0;
      bottom: -30px;
      @include mq(tab) {
        width: vw(242);
        height: vw(385);
      }
    }
  }
  &__ttl {
    font-size: 2.5rem;
    font-weight: 700;
    line-height: 1.2;
    color: #fff;
    padding: 12px;
    background: $color-theme;
    border-radius: 20px 20px 0 0;
    width: 50%;
    position: absolute;
    top: -70px;
    left: -30px;
    text-align: center;
  }
  &__text {
    font-size: 2.2rem;
    font-weight: 700;
    line-height: 1.77777;
    margin: 0 0 20px;
    text-align: center;
    .lg {
      font-size: 120%;
      background: linear-gradient(transparent 50%, #fff932 50%);
      color: #fc2828;
      padding: 0 .25em;
      margin: 0 .5em;
    }
  }
  &__img {
    img {
      margin: 0;
    }
  }
  &__form {
    margin: 10px 0 0;
    .btnWrap {
      margin: 0;
    }
    .btn {
      font-size: 3rem;
      font-weight: 700;
      background: $color-yellow;
      border-radius: 10px;
      color: $font-color-base;
      box-shadow: 0 3px 0 #957725;
      padding: 26px 24px 32px;
      margin: 20px auto 0;
      position: relative;
      cursor: pointer;
      text-decoration: none;
      width: 100%;
      max-width: 600px;
      text-align: center;
      @include mq(pc) {
        &:hover {
          box-shadow: none;
          transform: translateY(3px);
        }
      }
      &::before {
        content: "";
        width: 31px;
        height: 31px;
        display: inline-block;
        background: url(../img/common/icon/icon_home.svg) no-repeat center center;
        background-size: contain;
        vertical-align: middle;
        margin: -10px 14px 0 0;
      }
    }
  }
}
.zeroensateiWrap {
  margin: 80px 0 50px;
  &__ttl {
    font-size: 4.5rem;
    font-weight: 700;
    line-height: 1.25;
    margin-bottom: 85px;
    text-align: center;
    &::before {
      content: "";
      width: 12px;
      height: 12px;
      display: inline-block;
      vertical-align: middle;
      border-radius: 50px;
      background: $color-theme;
      margin: -10px 15px 0 0;
    }
  }
}
.cvbtnBox {
  padding: 30px 30px;
  margin: 50px 0;
  background: $color-theme-light;
  position: relative;
  text-align: center;
  box-shadow: 0 3px 20px rgba(48, 74, 133, 0.12);
  &__text {
    font-size: 2.4rem;
    font-weight: 600;
    line-height: 1.5;
    background: #fff;
    display: inline-block;
    border-radius: 5px;
    padding: 10px 30px;
    animation: bound 2.5s ease-in-out 0s infinite alternate;
    .em {
      font-size: 3rem;
      font-weight: 700;
      color: $color-pink;
    }
  }
  @keyframes bound {
    0% {
      transform: scale(1);
    }
    89% {
      transform: scale(1);
    }
    90% {
      transform: scale(1.03);
    }
    95% {
      transform: scale(0.98);
    }
    100% {
      transform: scale(1);
    }
  }
  &__link {
    font-size: 3rem;
    font-weight: 700;
    background: $color-yellow;
    border-radius: 5px;
    color: $font-color-base;
    box-shadow: 0 3px 0 #957725;
    padding: 16px 24px 22px;
    margin: 20px auto 0;
    position: relative;
    cursor: pointer;
    text-decoration: none;
    width: 100%;
    max-width: 600px;
    display: block;
    text-align: center;
    @include mq(pc) {
      &:hover {
        box-shadow: none;
        transform: translateY(3px);
      }
    }
    &::before {
      content: "";
      width: 31px;
      height: 31px;
      display: inline-block;
      background: url(../img/common/icon/icon_home.svg) no-repeat center center;
      background-size: contain;
      vertical-align: middle;
      margin: -10px 14px 0 0;
    }
  }
}
@include mq(sp) {
  /*=================================
    sateiBox
  =================================*/
  .articleDetail__in {
    .sateiBox {
      width: calc(100% + per(68));
      margin-left: per(-36)!important;
    }
  }
  .sateiBox {
    border-radius: 0;
    background: $color-theme-light;
    padding: 40px 0 40px per(30);
    box-shadow: none;
    margin: 40px 0;
    &__inner {
      border-radius: 0;
      padding: 0;
      &::after {
        content: none;
      }
    }
    &__ttl {
      font-size: 2.4rem;
      line-height: 1.08;
      padding-left: 9px;
      padding-right: per(30);
      &::before {
        width: 6px;
        height: 6px;
        top: 10px;
      }
    }
    &__text {
      font-size: 1.4rem;
      line-height: 1.71428;
      margin: 11px 0 0;
      padding-right: per(30);
    }
    &__form {
      margin: 15px 0 0;
      form {
        display: block;
      }
      .selectWrap {
        display: block;
        background: url(../img/common/img_sateiBox.png) no-repeat right bottom;
        background-size: 173px auto;
        max-width: initial;
        .design-select-box {
          font-size: 1.7rem;
          padding: 16px 20px;
          max-width: calc(100% - per(117));
          width: 100%;
          margin: 15px 0 0 0;
        }
      }
      .btn {
        border-radius: 5px;
        box-shadow: 0 4px 0 #957725;
        padding: 22px per(10) 24px;
        width: calc(100% - per(30));
        margin: 20px auto 0;
      }
    }
  }
  .sateiBoxWrap {
    padding: 40px 0;
    margin: 40px 0 0;
    text-align: left;
    &__ttl {
      font-size: 2.4rem;
      line-height: 1.08;
      padding-left: 9px;
      padding-right: per(30);
      margin-left: per(30);
      position: relative;
      &::before {
        width: 6px;
        height: 6px;
        display: inline-block;
        vertical-align: middle;
        border-radius: 50px;
        background: $color-theme;
        position: absolute;
        top: 20px;
        left: 0;
      }
    }
    .sateiBox {
      margin: 30px auto 0;
      padding: 0;
      &__inner {
        border-radius: 0;
        padding: 0 0 0 per(30);
      }
      &__ttl {
        font-size: 2rem;
        border-radius: 0;
        width: calc(100% + per(32));
        margin-left: per(-32);
        position: static;
        &::before {
          content: none;
        }
      }
      &__text {
        font-size: 1.4rem;
        font-weight: 500;
        line-height: 1.714285;
        margin: 20px 0 0;
      }
    }
  }
  /*=================================
    zeroensatei
  =================================*/
  .articleDetail__in {
    .zeroensateiWrap {
      width: calc(100% + per(68));
      margin-left: per(-36)!important;
    }
  }
  .zeroensatei {
    margin: 30px auto 0;
    padding: 0 0 40px;
    border-radius: 0;
    background: $color-theme-light;
    box-shadow: none;
    &__inner {
      border-radius: 0;
      padding: 0;
      &::after {
        width: 121px;
        height: 192px;
        bottom: -40px;
      }
    }
    &__ttl {
      font-size: 2rem;
      line-height: 1.08;
      padding-left: 9px;
      padding-right: per(30);
      border-radius: 0;
      width: calc(100% + per(32));
      margin-left: per(-32);
      position: static;
    }
    &__text {
      font-size: 1.6rem;
      font-weight: 500;
      line-height: 1.714285;
      margin: 20px 0 0;
      .lg {
        margin: 0 .25em;
      }
    }
    &__img {
      width: 90%;
      margin: 0 auto;
      img {
        margin: 0;
      }
    }
    &__form {
      margin: 15px 0 0;
      padding: 0 per(15);
      .btn {
        font-size: 2.2rem;
        border-radius: 5px;
        box-shadow: 0 4px 0 #957725;
        padding: 22px per(15) 24px;
        margin: 45px 0 0;
        display: block;
        width: 79%;
        &::before {
          width: 28px;
          height: 28px;
          margin: -10px 8px 0 0;
        }
      }
    }
  }
  .zeroensateiWrap {
    margin: 40px 0;
    &__ttl {
      font-size: 2.4rem;
      line-height: 1.08;
      margin-bottom: 30px;
      &::before {
        width: 6px;
        height: 6px;
        margin: -7px 5px 0 0;
      }
    }
  }
  .cvbtnBox {
    width: calc(100% + per(68));
    margin: 40px 0 40px per(-36);
    padding: 30px per(36);
    box-shadow: none;
    &__text {
      font-size: 2rem;
      padding: 10px 15px;
      .em {
        font-size: 2.8rem;
      }
    }
    &__link {
      font-size: 2.5rem;
      border-radius: 5px;
      box-shadow: 0 4px 0 #957725;
      padding: 22px per(30) 24px;
      margin: 20px auto 0;
    }
  }
}

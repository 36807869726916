@use "../global" as *;
/*=================================
  cvBox
=================================*/
.cvBox {
  background: url(../img/common/bg_cv_pc.png) no-repeat left bottom 112px;
  background-size: contain;
  padding: 0 0 270px;
  position: relative;
  @include mq(tab) {
    background: url(../img/common/bg_cv_pc.png) no-repeat left bottom vw(112);
    padding: 0 0 vw(270) vw(184);
  }
  &__inner {
    max-width: $base-width;
    margin: 0 auto;
  }
  &__cont {
    max-width: 880px;
    margin: 0 0 0 auto;
    background: #fff;
    padding: 15px 0 32px 82px;
    position: relative;
    @include mq(tab) {
      padding: 15px vw(82) 32px;
    }
    &::before {
      content: "";
      width: 317px;
      height: 536px;
      display: inline-block;
      background: url(../img/common/img_cv.png) no-repeat center center;
      background-size: contain;
      position: absolute;
      bottom: -158px;
      left: -184px;
      z-index: 1;
      @include mq(tab) {
        width: vw(317);
        height: vw(536);
        bottom: vw(-158);
        left: vw(-184);
      }
    }
  }
  &::after {
    content: "";
    width: 100%;
    height: 112px;
    display: block;
    background: #fff;
    position: absolute;
    left: 0;
    bottom: 0;
    z-index: 0;
    @include mq(tab) {
      height: vw(112);
    }
  }
  &__ttl {
    font-size: 4.5rem;
    font-weight: 700;
    @include mq(tab) {
      font-size: clamp(3.5rem, vw(45), 4.5rem);
    }
    &::before {
      content: "";
      width: 12px;
      height: 12px;
      display: inline-block;
      vertical-align: middle;
      border-radius: 50px;
      background: $color-theme;
      margin: -5px 15px 0 0;
    }
  }
  &__text {
    font-size: 1.6rem;
    line-height: 1.5;
  }
  .telBox {
    text-align: center;
    max-width: 80%;
    margin: 45px auto 0;
    &__ttl {
      font-size: clamp(1.6rem, vw(22), 2.2rem);
      font-weight: 700;
      margin: 0 0 20px;
    }
    .btn {
      @include font-lato;
      font-size: 3.2rem;
      font-weight: 700;
      line-height: 1;
      text-decoration: none;
      color: $font-color-base;
      border-radius: 10px;
      padding: 22px 40px 16px;
      border: 3px solid #D8D8D8;
      background: $color-yellow;
      margin: 0 0 26px;
      display: block;
      @include mq(pc) {
        pointer-events: none;
      }
      @include mq(tab) {
        font-size: clamp(2.7rem, vw(32), 3.2rem);
      }
      .text {
        font-family: "Noto Sans JP", "源ノ角ゴシック", "Yu Gothic Medium","游ゴシック Medium","游ゴシック",YuGothic,YuGothicM,"ヒラギノ角ゴ Pro W3","Hiragino Kaku Gothic Pro","メイリオ",Meiryo,"ＭＳ Ｐゴシック",sans-serif;
        font-size: 2.5rem;
        font-weight: 500;
        margin: 0 17px 0 0;
        @include mq(tab) {
          font-size: clamp(2rem, vw(25), 2.5rem);
        }
      }
      .time {
        font-family: "Noto Sans JP", "源ノ角ゴシック", "Yu Gothic Medium","游ゴシック Medium","游ゴシック",YuGothic,YuGothicM,"ヒラギノ角ゴ Pro W3","Hiragino Kaku Gothic Pro","メイリオ",Meiryo,"ＭＳ Ｐゴシック",sans-serif;
        font-size: 1.4rem;
        font-weight: 500;
        line-height: 1;
        display: block;
        margin: 9px 0 0;
      }
      img {
        width: 39px;
        height: auto;
        margin: 0 17px -5px 0;
        display: inline-block;
      }
    }
  }
  .btnBox {
    text-align: center;
    max-width: 80%;
    margin: 45px auto 0;
    a {
      text-decoration: none;
      color: $font-color-base;
    }
    .btn {
      &--satei {
        font-size: 2.7rem;
        font-weight: 700;
        color: #fff;
        line-height: 1.4444;
        background: #ff5a25;
        border-radius: 10px;
        box-shadow: 0 5px 0 #9f2b22;
        padding: 31px 40px 33px;
        margin: 100px 0 26px;
        display: block;
        position: relative;
        @include animation-base(all,0s);
        @include mq(tab) {
          font-size: clamp(2.2rem, vw(27), 2.7rem);
        }
        .text {
          font-size: 70%;
          color: $font-color-base;
          display: block;
          width: 100%;
          position: absolute;
          top: -50px;
          left: 50%;
          transform: translateX(-50%);
          .em {
            font-size: 140%;
            color: $color-pink;
          }
        }
        img {
          margin: 0 17px 0px 0;
          display: inline-block;
        }
        @include mq(pc) {
          &:hover {
            box-shadow: none;
            transform: translateY(3px);
          }
        }
      }
      &--service {
        font-size: 2.7rem;
        font-weight: 700;
        line-height: 1.4444;
        background: $color-yellow;
        border-radius: 10px;
        box-shadow: 0 3px 0 #957725;
        padding: 31px 40px 33px;
        margin: 0 0 26px;
        display: block;
        @include animation-base(all,0s);
        @include mq(tab) {
          font-size: clamp(2.2rem, vw(27), 2.7rem);
        }
        img {
          margin: 0 17px 0px 0;
          display: inline-block;
        }
        @include mq(pc) {
          &:hover {
            box-shadow: none;
            transform: translateY(3px);
          }
        }
      }
      &--tel {
        @include font-lato;
        font-size: 3.2rem;
        font-weight: 700;
        line-height: 1;
        border-radius: 10px;
        padding: 22px 40px 16px;
        border: 3px solid #D8D8D8;
        background: $color-yellow;
        margin: 0 0 26px;
        display: block;
        @include mq(pc) {
          pointer-events: none;
        }
        @include mq(tab) {
          font-size: clamp(2.7rem, vw(32), 3.2rem);
        }
        .text {
          font-family: "Noto Sans JP", "源ノ角ゴシック", "Yu Gothic Medium","游ゴシック Medium","游ゴシック",YuGothic,YuGothicM,"ヒラギノ角ゴ Pro W3","Hiragino Kaku Gothic Pro","メイリオ",Meiryo,"ＭＳ Ｐゴシック",sans-serif;
          font-size: 2.5rem;
          font-weight: 500;
          margin: 0 17px 0 0;
          @include mq(tab) {
            font-size: clamp(2rem, vw(25), 2.5rem);
          }
        }
        .time {
          font-family: "Noto Sans JP", "源ノ角ゴシック", "Yu Gothic Medium","游ゴシック Medium","游ゴシック",YuGothic,YuGothicM,"ヒラギノ角ゴ Pro W3","Hiragino Kaku Gothic Pro","メイリオ",Meiryo,"ＭＳ Ｐゴシック",sans-serif;
          font-size: 1.4rem;
          font-weight: 500;
          line-height: 1;
          display: block;
          margin: 9px 0 0;
        }
        img {
          width: 39px;
          height: auto;
          margin: 0 17px -5px 0;
          display: inline-block;
        }
      }
    }
  }
  &.article__in {
    margin-top: 100px;
    .btnBox .btn--tel {
      background: #FECB3F;
      font-size: 2.8rem;
      img {
        margin: 0 10px -5px 0;
      }
    }
    .cvBox {
      &__ttl {
        font-size: 4.5rem;
        font-weight: 700;
        margin: 0 0 20px;
        @include mq(tab) {
          font-size: clamp(3.5rem, vw(45), 4.5rem);
        }
        &::before {
          content: "";
          width: 12px;
          height: 12px;
          display: inline-block;
          vertical-align: middle;
          border-radius: 50px;
          background: $color-theme;
          margin: -5px 15px 0 0;
        }
      }
      &__text {
        font-size: 2rem;
        font-weight: 600;
        line-height: 1.75;
        @include mq(tab) {
          font-size: clamp(1.6rem, vw(18), 1.8rem);
        }
      }
    }
  }
}
.cvBox--article {
  background: $color-theme-light;
  box-shadow: 0 5px 25px rgba(48, 74, 133, 0.12);
  border: 20px solid #fff;
  border-radius: 10px;
  padding: 50px 50px;
  margin: 100px auto;
  .cvBox {
    &__inner {
      max-width: $base-width;
      margin: 0 auto;
    }
    &__ttl {
      font-size: 3.5rem;
      font-weight: 700;
      margin: 0 0 40px;
      width: 100%;
      text-align: center;
      background: $color-theme;
      color: #fff;
      position: relative;
      &::after {
        content: "";
        border-style: solid;
        border-right: 12px solid transparent;
        border-left: 12px solid transparent;
        border-top: 20px solid $color-theme;
        border-bottom: 0;
        position: absolute;
        bottom: -20px;
        left: 50%;
        transform: translateX(-50%);
        @include mq(tab) {
          border-right: vw(12) solid transparent;
          border-left: vw(12) solid transparent;
          border-top: vw(20) solid $color-theme;
          bottom: vw(-18);
        }
      }
      &::before {
        content: none;
      }
      @include mq(tab) {
        font-size: clamp(3rem, vw(35), 3.5rem);
      }
    }
    &__text {
      font-size: 2rem;
      font-weight: 600;
      line-height: 1.75;
      position: relative;
      padding-left: 270px;
      min-height: 154px;
      @include mq(tab) {
        font-size: clamp(1.6rem, vw(18), 1.8rem);
        padding-left: vw(270);
        min-height: vw(154);
      }
      &::before {
        content: "";
        width: 230px;
        height: 154px;
        display: inline-block;
        background: url(../img/common/img_cv02.jpg) no-repeat center center;
        background-size: contain;
        position: absolute;
        top: 0;
        left: 0;
        @include mq(tab) {
          width: vw(230);
          height: vw(154);
        }
      }
    }
  }
  .telBox {
    text-align: center;
    max-width: 80%;
    margin: 45px auto 0;
    &__ttl {
      font-size: clamp(1.6rem, vw(22), 2.2rem);
      font-weight: 700;
      margin: 0 0 20px;
    }
    .btn {
      @include font-lato;
      font-size: 3.2rem;
      font-weight: 700;
      line-height: 1;
      text-decoration: none;
      color: $font-color-base;
      border-radius: 10px;
      padding: 22px 40px 16px;
      border: 3px solid #D8D8D8;
      background: $color-yellow;
      margin: 0 0 26px;
      display: block;
      @include mq(pc) {
        pointer-events: none;
      }
      @include mq(tab) {
        font-size: clamp(2.7rem, vw(32), 3.2rem);
      }
      .text {
        font-family: "Noto Sans JP", "源ノ角ゴシック", "Yu Gothic Medium","游ゴシック Medium","游ゴシック",YuGothic,YuGothicM,"ヒラギノ角ゴ Pro W3","Hiragino Kaku Gothic Pro","メイリオ",Meiryo,"ＭＳ Ｐゴシック",sans-serif;
        font-size: 2.5rem;
        font-weight: 500;
        margin: 0 17px 0 0;
        @include mq(tab) {
          font-size: clamp(2rem, vw(25), 2.5rem);
        }
      }
      .time {
        font-family: "Noto Sans JP", "源ノ角ゴシック", "Yu Gothic Medium","游ゴシック Medium","游ゴシック",YuGothic,YuGothicM,"ヒラギノ角ゴ Pro W3","Hiragino Kaku Gothic Pro","メイリオ",Meiryo,"ＭＳ Ｐゴシック",sans-serif;
        font-size: 1.4rem;
        font-weight: 500;
        line-height: 1;
        display: block;
        margin: 9px 0 0;
      }
      img {
        width: 39px;
        height: auto;
        margin: 0 17px -5px 0;
        display: inline-block;
      }
    }
  }
  .btnBox {
    text-align: center;
    max-width: 80%;
    margin: 45px auto 0;
    a {
      text-decoration: none;
      color: $font-color-base;
    }
    .btn {
      &--satei {
        font-size: 2.7rem;
        font-weight: 700;
        color: #fff;
        line-height: 1.4444;
        background: #ff5a25;
        border-radius: 10px;
        box-shadow: 0 5px 0 #9f2b22;
        padding: 31px 40px 33px;
        margin: 70px 0 26px;
        display: block;
        position: relative;
        @include animation-base(all,0s);
        @include mq(tab) {
          font-size: clamp(2.2rem, vw(27), 2.7rem);
        }
        .text {
          font-size: 70%;
          color: $font-color-base;
          display: block;
          width: 100%;
          position: absolute;
          top: -50px;
          left: 50%;
          transform: translateX(-50%);
          .em {
            font-size: 140%;
            color: $color-pink;
          }
        }
        img {
          margin: 0 17px 0px 0;
          display: inline-block;
        }
        @include mq(pc) {
          &:hover {
            box-shadow: none;
            transform: translateY(3px);
          }
        }
      }
      &--service {
        font-size: 2.7rem;
        font-weight: 700;
        line-height: 1.4444;
        background: $color-yellow;
        border-radius: 10px;
        box-shadow: 0 3px 0 #957725;
        padding: 31px 40px 33px;
        margin: 0 0 26px;
        display: block;
        @include animation-base(all,0s);
        @include mq(tab) {
          font-size: clamp(2.2rem, vw(27), 2.7rem);
        }
        img {
          margin: 0 17px 0px 0;
          display: inline-block;
        }
        @include mq(pc) {
          &:hover {
            box-shadow: none;
            transform: translateY(3px);
          }
        }
      }
      &--tel {
        @include font-lato;
        font-size: 3.2rem;
        font-weight: 700;
        line-height: 1;
        border-radius: 10px;
        padding: 22px 40px 16px;
        border: 3px solid #D8D8D8;
        background: $color-yellow;
        margin: 0 0 26px;
        display: block;
        @include mq(pc) {
          pointer-events: none;
        }
        @include mq(tab) {
          font-size: clamp(2.7rem, vw(32), 3.2rem);
        }
        .text {
          font-family: "Noto Sans JP", "源ノ角ゴシック", "Yu Gothic Medium","游ゴシック Medium","游ゴシック",YuGothic,YuGothicM,"ヒラギノ角ゴ Pro W3","Hiragino Kaku Gothic Pro","メイリオ",Meiryo,"ＭＳ Ｐゴシック",sans-serif;
          font-size: 2.5rem;
          font-weight: 500;
          margin: 0 17px 0 0;
          @include mq(tab) {
            font-size: clamp(2rem, vw(25), 2.5rem);
          }
        }
        .time {
          font-family: "Noto Sans JP", "源ノ角ゴシック", "Yu Gothic Medium","游ゴシック Medium","游ゴシック",YuGothic,YuGothicM,"ヒラギノ角ゴ Pro W3","Hiragino Kaku Gothic Pro","メイリオ",Meiryo,"ＭＳ Ｐゴシック",sans-serif;
          font-size: 1.4rem;
          font-weight: 500;
          line-height: 1;
          display: block;
          margin: 9px 0 0;
        }
        img {
          width: 39px;
          height: auto;
          margin: 0 17px -5px 0;
          display: inline-block;
        }
      }
    }
  }
}

@include mq(sp) {
  /*=================================
    cvBox
  =================================*/
  .cvBox {
    background: url(../img/common/bg_cv_sp.png) no-repeat center bottom;
    background-size: cover;
    padding: 0 0 50px;
    margin: 40px 0;
    &__cont {
      max-width: calc(100% - per(60));
      padding: 40px per(25) 40px per(33);
      &::before {
        content: none;
      }
    }
    &::after {
      content: none;
    }
    &__ttl {
      font-size: 2.4rem;
      line-height: 1.3333;
      &::before {
        width: 6px;
        height: 6px;
        margin: -5px 3px 0 0;
      }
    }
    &__text {
      font-size: 1.4rem;
      line-height: 1.714285;
      margin: 11px 0 0;
    }
    .telBox {
      max-width: initial;
      width: calc(100% + per(101));
      margin: 40px 0 0 per(-101);
      &__ttl {
        font-size: 1.6rem;
        margin: 0 0 10px;
      }
      .btn {
        font-size: 3.1rem;
        border-radius: 5px;
        padding: 16px per(35);
        border: 2px solid #D8D8D8;
        .text {
          display: none;
        }
        .time {
          font-size: 1.3rem;
          margin: 0;
        }
        img {
          width: 30px;
          margin: 0 22px -8px 0;
        }
      }
    }
    .btnBox {
      max-width: initial;
      width: calc(100% + per(101));
      margin: 40px 0 0 per(-101);
      a {
        text-align: center;
      }
      .btn {
        &--satei {
          font-size: 2rem;
          border-radius: 5px;
          box-shadow: 0 3px 0 #9f2b22;
          padding: 16px per(15);
          margin: 60px 0 20px;
          .text {
            top: -40px;
          }
        }
        &--service {
          font-size: 2.2rem;
          font-weight: 500;
          line-height: 1.181818;
          border-radius: 5px;
          box-shadow: 0 4px 0 #957725;
          padding: 15px per(27) 16px;
          margin: 0 0 14px;
          img {
            width: 28px;
            margin: 0 15px -12px 0;
          }
        }
        &--tel {
          font-size: 3.1rem;
          border-radius: 5px;
          padding: 16px per(35);
          border: 2px solid #D8D8D8;
          .text {
            display: none;
          }
          .time {
            font-size: 1.3rem;
            margin: 0;
          }
          img {
            width: 30px;
            margin: 0 22px -8px 0;
          }
        }
      }
    }
    &.article__in {
      margin-top: 70px;
      .cvBox {
        &__cont {
          &::before {
            content: "";
            width: vw(110,$sp-width);
            height: vw(186,$sp-width);
            display: inline-block;
            background: url(../img/common/img_cv.png) no-repeat center center;
            background-size: contain;
            position: absolute;
            bottom: -50px;
            left: per(-110);
            z-index: 1;
          }
        }
        &__ttl {
          font-size: 2.4rem;
          line-height: 1.3333;
          margin: 0 0 20px;
          &::before {
            width: 6px;
            height: 6px;
            margin: -5px 3px 0 0;
          }
        }
        &__text {
          font-size: 1.4rem;
          line-height: 1.714285;
        }
      }
      .telBox {
        max-width: 100%;
        margin: 30px auto 0;
        &__ttl {
          font-size: 1.6rem;
          margin: 0 0 10px;
        }
        .btn {
          font-size: 2.2rem;
          border-radius: 5px;
          padding: 16px per(15);
          border: 2px solid #D8D8D8;
          .text {
            font-size: 1.3rem;
            margin: 0 17px 0 0;
          }
          img {
            width: 30px;
            margin: 0 10px -5px 0;
          }
        }
      }
      .btnBox {
        max-width: 100%;
        margin: 30px auto 0;
        .btn {
          &--satei {
            font-size: 1.7rem;
            border-radius: 5px;
            box-shadow: 0 3px 0 #9f2b22;
            padding: 16px per(15);
            margin: 60px 0 20px;
            .text {
              top: -40px;
            }
          }
          &--service {
            font-size: 1.7rem;
            font-weight: 500;
            line-height: 1.181818;
            border-radius: 5px;
            box-shadow: 0 4px 0 #957725;
            padding: 15px per(27) 16px;
            margin: 0 0 14px;
            img {
              width: 28px;
              margin: 0 15px -12px 0;
            }
          }
          &--tel {
            font-size: 2.2rem;
            border-radius: 5px;
            padding: 16px per(35);
            border: 2px solid #D8D8D8;
            .text {
              display: none;
            }
            .time {
              font-size: 1.3rem;
              margin: 0;
            }
            img {
              width: 30px;
              margin: 0 22px -8px 0;
            }
          }
        }
      }
    }
  }
  .cvBox--article {
    border: 10px solid #fff;
    border-radius: 8px;
    padding: 30px per(20);
    margin: 50px auto;
    .cvBox {
      &__ttl {
        font-size: 1.9rem;
        line-height: 1.3333;
        margin: 0 0 20px;
        padding: 10px per(15);
        &::after {
          border-right: 8px solid transparent;
          border-left: 8px solid transparent;
          border-top: 12px solid $color-theme;
          bottom: -12px;
        }
      }
      &__text {
        font-size: 1.4rem;
        line-height: 1.714285;
        padding-left: 0;
        min-height: inherit;
        &::before {
          width: 100%;
          height: vw(154,$sp-width);
          background-size: cover;
          background-position: center top;
          display: block;
          margin: 0 auto 30px;
          position: static;
        }
      }
    }
    .telBox {
      max-width: 100%;
      margin: 30px auto 0;
      &__ttl {
        font-size: 1.6rem;
        margin: 0 0 10px;
      }
      .btn {
        font-size: 2.8rem;
        border-radius: 5px;
        padding: 16px per(15);
        border: 2px solid #D8D8D8;
        .text {
          font-size: 1.3rem;
          margin: 0 17px 0 0;
        }
        img {
          width: 30px;
          margin: 0 10px -5px 0;
        }
      }
    }
    .btnBox {
      max-width: 100%;
      margin: 30px auto 0;
      .btn {
        &--satei {
          font-size: 2rem;
          border-radius: 5px;
          box-shadow: 0 3px 0 #9f2b22;
          padding: 16px per(15);
          margin: 60px 0 20px;
          .text {
            top: -40px;
          }
        }
        &--service {
          font-size: 2.2rem;
          font-weight: 500;
          line-height: 1.181818;
          border-radius: 5px;
          box-shadow: 0 4px 0 #957725;
          padding: 15px per(27) 16px;
          margin: 0 0 14px;
          img {
            width: 28px;
            margin: 0 15px -12px 0;
          }
        }
        &--tel {
          font-size: 3.1rem;
          border-radius: 5px;
          padding: 16px per(35);
          border: 2px solid #D8D8D8;
          .text {
            display: none;
          }
          .time {
            font-size: 1.3rem;
            margin: 0;
          }
          img {
            width: 30px;
            margin: 0 22px -8px 0;
          }
        }
      }
    }
  }
}
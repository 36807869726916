@use "../../global" as *;

a {
  &:hover {
    outline: none;
  }
  &:active {
    outline: none;
  }
  &:focus {
    outline: none;
  }
}
@include mq(pc) {
  /* =================================
    hover
  ================================= */
  a {
    @include animation-base(all);
  }
  .widelink {
    @include animation-base;
    cursor: pointer;
    .widelink__cover {
      position: relative;
      overflow: hidden;
      &::before {
        content: "";
        background: url(../img/common/btn_more.png) no-repeat center center;
        background-size: contain;
        width: 130px;
        height: 44px;
        opacity: 0;
        position: absolute;
        top: 45%;
        left: 50%;
        transform: translate(-50%, -50%);
        z-index: 6;
        transition-property: opacity,top;
        transition-duration: 0.15s;
        transition-timing-function: ease-out;
      }
      &::after {
        content: "";
        position: absolute;
        top: 0px;
        left: 0px;
        width: 100%;
        height: 100%;
        z-index: 5;
        opacity: 0;
        background: rgb(61,84,138);
        background: -moz-linear-gradient(135deg, rgba(61,84,138,0.7) 0%, rgba(100,133,209,0.7) 100%);
        background: -webkit-linear-gradient(135deg, rgba(61,84,138,0.7) 0%, rgba(100,133,209,0.7) 100%);
        background: linear-gradient(135deg, rgba(61,84,138,0.7) 0%, rgba(100,133,209,0.7) 100%);
        filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#3d548a",endColorstr="#6485d1",GradientType=1);
        color: white;
        transition-property: opacity;
        transition-duration: 0.15s;
        transition-timing-function: ease-out;
      }
    }
    .widelink__menu {
      position: relative;
      overflow: hidden;
      display: block;
      &::before {
        content: "詳しく見る";
        font-size: 1.3rem;
        font-weight: 700;
        color: #fff;
        line-height: 1;
        text-align: center;
        opacity: 0;
        padding: 15px 9%;
        border: 1px solid #fff;
        border-radius: 50px;
        position: absolute;
        bottom: 12%;
        left: 50%;
        transform: translateX(-50%);
        z-index: 6;
        transition-property: opacity,bottom;
        transition-duration: 0.15s;
        transition-timing-function: ease-out;
      }
    }
    .widelink__article {
      position: relative;
      overflow: hidden;
      &::before {
        content: "";
        display: inline-block;
        width: 130px;
        height: 24px;
        background: url(../img/link_more.png) no-repeat center center;
        opacity: 0;
        position: absolute;
        top: 45%;
        left: 50%;
        transform: translate(-50%, -50%);
        z-index: 6;
        transition-property: opacity,top;
        transition-duration: 0.15s;
        transition-timing-function: ease-out;
      }
      &::after {
        content: "";
        position: absolute;
        top: 0px;
        left: 0px;
        width: 100%;
        height: 100%;
        z-index: 5;
        opacity: 0;
        background-color: rgba(0, 84, 142, .7);
        color: white;
        transition-property: opacity;
        transition-duration: 0.15s;
        transition-timing-function: ease-out;
      }
    }
    &:hover {
      a {
        text-decoration: none;
      }
      .widelink__cover,.widelink__article {
        &::before {
          opacity: 1;
          top: 50%;
        }
        &::after {
          opacity: 1;
        }
      }
      .widelink__menu {
        &::before {
          opacity: 1;
          bottom: 10%;
        }
      }
    }
  }
  .hoverBg {
    .widelink__cover {
      &::before {
        content: none;
      }
      &::after {
        content: none;
      }
    }
    &:hover {
      background: $color-theme-light;
    }
  }
}
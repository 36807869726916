@use "../global" as *;
/*=================================
  ikkatsuBox
=================================*/
.ikkatsuBox {
  margin: 100px 0;
  &__ttl {
    font-size: 4.5rem;
    font-weight: 700;
    line-height: 1.25;
    padding-left: 27px;
    position: relative;
    &::before {
      content: "";
      width: 12px;
      height: 12px;
      display: inline-block;
      vertical-align: middle;
      border-radius: 50px;
      background: $color-theme;
      position: absolute;
      top: 25px;
      left: 0;
    }
  }
  &__text {
    font-size: 1.6rem;
    line-height: 2;
    margin: 7px 0 0;
  }
  &__cont {
    border: 5px solid $color-theme-light;
    border-radius: 10px;
    background: #fff;
    padding: 35px 7%;
    margin: 40px 0 0;
    .contWrap {
      display: flex;
      justify-content: space-between;
      &__item {
        width: 46%;
        text-align: center;
        &:nth-child(2n) {
          position: relative;
          &::before {
            content: "";
            width: 1px;
            height: 95%;
            display: block;
            border-left: 1px dashed #D4D4D4;
            position: absolute;
            top: 2.5%;
            left: -9%;
          }
        }
      }
      &__ttl {
        font-size: 1.8rem;
        font-weight: 700;
        color: #fff;
        background: $color-theme;
        text-align: center;
        border-radius: 10px 10px 0 0;
        width: 88%;
        padding: 12px 0;
        margin: 0 auto 22px;
      }
      &__text {
        font-size: clamp(1.8rem,vw(20),2rem);
        font-weight: 500;
        line-height: 1.75;
        margin: 0 0 20px;
      }
      &__conclusion {
        font-size: clamp(2rem,vw(24),2.4rem);
        font-weight: 700;
        line-height: 1.5714;
        letter-spacing: .1em;
        margin: 35px 0 0;
        .em {
          color: $color-red;
          background: linear-gradient(transparent 82%, #FFDFDF 82%);
          padding: 0 5px 5px;
        }
      }
    }
    .btnWrap {
      margin: 40px 0 0;
    }
  }
}
.ikkatsuBoxWrap {
  background: url(../img/common/bg_ikkatsuBox.jpg) no-repeat center top;
  background-size: cover;
  padding: 100px 1%;
  .ikkatsuBox {
    max-width: $base-width;
    margin: 0 auto;
    &__ttl {
      color: #fff;
      &::before {
        background: #fff;
      }
    }
    &__text {
      color: #fff;
      margin: 12px 0 0;
    }
  }
}
@include mq(sp) {
  /*=================================
    ikkatsuBox
  =================================*/
  .ikkatsuBox {
    margin: 40px 0;
    &__ttl {
      font-size: 2.4rem;
      line-height: 1.083333;
      padding: 0 per(10) 0 per(20);
      &::before {
        width: 6px;
        height: 6px;
        top: 12px;
        left: per(10);
      }
    }
    &__text {
      font-size: 1.4rem;
      line-height: 1.71428;
      margin: 13px 0 0;
      padding: 0 per(10);
    }
    &__cont {
      border: none;
      padding: 0;
      margin: 20px 0 0;
      .contWrap {
        display: block;
        &__item {
          width: 100%;
          border: 3px solid $color-theme-light;
          border-radius: 10px;
          padding: 30px per(10);
          &:nth-child(2n) {
            margin: 20px 0 0;
            &::before {
              content: none;
            }
          }
        }
        &__ttl {
          font-size: 1.6rem;
          letter-spacing: .09em;
          line-height: 1.5;
          width: 85%;
          padding: 8px 0;
          margin: 0 auto 12px;
        }
        &__text {
          font-size: 1.6rem;
          line-height: 1.3125;
          margin: 0 0 17px;
        }
        &__conclusion {
          font-size: 1.9rem;
          line-height: 1.59;
          margin: 17px 0 0;
        }
      }
      .btnWrap {
        margin: 20px 0 0;
      }
    }
  }
  .ikkatsuBoxWrap {
    background: none;
    padding: 40px per(30);
    .ikkatsuBox {
      &__ttl {
        color: $font-color-base;
        &::before {
          background: $color-theme;
        }
      }
      &__text {
        color: $font-color-base;
        margin: 12px 0 0;
      }
    }
  }
}
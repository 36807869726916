@use "../../global" as *;

/*=================================
  breadcrumb
=================================*/
.breadcrumb {
  margin-bottom: 20px;
  ul {
    li {
      font-size: 1.4rem;
      line-height: 1;
      color: #B8B8B8;
      display: inline-block;
      a {
        font-size: 1.4rem;
        line-height: 1;
        color: $font-color-base;
        text-decoration: none;
        margin-right: 18px;
        @include mq(pc) {
          &:hover {
            color: $color-link;
            text-decoration: underline;
          }
        }
        &::after {
          content: "";
          width: 6px;
          height: 10px;
          background: url(../img/common/icon/icon_arrow_breadcrumb.png) no-repeat center center;
          background-size: contain;
          display: inline-block;
          margin-left: 18px;
        }
      }
    }
  }
  &.foot {
    margin: 0;
    padding: 17px 1%;
    border-bottom: 1px solid #E1E1E1;
    .inner-wrap {
      max-width: 1260px;
    }
  }
}
@include mq(sp) {
  /*=================================
    breadcrumb
  =================================*/
  .breadcrumb {
    margin-bottom: 15px;
    ul {
      overflow-x: scroll;
      white-space: nowrap;
      li {
        font-size: 1rem;
        line-height: 1.5;
        a {
          font-size: 1rem;
          line-height: 1.5;
          margin-right: 7px;
          &::after {
            margin-left: 7px;
            margin-bottom: -1px;
          }
        }
      }
    }
    &.foot {
      padding: 20px per(17);
    }
  }
}

@use "../../global" as *;

/*=================================
  PR表記
=================================*/
.tooltip--float { /* ツールチップを設置する「親要素」に指定、そこを基準に右上に配置される */
  position: relative;
}
.tooltip--float .tooltipWrap {
  cursor: pointer;
  border-radius: 2px;
  width: 100%;
  position: absolute;
  top: 0;
  right: 0;
}
.tooltip--float .js--tooltipTarget {
  font-size: 1.1rem;
  line-height: 1;
  letter-spacing: .05em;
  color: #fff;
  background: #6e7682;
  display: inline-block;
  padding: 6px 9px;
  position: absolute;
  top: 0;
  right: 0;
  z-index: 999;
}
.tooltip--float .js--tooltipItem {
  font-size: 0.9rem;
  padding: 10px;
  border-radius: 5px;
  color: #555;
  background: #d5d7d9;
  width: 50%;
  position: absolute;
  z-index: 1000;
  display: none;
}
.tooltip--float .tooltipWrap:hover .js--tooltipItem {
  display: inline-block;
  top: 32px;
  right: 5px;
  width: 300px;
}
.tooltip--float .js--tooltipItem::before {
  content: "";
  border-style: solid;
  border-width: 0 6px 12px 6px;
  border-color: transparent transparent #d5d7d9 transparent;
  position: absolute;
  top: -12px;
  right: 10px;
  z-index: 1000;
}

.tooltip--static {
  position: relative;
  text-align: right;
}
.tooltip--static .tooltipWrap {
  display: inline-block;
}
.tooltip--static .js--tooltipTarget {
  font-size: 1.1rem;
  line-height: 1;
  letter-spacing: .05em;
  color: #fff;
  background: #858f9e;
  display: inline-block;
  padding: 6px 9px;
  cursor: pointer;
  position: relative;
  z-index: 999;
}
.tooltip--static .js--tooltipItem {
  font-size: 1.1rem;
  padding: 10px;
  border-radius: 5px;
  color: #555;
  background: #e5e8eb;
  width: 50%;
  text-align: left;
  position: absolute;
  z-index: 1000;
  display: none;
}
.tooltip--static .tooltipWrap:hover .js--tooltipItem {
  display: inline-block;
  top: 35px;
  right: 5px;
  width: 300px;
}
.tooltip--static .js--tooltipItem::before {
  content: "";
  border-style: solid;
  border-width: 0 6px 12px 6px;
  border-color: transparent transparent #d5d7d9 transparent;
  position: absolute;
  top: -12px;
  right: 10px;
  z-index: 1000;
}

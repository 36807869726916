@use "../global" as *;
/*=================================
  area
=================================*/
.areaInfo {
  background: $color-theme-light;
  padding: 86px 30px 46px;
  &__inner {
    max-width: $base-width;
    margin: 0 auto;
    padding: 50px 30px 30px;
    border-radius: 20px;
    background: #fff;
    background-size: contain;
    box-shadow: 0 5px 25px rgba(48, 74, 133, 0.12);
    position: relative;
    .areaList {
      padding: 30px 30px 30px;
      border-radius: 15px;
      background: #F5F8FF;
      display: grid;
      grid-template-columns: 1fr 1fr 1fr 1fr 1fr;
      gap: 20px;
      &__item {
        text-align: center;
        font-size: 1.8rem;
        overflow: hidden;
        position: relative;
        @include mq(pc) {
          &:hover {
            &::after {
              content: "";
              width: 100%;
              height: 100%;
              display: block;
              background: rgba(0, 0, 0, .5);
              position: absolute;
              top: 0;
              left: 0;
              z-index: 1;
            }
            a {
              transform: translateY(0);
            }
          }
        }
        &::before {
          content: "≫";
          display: inline-block;
          color: #fff;
          position: absolute;
          top: 50%;
          right: 15px;
          transform: translateY(-50%);
          z-index: 2;
        }
        &::after {
          content: "";
          width: 100%;
          height: 100%;
          display: block;
          background: rgb(37,60,112);
          background: -moz-linear-gradient(90deg, rgba(37,60,112,0.7959558823529411) 0%, rgba(6,13,29,0.8) 100%);
          background: -webkit-linear-gradient(90deg, rgba(37,60,112,0.7959558823529411) 0%, rgba(6,13,29,0.8) 100%);
          background: linear-gradient(90deg, rgba(37,60,112,0.7959558823529411) 0%, rgba(6,13,29,0.8) 100%);
          filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#253c70",endColorstr="#060d1d",GradientType=1);
          position: absolute;
          top: 0;
          left: 0;
          z-index: 1;
        }
        .img {
          width: 100%;
          height: 100%;
          object-fit: cover;
          position: absolute;
          top: 0;
          left: 0;
          z-index: 0;
        }
        a {
          font-weight: 500;
          color: #fff;
          background: transparent;
          box-shadow: none;
          padding: 10px;
          overflow: hidden;
          text-decoration: none;
          border-radius: 5px;
          display: block;
          position: relative;
          z-index: 2;
        }
      }
    }
  }
  &__ttl {
    position: absolute;
    top: -30px;
    left: 0px;
    width: 50%;
    padding: 12px;
    border-radius: 20px 20px 0 0;
    background: $color-theme;
    font-size: 3rem;
    font-weight: 700;
    line-height: 1.2;
    letter-spacing: .1em;
    text-align: center;
    color: #fff;
  }
  &__text {
    font-size: 1.7rem;
    padding: 20px 0 30px;
  }
}
.mv {
  & + .areaInfo {
    background: rgb(245,244,240);
    background: -moz-linear-gradient(90deg, rgba(245,244,240,1) 0%, rgba(245,248,255,1) 100%);
    background: -webkit-linear-gradient(90deg, rgba(245,244,240,1) 0%, rgba(245,248,255,1) 100%);
    background: linear-gradient(90deg, rgba(245,244,240,1) 0%, rgba(245,248,255,1) 100%);
    filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#dceeff",endColorstr="#f5f8ff",GradientType=1);
    padding: 40px 1% 80px;
    margin-top: -100px;
    .areaInfo {
      &__inner {
        max-width: $base-width;
        margin: 0 auto;
        padding: 40px 30px;
        border-radius: 0;
        background: #fff;
        background-size: contain;
        box-shadow: 0 5px 25px rgba(48, 74, 133, 0.12);
        position: relative;
        .areaList {
          padding: 40px 30px 0;
          border-radius: 0;
          background: transparent;
          display: grid;
          grid-template-columns: 1fr 1fr 1fr 1fr 1fr;
          gap: 20px;
          &__item {
            text-align: center;
            font-size: 1.8rem;
            overflow: hidden;
            position: relative;
            @include mq(pc) {
              &:hover {
                &::after {
                  content: "";
                  width: 100%;
                  height: 100%;
                  display: block;
                  background: rgba(0, 0, 0, .5);
                  position: absolute;
                  top: 0;
                  left: 0;
                  z-index: 1;
                }
                a {
                  transform: translateY(0);
                }
              }
            }
            &::before {
              content: "≫";
              display: inline-block;
              color: #fff;
              position: absolute;
              top: 50%;
              right: 15px;
              transform: translateY(-50%);
              z-index: 2;
            }
            &::after {
              content: "";
              width: 100%;
              height: 100%;
              display: block;
              background: rgb(37,60,112);
              background: -moz-linear-gradient(90deg, rgba(37,60,112,0.7959558823529411) 0%, rgba(6,13,29,0.8) 100%);
              background: -webkit-linear-gradient(90deg, rgba(37,60,112,0.7959558823529411) 0%, rgba(6,13,29,0.8) 100%);
              background: linear-gradient(90deg, rgba(37,60,112,0.7959558823529411) 0%, rgba(6,13,29,0.8) 100%);
              filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#253c70",endColorstr="#060d1d",GradientType=1);
              position: absolute;
              top: 0;
              left: 0;
              z-index: 1;
            }
            .img {
              width: 100%;
              height: 100%;
              object-fit: cover;
              position: absolute;
              top: 0;
              left: 0;
              z-index: 0;
            }
            a {
              font-weight: 500;
              color: #fff;
              background: transparent;
              box-shadow: none;
              padding: 10px;
              overflow: hidden;
              border-radius: 5px;
              overflow: hidden;
              display: block;
              position: relative;
              z-index: 2;
            }
          }
        }
      }
      &__ttl {
        position: static;
        padding: 0;
        width: initial;
        border-radius: 0;
        background: transparent;
        font-size: 4.5rem;
        font-weight: 700;
        color: $font-color-base;
        line-height: 1.5;
        text-align: left;
        &::before {
          content: "";
          width: 12px;
          height: 12px;
          display: inline-block;
          border-radius: 50px;
          background: $color-theme;
          vertical-align: middle;
          margin: -5px 15px 0 0;
        }
      }
      &__text {
        padding: 30px 30px 0;
      }
    }
  }
}

.regionInfo {
  margin: 120px auto 80px;
  background: $color-theme-light;
  padding: 50px 30px 30px;
  position: relative;
  .regionList {
    padding: 30px 30px 30px;
    border-radius: 15px;
    background: #fff;
    &__item {
      display: inline-block;
      text-align: center;
      font-size: 1.6rem;
      margin: 8px 2px;
      @include mq(pc) {
        &:hover {
          a {
            box-shadow: none;
            transform: translateY(3px);
          }
        }
      }
      a {
        color: $font-color-base;
        text-decoration: none;
        background: $color-yellow;
        box-shadow: 0 3px 0 #957725;
        padding: 0px 10px;
        border-radius: 5px;
        display: block;
        @include animation-base(all,0s);
      }
      span {
        background: #e4e4e4;
        box-shadow: 0 3px 0 #b8b8b8;
        padding: 0px 10px;
        border-radius: 5px;
        display: block;
      }
    }
  }
  &__ttl {
    position: absolute;
    width: 23em;
    top: -40px;
    left: 50%;
    transform: translateX(-50%);
    padding: 12px;
    border-radius: 20px 20px 0 0;
    background: $color-theme;
    font-size: 3rem;
    font-weight: 700;
    line-height: 1.2;
    letter-spacing: .1em;
    text-align: center;
    color: #fff;
  }
  &__text {
    font-size: 1.7rem;
    padding: 20px 0 30px;
  }
}

@include mq(sp) {
  /*=================================
    area
  =================================*/
  .areaInfo {
    padding: 70px per(17) 40px;
    &__inner {
      padding: 30px per(17) 30px;
      border-radius: 10px;
      box-shadow: 0 3px 15px rgba(48, 74, 133, 0.05);
      .areaList {
        padding: 20px per(12);
        border-radius: 10px;
        display: block;
        &__item {
          font-size: 1.5rem;
          display: inline-block;
          margin: 5px 3px;
          vertical-align: bottom;
          &::before {
            font-size: 1.2rem;
            right: 8px;
          }
          a {
            border-radius: 0;
            padding: 10px 30px 10px 12px;
          }
          span {
            padding: 5px 8px;
            border-radius: 3px;
          }
        }
      }
    }
    &__ttl {
      width: 100%;
      padding: 8px;
      border-radius: 10px 10px 0 0;
      background: $color-theme;
      font-size: 1.8rem;
      line-height: 1.5;
    }
    &__text {
      font-size: 1.4rem;
      line-height: 1.71428;
      padding: 8px 0 12px;
    }
  }
  .mv {
    & + .areaInfo {
      padding: 50px per(17);
      margin-top: 0;
      .areaInfo {
        &__inner {
          padding: 30px per(17);
          border-radius: 0;
          background: #fff;
          background-size: contain;
          box-shadow: 0 5px 25px rgba(48, 74, 133, 0.12);
          position: relative;
          .areaList {
            padding: 20px per(12) 0;
            border-radius: 0;
            display: block;
            &__item {
              font-size: 1.5rem;
              &::before {
                font-size: 1.2rem;
                right: 8px;
              }
              a {
                padding: 10px 30px 10px 12px;
                border-radius: 0;
              }
            }
          }
        }
        &__ttl {
          font-size: 2.4rem;
          line-height: 1.083333;
          &::before {
            width: 6px;
            height: 6px;
            margin: -5px 8px 0 0;
          }
        }
        &__text {
          padding: 20px per(14) 0;
        }
      }
    }
  }

  .regionInfo {
    margin: 70px per(20) 50px;
    padding: 20px per(17);
    .regionList {
      padding: 20px per(17);
      border-radius: 10px;
      &__item {
       font-size: 1.5rem;
        margin: 8px 2px;
        a {
          padding: 0px 8px;
          border-radius: 2px;
        }
        span {
          padding: 0px 8px;
          border-radius: 2px;
        }
      }
    }
    &__ttl {
      width: 100%;
      top: -40px;
      left: 0;
      transform: translateX(0);
      padding: 8px;
      border-radius: 10px 10px 0 0;
      font-size: 1.8rem;
      line-height: 1.5;
    }
    &__text {
      font-size: 1.4rem;
      line-height: 1.71428;
      padding: 8px 0 12px;
    }
  }
}

@charset "UTF-8";


@keyframes show {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
@keyframes hide {
  0% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}
@keyframes vibrate {
  0% {
    transform: rotate(0deg);
  }
  25% {
    transform: rotate(15deg);
  }
  50% {
    transform: rotate(-15deg);
  }
  75% {
    transform: rotate(15deg);
  }
  100% {
    transform: rotate(0deg);
  }
}
@keyframes vibrate02 {
  0% {
    transform: rotate(0deg) scale(1.2);
  }
  5% {
    transform: rotate(15deg) scale(1.2);
  }
  10% {
    transform: rotate(-15deg) scale(1.2);
  }
  15% {
    transform: rotate(15deg) scale(1.2);
  }
  20% {
    transform: rotate(0deg) scale(1);
  }
  100% {
    transform: rotate(0deg) scale(1);
  }
}
@keyframes burger-show {
  0% {
    right: -50%;
  }
  100% {
    right: 0%;
  }
}
@keyframes header-show {
  0% {
    opacity: 0;
    top: -100px;
  }
  100% {
    opacity: 1;
    top: 0;
  }
}
a:hover {
  outline: none;
}
a:active {
  outline: none;
}
a:focus {
  outline: none;
}

@media screen and (min-width: 767px) { /* =================================
  hover
================================= */
  a {
    transition-property: all;
    transition-duration: 0.2s;
    transition-timing-function: ease-out;
  }
  .widelink {
    transition-property: all;
    transition-duration: 0.2s;
    transition-timing-function: ease-out;
    cursor: pointer;
  }
  .widelink .widelink__cover {
    position: relative;
    overflow: hidden;
  }
  .widelink .widelink__cover::before {
    content: "";
    background: url(../img/common/btn_more.png) no-repeat center center;
    background-size: contain;
    width: 130px;
    height: 44px;
    opacity: 0;
    position: absolute;
    top: 45%;
    left: 50%;
    transform: translate(-50%, -50%);
    z-index: 6;
    transition-property: opacity, top;
    transition-duration: 0.15s;
    transition-timing-function: ease-out;
  }
  .widelink .widelink__cover::after {
    content: "";
    position: absolute;
    top: 0px;
    left: 0px;
    width: 100%;
    height: 100%;
    z-index: 5;
    opacity: 0;
    background: rgb(61, 84, 138);
    background: -moz-linear-gradient(135deg, rgba(61, 84, 138, 0.7) 0%, rgba(100, 133, 209, 0.7) 100%);
    background: -webkit-linear-gradient(135deg, rgba(61, 84, 138, 0.7) 0%, rgba(100, 133, 209, 0.7) 100%);
    background: linear-gradient(135deg, rgba(61, 84, 138, 0.7) 0%, rgba(100, 133, 209, 0.7) 100%);
    filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#3d548a",endColorstr="#6485d1",GradientType=1);
    color: white;
    transition-property: opacity;
    transition-duration: 0.15s;
    transition-timing-function: ease-out;
  }
  .widelink .widelink__menu {
    position: relative;
    overflow: hidden;
    display: block;
  }
  .widelink .widelink__menu::before {
    content: "詳しく見る";
    font-size: 1.3rem;
    font-weight: 700;
    color: #fff;
    line-height: 1;
    text-align: center;
    opacity: 0;
    padding: 15px 9%;
    border: 1px solid #fff;
    border-radius: 50px;
    position: absolute;
    bottom: 12%;
    left: 50%;
    transform: translateX(-50%);
    z-index: 6;
    transition-property: opacity, bottom;
    transition-duration: 0.15s;
    transition-timing-function: ease-out;
  }
  .widelink .widelink__article {
    position: relative;
    overflow: hidden;
  }
  .widelink .widelink__article::before {
    content: "";
    display: inline-block;
    width: 130px;
    height: 24px;
    background: url(../img/link_more.png) no-repeat center center;
    opacity: 0;
    position: absolute;
    top: 45%;
    left: 50%;
    transform: translate(-50%, -50%);
    z-index: 6;
    transition-property: opacity, top;
    transition-duration: 0.15s;
    transition-timing-function: ease-out;
  }
  .widelink .widelink__article::after {
    content: "";
    position: absolute;
    top: 0px;
    left: 0px;
    width: 100%;
    height: 100%;
    z-index: 5;
    opacity: 0;
    background-color: rgba(0, 84, 142, 0.7);
    color: white;
    transition-property: opacity;
    transition-duration: 0.15s;
    transition-timing-function: ease-out;
  }
  .widelink:hover a {
    text-decoration: none;
  }
  .widelink:hover .widelink__cover::before, .widelink:hover .widelink__article::before {
    opacity: 1;
    top: 50%;
  }
  .widelink:hover .widelink__cover::after, .widelink:hover .widelink__article::after {
    opacity: 1;
  }
  .widelink:hover .widelink__menu::before {
    opacity: 1;
    bottom: 10%;
  }
  .hoverBg .widelink__cover::before {
    content: none;
  }
  .hoverBg .widelink__cover::after {
    content: none;
  }
  .hoverBg:hover {
    background: #F5F8FF;
  }
}
/*=================================
  button
=================================*/
.btnWrap {
  margin: 20px auto 30px;
  text-align: center;
}
.btnWrap.center {
  text-align: center;
}
.btnWrap.right {
  text-align: right;
}
.btnWrap > * + * {
  margin-top: 9px;
}

.btn--blue {
  font-size: 1.4rem;
  font-weight: 500;
  color: #fff;
  display: inline-block;
  text-align: center;
  text-decoration: none;
  background: #304A85;
  padding: 15px;
}

.btn--yellow {
  font-size: 2.1rem;
  font-weight: 700;
  color: #222;
  display: inline-block;
  text-align: center;
  text-decoration: none;
  background: #FECB3F;
  border-radius: 5px;
  box-shadow: 0 4px 0 #957725;
  padding: 22px 1.2820512821%;
  position: relative;
  width: 100%;
  text-align: center;
}
.btn--yellow .icon {
  width: 28px;
  margin: 0 10px 4px 0;
}

.btn--gray {
  font-size: 1.9rem;
  font-weight: 700;
  line-height: 1.05;
  color: #fff;
  display: inline-block;
  text-align: center;
  text-decoration: none;
  background: #333333;
  box-shadow: 0 5px 0 #202427;
  display: block;
  padding: 28px 30px;
  position: relative;
  transition-property: all;
  transition-duration: 0s;
  transition-timing-function: ease-out;
}
@media screen and (min-width: 767px) and (max-width: 1366px) {
  .btn--gray {
    padding: 2.4158125915vw 16.4714494876vw;
  }
}
.btn--gray::after {
  content: "";
  width: 12px;
  height: 19px;
  display: inline-block;
  vertical-align: middle;
  background: url(../img/icon/icon_arrow_w.png) no-repeat center center;
  background-size: contain;
  position: absolute;
  top: 50%;
  right: 16px;
  transform: translateY(-50%);
}

.btn--blueShadow {
  font-size: 1.4rem;
  font-weight: 500;
  color: #fff;
  display: inline-block;
  text-align: center;
  background: #3da7f0;
  box-shadow: 0 3px 0 #304A85;
  border-radius: 4px;
  padding: 23px 22px;
  text-decoration: none;
}

.btn--ghost {
  font-size: 1.5rem;
  font-weight: 500;
  color: #fff;
  display: inline-block;
  text-align: center;
  text-decoration: none;
  padding: 15px 58px;
  border: 1px solid #fff;
  background: rgba(255, 255, 255, 0.3);
  position: relative;
}
.btn--ghost::after {
  content: "";
  width: 9px;
  height: 15px;
  display: inline-block;
  vertical-align: middle;
  background: url(../img/icon/icon_arrow_w.png) no-repeat center center;
  background-size: contain;
  position: absolute;
  top: 50%;
  right: 24px;
  transform: translateY(-50%);
}

.btn--border {
  font-size: 1.6rem;
  font-weight: 500;
  line-height: 1;
  color: #304A85;
  border: 3px solid #304A85;
  background: #fff;
  text-decoration: none;
  text-align: center;
  display: block;
  margin: 0 auto;
  padding: 16px 1%;
}
.btn--border::before {
  content: "";
  display: inline-block;
  width: 17px;
  height: 17px;
  background: url(../img/btn_arrow.png) no-repeat center center;
  background-size: contain;
  vertical-align: middle;
}

.btn--more {
  font-size: 1.6rem;
  font-weight: 700;
  line-height: 1.375;
  color: #222;
  border: 1px solid #304A85;
  text-decoration: none;
  text-align: left;
  display: inline-block;
  margin: 0 auto;
  padding: 17px 16.1538461538% 17px 9.7435897436%;
  background: url(../img/common/icon/icon_arrow_right_sp.png) no-repeat right 3.5897435897% center #fff;
  background-size: 29px auto;
  border-radius: 7px;
  position: relative;
}
.btn--more::before {
  content: "";
  width: 10px;
  height: 10px;
  background: #304A85;
  display: inline-block;
  vertical-align: middle;
  border-radius: 50px;
  position: absolute;
  top: 24px;
  left: 5.1282051282%;
}

@media screen and (min-width: 767px) {
  /*=================================
    button
  =================================*/
  .btnWrap {
    display: flex;
    justify-content: center;
  }
  .btnWrap > * + * {
    margin: 0 20px;
  }
  .btn--blue {
    font-size: 2.2rem;
    padding: 23px 37px;
  }
  .btn--blue:hover {
    opacity: 0.9;
  }
  .btn--yellow {
    font-size: 2.3rem;
    border-radius: 10px;
    box-shadow: 0 3px 0 #957725;
    padding: 22px 10px;
    max-width: 504px;
    transition-property: all;
    transition-duration: 0s;
    transition-timing-function: ease-out;
  }
  .btn--yellow .icon {
    width: 37px;
    position: absolute;
    top: 26px;
    left: 45px;
    margin: 0;
  }
  .btn--yellow:hover {
    box-shadow: none;
    transform: translateY(3px);
  }
  .btn--gray {
    font-size: 2rem;
    padding: 33px 225px;
  }
  .btn--gray::after {
    width: 14px;
    height: 24px;
    right: 16px;
  }
  .btn--gray:hover {
    box-shadow: none;
    transform: translateY(5px);
  }
  .btn--blueShadow {
    font-size: 2.2rem;
    box-shadow: 0 5px 0 #304A85;
    padding: 24px 30px;
    transition: none;
  }
  .btn--blueShadow:hover {
    transform: translateY(5px);
    box-shadow: none;
  }
  .btn--ghost {
    font-weight: 700;
    background: transparent;
  }
  .btn--ghost:hover {
    background: rgba(255, 255, 255, 0.2);
  }
  .btn--border {
    font-size: 2.2rem;
    border-width: 4px;
    padding: 20px 66px;
    margin: 0;
    display: inline-block;
  }
  .btn--border::before {
    width: 24px;
    height: 24px;
    margin: 0 6px 3px 0;
  }
  .btn--border:hover {
    background: #f0f8fe;
  }
  .btn--more {
    font-size: 2rem;
    line-height: 2;
    text-align: center;
    display: block;
    margin: 0 auto;
    padding: 25px 48px 25px 10px;
    background: url(../img/common/icon/icon_arrow_right_pc.png) no-repeat right 20px center #fff;
    background-size: 38px auto;
    width: 100%;
    max-width: 600px;
  }
  .btn--more::before {
    width: 12px;
    height: 12px;
    position: static;
    margin: 0 8px 0 0;
  }
  .btn--more:hover {
    color: #fff;
    background: url(../img/common/icon/icon_arrow_right_pc.png) no-repeat right 20px center #304A85;
  }
  .btn--more:hover::before {
    background: #fff;
  }
}
/*=================================
  title
=================================*/
.ttl--base {
  font-size: 4.5rem;
  font-weight: 700;
  line-height: 1.5;
  margin-bottom: 50px;
}
.ttl--base::before {
  content: "";
  width: 12px;
  height: 12px;
  display: inline-block;
  vertical-align: middle;
  border-radius: 50px;
  background: #304A85;
  margin: -5px 15px 0 0;
}
.ttl--base:not(.left) {
  text-align: center;
}
.ttl--base.white {
  color: #fff;
}
.ttl--base.white::before {
  background: #fff;
}
.ttl--base + .ttl--text {
  margin-top: -40px;
  margin-bottom: 50px;
}

.ttl--page {
  padding: 30px 1% 0;
  margin-bottom: 30px;
}
.ttl--page__inner {
  max-width: 1140px;
  margin: 0 auto;
}
.ttl--page .ttl {
  font-size: 4.5rem;
  font-weight: 700;
  line-height: 1.33333;
  padding-left: 25px;
  position: relative;
}
.ttl--page .ttl::before {
  content: "";
  width: 12px;
  height: 12px;
  display: inline-block;
  vertical-align: middle;
  border-radius: 50px;
  background: #304A85;
  position: absolute;
  top: 25px;
  left: 0;
}
.ttl--page .text {
  font-size: 1.6rem;
  font-weight: 400;
  margin-top: 7px;
}

@media screen and (max-width: 767px) {
  /*=================================
    title
  =================================*/
  .ttl--base {
    font-size: 2rem;
    line-height: 1.55;
    margin-bottom: 20px;
  }
  .ttl--base + .ttl--text {
    margin-top: -10px;
    margin-bottom: 20px;
  }
  .ttl--page {
    padding: 0 5.1282051282%;
    margin-bottom: 30px;
  }
  .ttl--page .ttl {
    font-size: 2.4rem;
    padding-left: 15px;
  }
  .ttl--page .ttl::before {
    width: 8px;
    height: 8px;
    top: 13px;
  }
  .ttl--page .text {
    font-size: 1.4rem;
    font-weight: 400;
    line-height: 1.714285;
  }
}
/*=================================
  tab
=================================*/
.tabMenu {
  display: flex;
}
.tabMenu__item {
  font-size: 2.2rem;
  line-height: 1.363636;
  font-weight: 500;
  color: #222;
  background: #F3F3F3;
  padding: 30px 24px;
  text-align: center;
  width: 100%;
}
.tabMenu__item:not(.active) {
  cursor: pointer;
}
.tabMenu__item.active {
  background: #304A85;
  color: #fff;
}
@media screen and (min-width: 767px) {
  .tabMenu__item:hover:not(.active) {
    background: #F5F8FF;
  }
}

.tabItem {
  background: #fff;
  min-height: 190px;
}
.tabItem .tabList {
  display: none;
}
.tabItem .tabList.show {
  display: block !important;
}

@media screen and (max-width: 767px) {
  /*=================================
    tab
  =================================*/
  .tabMenu__item {
    font-size: 1.6rem;
    padding: 27px 4.358974359%;
  }
  .tabItem {
    min-height: inherit;
  }
}
/*=================================
  accordion
=================================*/
.js-accordion__btn {
  position: relative;
  cursor: pointer;
}

.js-accordion__item {
  display: none;
}

@media screen and (min-width: 0) and (max-width: 767px) {
  /*=================================
    accordion
  =================================*/
}
/*=================================
  table
=================================*/
table {
  border-collapse: collapse;
  border-spacing: 0;
  margin: 30px 0 50px;
  width: 100%;
  border: 1px solid #E3E3E3;
}
table caption {
  font-size: 1.4rem;
  margin: 20px 0;
  text-align: left;
  caption-side: bottom;
}
table thead th, table thead td {
  font-size: 1.6rem;
  font-weight: 700;
  color: #fff;
  text-align: center;
  background: #304A85;
}
table th {
  font-size: 1.4rem;
  font-weight: 700;
  line-height: 1.5;
  text-align: left;
  background: #F5F8FF;
}
@media screen and (min-width: 767px) {
  table th {
    width: 250px;
  }
}
table td {
  font-size: 1.4rem;
  font-weight: 400;
  line-height: 1.5;
}
@media screen and (min-width: 767px) {
  table td {
    width: calc(100% - 250px);
  }
}
table th, table td {
  padding: 19px 25px;
  border: 1px solid #E3E3E3;
  vertical-align: top;
}
table.scroll {
  white-space: nowrap;
  overflow: auto;
  display: block;
  border: none;
}
table.scroll ::-webkit-scrollbar {
  height: 5px;
}
table.scroll ::-webkit-scrollbar-track {
  background: #f1f1f1;
}
table.scroll ::-webkit-scrollbar-thumb {
  background: #bcbcbc;
}

.scrollAnnounce {
  display: none;
}

@media screen and (max-width: 767px) {
  /*=================================
    table
  =================================*/
  table {
    margin: 20px 0 40px;
    /* スクロールのつまみ部分の設定 */
  }
  table:not(.noscroll) {
    display: block;
    white-space: nowrap;
    overflow-x: auto;
    border: none;
  }
  table caption {
    font-size: 1.5rem;
    margin: 15px 0;
  }
  table th, table td {
    padding: 14px 20px;
  }
  table::-webkit-scrollbar {
    width: 5px;
    height: 5px;
  }
  table::-webkit-scrollbar-track {
    border-radius: none;
    background: #d6d6d6;
  }
  table::-webkit-scrollbar-thumb {
    background: #FECB3F;
  }
  .scrollAnnounce {
    display: block;
    text-align: center;
  }
  .scrollAnnounce .text {
    font-size: 1.3rem;
    font-weight: 600;
    color: #fff;
    background: #304A85;
    padding: 3px 6px;
    position: relative;
  }
  .scrollAnnounce .text::after {
    content: "";
    border-style: solid;
    border-width: 7px 6.5px 0 6.5px;
    border-color: #304A85 transparent transparent transparent;
    display: inline-block;
    position: absolute;
    bottom: -7px;
    left: 50%;
    transform: translateX(-50%);
  }
}
/*=================================
  breadcrumb
=================================*/
.breadcrumb {
  margin-bottom: 20px;
}
.breadcrumb ul li {
  font-size: 1.4rem;
  line-height: 1;
  color: #B8B8B8;
  display: inline-block;
}
.breadcrumb ul li a {
  font-size: 1.4rem;
  line-height: 1;
  color: #222;
  text-decoration: none;
  margin-right: 18px;
}
@media screen and (min-width: 767px) {
  .breadcrumb ul li a:hover {
    color: #304A85;
    text-decoration: underline;
  }
}
.breadcrumb ul li a::after {
  content: "";
  width: 6px;
  height: 10px;
  background: url(../img/common/icon/icon_arrow_breadcrumb.png) no-repeat center center;
  background-size: contain;
  display: inline-block;
  margin-left: 18px;
}
.breadcrumb.foot {
  margin: 0;
  padding: 17px 1%;
  border-bottom: 1px solid #E1E1E1;
}
.breadcrumb.foot .inner-wrap {
  max-width: 1260px;
}

@media screen and (min-width: 0) and (max-width: 767px) {
  /*=================================
    breadcrumb
  =================================*/
  .breadcrumb {
    margin-bottom: 15px;
  }
  .breadcrumb ul {
    overflow-x: scroll;
    white-space: nowrap;
  }
  .breadcrumb ul li {
    font-size: 1rem;
    line-height: 1.5;
  }
  .breadcrumb ul li a {
    font-size: 1rem;
    line-height: 1.5;
    margin-right: 7px;
  }
  .breadcrumb ul li a::after {
    margin-left: 7px;
    margin-bottom: -1px;
  }
  .breadcrumb.foot {
    padding: 20px 4.358974359%;
  }
}
/*=================================
  pager
=================================*/
.pager {
  max-width: 1000px;
  margin-top: 80px;
}
.pagerList {
  display: flex;
  justify-content: center;
}
.pagerList__item {
  margin-right: 6px;
}
.pagerList__item a, .pagerList__item span {
  font-size: 1.5rem;
  line-height: 1;
  padding: 13px 16px;
  display: block;
}
.pagerList__item a {
  color: #304A85;
  text-decoration: none;
  background: #F5F8FF;
}
@media screen and (min-width: 767px) {
  .pagerList__item a:hover {
    color: #fff;
    background: #304A85;
  }
}
.pagerList__item span {
  color: #fff;
  background: #304A85;
}
.pager .wp-pagenavi {
  display: flex;
  justify-content: center;
}
.pager .wp-pagenavi .pages {
  font-size: 1.5rem;
  line-height: 1;
  padding: 13px 16px;
  margin-right: 6px;
  border: 1px solid #304A85;
}
.pager .wp-pagenavi .current {
  font-size: 1.5rem;
  line-height: 1;
  color: #fff;
  padding: 13px 16px;
  background: #304A85;
  display: block;
  text-decoration: none;
  border: 1px solid #304A85;
}
.pager .wp-pagenavi .page {
  font-size: 1.5rem;
  color: #304A85;
  line-height: 1;
  padding: 13px 16px;
  display: block;
  background: #F5F8FF;
  text-decoration: none;
  margin-left: 6px;
  border: 1px solid #F5F8FF;
}
@media screen and (min-width: 767px) {
  .pager .wp-pagenavi .page:hover {
    color: #fff;
    background: #304A85;
  }
}
.pager .wp-pagenavi .nextpostslink {
  font-size: 1.5rem;
  color: #304A85;
  line-height: 1;
  padding: 13px 16px;
  display: block;
  background: #fff;
  text-decoration: none;
  margin-left: 6px;
  border: 1px solid #304A85;
}
@media screen and (min-width: 767px) {
  .pager .wp-pagenavi .nextpostslink:hover {
    color: #fff;
    background: #304A85;
  }
}
.pager .wp-pagenavi .previouspostslink {
  font-size: 1.5rem;
  color: #304A85;
  line-height: 1;
  padding: 13px 16px;
  display: block;
  background: #fff;
  text-decoration: none;
  border: 1px solid #304A85;
}
@media screen and (min-width: 767px) {
  .pager .wp-pagenavi .previouspostslink:hover {
    color: #fff;
    background: #304A85;
  }
}

@media screen and (max-width: 767px) {
  /*=================================
    pager
  =================================*/
  .pager {
    display: block;
    text-align: center;
    margin-top: 40px;
  }
  .pagerList {
    justify-content: center;
  }
  .pagerList__item {
    margin-right: 8px;
  }
  .pagerList__item a, .pagerList__item span {
    padding: 11px 14px;
  }
  .pager .wp-pagenavi .pages {
    padding: 11px 14px;
    margin-right: 8px;
  }
  .pager .wp-pagenavi .current {
    padding: 11px 14px;
  }
  .pager .wp-pagenavi .page {
    padding: 11px 14px;
    margin-left: 8px;
  }
  .pager .wp-pagenavi .nextpostslink {
    padding: 11px 14px;
    margin-left: 8px;
  }
  .pager .wp-pagenavi .previouspostslink {
    padding: 11px 14px;
  }
}
/*=================================
  PR表記
=================================*/
.tooltip--float { /* ツールチップを設置する「親要素」に指定、そこを基準に右上に配置される */
  position: relative;
}

.tooltip--float .tooltipWrap {
  cursor: pointer;
  border-radius: 2px;
  width: 100%;
  position: absolute;
  top: 0;
  right: 0;
}

.tooltip--float .js--tooltipTarget {
  font-size: 1.1rem;
  line-height: 1;
  letter-spacing: 0.05em;
  color: #fff;
  background: #6e7682;
  display: inline-block;
  padding: 6px 9px;
  position: absolute;
  top: 0;
  right: 0;
  z-index: 999;
}

.tooltip--float .js--tooltipItem {
  font-size: 0.9rem;
  padding: 10px;
  border-radius: 5px;
  color: #555;
  background: #d5d7d9;
  width: 50%;
  position: absolute;
  z-index: 1000;
  display: none;
}

.tooltip--float .tooltipWrap:hover .js--tooltipItem {
  display: inline-block;
  top: 32px;
  right: 5px;
  width: 300px;
}

.tooltip--float .js--tooltipItem::before {
  content: "";
  border-style: solid;
  border-width: 0 6px 12px 6px;
  border-color: transparent transparent #d5d7d9 transparent;
  position: absolute;
  top: -12px;
  right: 10px;
  z-index: 1000;
}

.tooltip--static {
  position: relative;
  text-align: right;
}

.tooltip--static .tooltipWrap {
  display: inline-block;
}

.tooltip--static .js--tooltipTarget {
  font-size: 1.1rem;
  line-height: 1;
  letter-spacing: 0.05em;
  color: #fff;
  background: #858f9e;
  display: inline-block;
  padding: 6px 9px;
  cursor: pointer;
  position: relative;
  z-index: 999;
}

.tooltip--static .js--tooltipItem {
  font-size: 1.1rem;
  padding: 10px;
  border-radius: 5px;
  color: #555;
  background: #e5e8eb;
  width: 50%;
  text-align: left;
  position: absolute;
  z-index: 1000;
  display: none;
}

.tooltip--static .tooltipWrap:hover .js--tooltipItem {
  display: inline-block;
  top: 35px;
  right: 5px;
  width: 300px;
}

.tooltip--static .js--tooltipItem::before {
  content: "";
  border-style: solid;
  border-width: 0 6px 12px 6px;
  border-color: transparent transparent #d5d7d9 transparent;
  position: absolute;
  top: -12px;
  right: 10px;
  z-index: 1000;
}

/*=================================
  topPage
=================================*/
.home .secWrap {
  padding: 90px 1% 60px;
}
.home .secWrap__inner {
  max-width: 1140px;
  margin: 0 auto;
}
.home .secWrap.reason { /* 選ばれる理由 */
  padding: 100px 0 64px calc((100% - 1140px) / 2);
  background: url(../img/top/bg_reason.svg) no-repeat right 63px top 142px;
  background-size: 300px auto;
}
@media screen and (min-width: 767px) and (max-width: 1366px) {
  .home .secWrap.reason {
    padding: 100px 0 64px 1%;
  }
}
.home .secWrap.reason .reason__inner {
  background: rgb(48, 74, 133);
  background: -moz-linear-gradient(95deg, rgba(48, 74, 133, 0.15) 0%, rgba(119, 98, 49, 0.15) 100%);
  background: -webkit-linear-gradient(95deg, rgba(48, 74, 133, 0.15) 0%, rgba(119, 98, 49, 0.15) 100%);
  background: linear-gradient(95deg, rgba(48, 74, 133, 0.15) 0%, rgba(119, 98, 49, 0.15) 100%);
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#304a85",endColorstr="#776231",GradientType=1);
  padding: 50px clamp(1%, 8.78477306vw, 120px) 65px 70px;
  border-radius: 30px 0 0 30px;
}
.home .secWrap.reason .reasonList {
  max-width: 1000px;
  display: flex;
  flex-wrap: wrap;
}
.home .secWrap.reason .reasonList__item {
  text-align: center;
  width: calc((100% - clamp(50px, 7.3206442167vw, 100px)) / 3);
  background: #fff;
  box-shadow: 0 6px 18px rgba(48, 74, 133, 0.1);
  border-radius: 15px;
  padding: 47px 0 40px;
  display: flex;
  flex-direction: column;
}
.home .secWrap.reason .reasonList__item:not(:nth-child(3n+1)) {
  margin-left: clamp(25px, 3.6603221083vw, 50px);
}
.home .secWrap.reason .reasonList__item .icon {
  width: 45%;
  margin: 0 auto 22px;
}
.home .secWrap.reason .reasonList__item .text {
  font-size: clamp(2.5rem, 2.5622254758vw, 3.5rem);
  font-weight: 700;
  line-height: 1.166666;
  margin: auto;
}
.home .secWrap.reason .reasonList__item .text.sm {
  font-size: clamp(2rem, 2.196193265vw, 3rem);
}
.home .secWrap.reason .reasonList__item .numWrap {
  font-size: clamp(2rem, 2.196193265vw, 3rem);
  display: block;
}
.home .secWrap.reason .reasonList__item .numWrap .num {
  font-size: clamp(2rem, 2.196193265vw, 3.4rem);
  font-weight: 700;
  line-height: 1.32352;
  color: #304A85;
  border-bottom: 5px solid #304A85;
  margin: 0 6px;
}
.home .secWrap.reason .reasonList__item .numWrap .num:not(.sm) {
  font-family: "Lato", sans-serif;
  font-size: clamp(4rem, 4.9780380673vw, 6.8rem);
}
.home .secWrap.faq {
  background: url(../img/top/bg_qa_pc.png) no-repeat right top 38px #fff;
  padding: 100px 0 55px;
}
@media screen and (min-width: 767px) and (max-width: 1366px) {
  .home .secWrap.faq {
    background-size: 60% auto;
  }
}
.home .secWrap.faq .secWrap__inner {
  padding: 0 70px;
}
@media screen and (min-width: 767px) and (max-width: 1366px) {
  .home .secWrap.faq .secWrap__inner {
    padding: 0 5.1244509517vw;
  }
}
.home .secWrap.faq .faqWrap {
  margin-top: 105px;
}
.home .secWrap.faq .faqList {
  padding: 28px 40px;
  box-shadow: 0 5px 25px rgba(34, 34, 34, 0.05);
  border-radius: 15px;
  background: #fff;
}
.home .secWrap.faq .faqList .q {
  font-size: 2.4rem;
  font-weight: 500;
  color: #304A85;
  padding-left: 34px;
  position: relative;
}
.home .secWrap.faq .faqList .q::before {
  font-family: "Lato", sans-serif;
  content: "Q";
  font-size: 2.7rem;
  font-weight: 700;
  color: #304A85;
  display: inline-block;
  position: absolute;
  top: -3px;
  left: 0;
}
.home .secWrap.faq .faqList .q::after {
  content: "";
  width: 50px;
  height: 50px;
  display: inline-block;
  background: url(../img/common/icon/icon_qa_open_pc.png) no-repeat center center;
  background-size: contain;
  position: absolute;
  right: 30px;
  top: 50%;
  transform: translateY(-50%);
  transition-property: all;
  transition-duration: 0.2s;
  transition-timing-function: ease-out;
}
@media screen and (min-width: 767px) and (max-width: 1366px) {
  .home .secWrap.faq .faqList .q::after {
    width: 3.6603221083vw;
    height: 3.6603221083vw;
    right: 2.196193265vw;
  }
}
.home .secWrap.faq .faqList .q.open::after {
  background: url(../img/common/icon/icon_qa_close_pc.png) no-repeat center center;
  background-size: contain;
}
.home .secWrap.faq .faqList .a {
  font-size: 1.6rem;
  line-height: 1.75;
  padding-top: 10px;
}
.home .secWrap.faq .faqList .a * + p {
  margin-top: 44px;
}
.home .secWrap.faq .faqList .a a {
  font-weight: 500;
}
.home .secWrap.faq .faqList + .faqList {
  margin-top: 20px;
}
.home .media__ttl {
  color: #fff;
  text-align: center;
  background: url(../img/top/bg_media_pc.jpg) no-repeat center center;
  background-size: cover;
  padding: 65px 1% 79px;
}
.home .media__ttl .ttl {
  font-size: 4.5rem;
  font-weight: 700;
  line-height: 1.5;
}
.home .media__ttl .ttl::before {
  content: "";
  width: 12px;
  height: 12px;
  display: inline-block;
  vertical-align: middle;
  border-radius: 50px;
  background: #fff;
  margin: -5px 15px 0 0;
}
.home .media__ttl .text {
  font-size: 1.6rem;
  line-height: 2;
  margin: 7px 0 0;
}
.home .mediaWrap {
  background: rgb(77, 102, 158);
  background: -moz-linear-gradient(135deg, rgb(77, 102, 158) 0%, rgb(48, 74, 133) 100%);
  background: -webkit-linear-gradient(135deg, rgb(77, 102, 158) 0%, rgb(48, 74, 133) 100%);
  background: linear-gradient(135deg, rgb(77, 102, 158) 0%, rgb(48, 74, 133) 100%);
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#4d669e",endColorstr="#304a85",GradientType=1);
  padding: 20px 1% 50px;
}
.home .mediaList {
  max-width: 1140px;
  margin: 0 auto;
  display: flex;
  flex-wrap: wrap;
}
.home .mediaList__item {
  display: flex;
  justify-content: center;
  align-items: center;
  width: calc((100% - 180px) / 6);
  height: 110px;
  background: #fff;
  border-radius: 5px;
  margin-top: 30px;
  text-align: center;
}
@media screen and (min-width: 767px) {
  .home .mediaList__item:hover a {
    box-shadow: 0 5px 25px rgba(34, 34, 34, 0.5);
  }
  .home .mediaList__item:hover a img {
    transform: scale(1.1);
  }
}
.home .mediaList__item a {
  height: 100%;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}
.home .mediaList__item img {
  display: inline-block;
  vertical-align: middle;
  transition-property: all;
  transition-duration: 0.2s;
  transition-timing-function: ease-out;
}
.home .mediaList__item img:not(.vertical) {
  width: 80%;
}
.home .mediaList__item img.vertical {
  width: auto;
  height: 90px;
}
.home .mediaList__item:not(:nth-child(6n+1)) {
  margin-left: 36px;
}
.home .relatedBox {
  padding: 100px 1%;
}
.home .access {
  background: url(../img/top/bg_access.png) no-repeat right 63px top 52px;
  padding: 100px 0;
}
.home .access__inner {
  max-width: 1140px;
  margin: 0 auto;
  padding: 0 0 0 70px;
}
@media screen and (min-width: 767px) and (max-width: 1366px) {
  .home .access__inner {
    padding: 0 5.1244509517vw;
  }
}
.home .accessCont {
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  flex-wrap: wrap;
}
.home .access__text {
  margin: 0 0 10px;
}
.home .access__gMap {
  width: calc(100% - 366px);
  min-width: 600px;
  padding-top: 44.86%;
  overflow: hidden;
  position: relative;
}
.home .access__gMap iframe {
  position: absolute;
  left: 0;
  top: 0;
  height: 100%;
  width: 100%;
}

/* メインビジュアル */
.mv {
  background: url(../img/top/mv_bg_pc.jpg) no-repeat center top;
  background-size: cover;
  padding: 0 50px 0 clamp(50px, 11.2005856515vw, 153px);
  position: relative;
}
@media screen and (min-width: 767px) and (max-width: 1366px) {
  .mv {
    padding: 0 50px 0 clamp(20px, 5.8565153734vw, 80px);
  }
}
.mv__inner {
  max-width: 1140px;
  margin: 0 auto;
  position: relative;
}
.mv__catch {
  font-size: 4.5rem;
  font-weight: 700;
  letter-spacing: 0.045em;
  line-height: 1.77777;
  color: #fff;
  padding: 125px 0 94px;
}
@media screen and (min-width: 767px) and (max-width: 1366px) {
  .mv__catch {
    font-size: 3.2942898975vw;
    padding: 9.1508052709vw 0 7.3206442167vw;
  }
}
.mv__catch .line {
  background: url(../img/top/mv_textDeco.png) repeat-x left bottom;
  background-size: 3em auto;
  padding-bottom: 10px;
}
.mv + .relatedBox {
  background: #fff;
}

.bnrBox {
  position: absolute;
  right: calc((100% - 1140px) / 2);
  bottom: -80px;
}
@media screen and (min-width: 767px) and (max-width: 1366px) {
  .bnrBox {
    right: 1.5%;
    bottom: -10vw;
  }
}
.bnrBox__inner {
  max-width: 1140px;
  margin: 0 auto;
  display: flex;
  flex-wrap: wrap;
}
.bnrBox__item {
  width: 480px;
  background: #fff;
  border-radius: 7px;
  box-shadow: 0 5px 25px rgba(34, 34, 34, 0.11);
  padding: 22px;
}
@media screen and (min-width: 767px) and (max-width: 1366px) {
  .bnrBox__item {
    width: 320px;
    padding: 1.6105417277vw;
  }
}
.bnrBox__item:nth-child(2n) {
  margin-left: 40px;
}
.bnrBox__item.yellow .bnrBox__link::before {
  background: #776231;
}
.bnrBox__item.yellow .bnrBox__img::after {
  background: url(../img/top/top_bnr_arrow02_pc.png) no-repeat center center;
  background-size: contain;
}
@media screen and (min-width: 767px) {
  .bnrBox__item:hover {
    box-shadow: 0 5px 25px rgba(34, 34, 34, 0.2);
  }
  .bnrBox__item:hover .bnrBox__link {
    text-decoration: underline;
  }
  .bnrBox__item:hover .bnrBox__img::after {
    transform: translateY(-50%) scale(0.9);
  }
}
.bnrBox__link {
  font-size: 2rem;
  font-weight: 700;
  line-height: 2;
  text-decoration: none;
  position: relative;
  margin: 15px 0 0;
  display: block;
}
@media screen and (min-width: 767px) and (max-width: 1366px) {
  .bnrBox__link {
    font-size: 1.6rem;
    margin: 10px 0 0;
  }
}
.bnrBox__link::before {
  content: "";
  width: 12px;
  height: 12px;
  display: inline-block;
  border-radius: 50px;
  background: #304A85;
  margin: 0 8px 0 0;
}
.bnrBox__img {
  display: block;
  position: relative;
}
.bnrBox__img img {
  border-radius: 5px 5px 0 0;
  overflow: hidden;
}
.bnrBox__img::after {
  content: "";
  width: 44px;
  height: 44px;
  display: inline-block;
  background: url(../img/top/top_bnr_arrow01_pc.png) no-repeat center center;
  background-size: contain;
  position: absolute;
  top: calc(50% + 22px);
  right: -22px;
  transform: translateY(-50%);
}
.bnrBox__text {
  font-size: 1.6rem;
  line-height: 1.75;
  margin: 17px 0 0;
}

@media screen and (max-width: 767px) {
  /*=================================
    topPage
  =================================*/
  .home .secWrap {
    padding: 60px 4.358974359%;
  }
  .home .secWrap.reason { /* 選ばれる理由 */
    padding: 60px 0 60px 4.358974359%;
    background: url(../img/top/bg_reason.svg) no-repeat right -80px top 76px;
    background-size: 260px auto;
  }
  .home .secWrap.reason .reason__inner {
    padding: 40px 10.2564102564% 40px 6.1538461538%;
    border-radius: 15px 0 0 15px;
  }
  .home .secWrap.reason .reasonList {
    display: block;
  }
  .home .secWrap.reason .reasonList__item {
    width: 100%;
    border-radius: 7px;
    padding: 22px 2.5641025641%;
    display: block;
  }
  .home .secWrap.reason .reasonList__item:not(:nth-child(3n+1)) {
    margin: 20px 0 0;
  }
  .home .secWrap.reason .reasonList__item .icon {
    width: 23%;
    margin: 0 auto 15px;
  }
  .home .secWrap.reason .reasonList__item .text {
    font-size: 2rem;
  }
  .home .secWrap.reason .reasonList__item .text.sm {
    font-size: 2rem;
  }
  .home .secWrap.reason .reasonList__item .numWrap {
    font-size: 2rem;
    display: inline-block;
  }
  .home .secWrap.reason .reasonList__item .numWrap .num {
    font-size: 2.5rem;
    line-height: 1.11428;
    border-bottom: 3px solid #304A85;
  }
  .home .secWrap.reason .reasonList__item .numWrap .num:not(.sm) {
    font-size: 3.5rem;
  }
  .home .secWrap.faq {
    padding: 60px 0;
    background: #fff;
  }
  .home .secWrap.faq .secWrap__inner {
    padding: 0;
  }
  .home .secWrap.faq .secWrap__inner .ttl--base, .home .secWrap.faq .secWrap__inner .ttl--text {
    padding: 0 10.2564102564%;
  }
  .home .secWrap.faq .faqWrap {
    margin-top: 30px;
    background: url(../img/top/bg_qa_sp.png) no-repeat right top;
    background-size: 89.7435897436% auto;
    padding: 40px 4.358974359% 60px 0;
  }
  .home .secWrap.faq .faqList {
    padding: 28px 15.3846153846% 28px 4.6153846154%;
    border-radius: 0 7px 7px 0;
  }
  .home .secWrap.faq .faqList .q {
    font-size: 1.8rem;
    font-weight: 700;
    padding-left: 22px;
  }
  .home .secWrap.faq .faqList .q::before {
    font-size: 1.8rem;
    top: 0;
  }
  .home .secWrap.faq .faqList .q::after {
    width: 36px;
    height: 36px;
    background: url(../img/common/icon/icon_qa_open_sp.png) no-repeat center center;
    background-size: contain;
    right: -15.3846153846%;
  }
  .home .secWrap.faq .faqList .q.open::after {
    background: url(../img/common/icon/icon_qa_close_sp.png) no-repeat center center;
    background-size: contain;
  }
  .home .secWrap.faq .faqList .a {
    font-size: 1.4rem;
    line-height: 1.857142;
    padding: 13px 0 0 22px;
  }
  .home .secWrap.faq .faqList .a * + p {
    margin-top: 22px;
  }
  .home .secWrap.faq .faqList .a a {
    font-weight: 500;
  }
  .home .secWrap.faq .faqList + .faqList {
    margin-top: 20px;
  }
  .home .media__ttl {
    background: url(../img/top/bg_media_sp.jpg) no-repeat center center;
    background-size: cover;
    padding: 40px 10.2564102564%;
  }
  .home .media__ttl .ttl {
    font-size: 2.4rem;
    line-height: 1;
  }
  .home .media__ttl .ttl::before {
    width: 8px;
    height: 8px;
    margin: -5px 9px 0 0;
  }
  .home .media__ttl .text {
    font-size: 1.4rem;
    line-height: 1.85714;
    text-align: left;
  }
  .home .mediaWrap {
    padding: 24px 6.9230769231% 40px;
  }
  .home .mediaList__item {
    width: 47.9487179487%;
    margin-top: 16px;
  }
  .home .mediaList__item img:not(.vertical) {
    width: 80%;
  }
  .home .mediaList__item img.vertical {
    width: auto;
    height: 90px;
  }
  .home .mediaList__item:not(:nth-child(6n+1)) {
    margin-left: 0;
  }
  .home .mediaList__item:nth-child(2n) {
    margin-left: 4.1025641026%;
  }
  .home .relatedBox {
    padding: 40px 10.2564102564%;
  }
  .home .access {
    background: url(../img/top/bg_access.png) no-repeat right -60px top 0;
    padding: 0 10.2564102564% 60px;
  }
  .home .access__inner {
    padding: 0;
  }
  .home .accessCont {
    display: block;
  }
  .home .access__text {
    margin: 0 0 30px;
  }
  .home .access__gMap {
    width: 100%;
    min-width: initial;
    padding-top: 94.2%;
    border-radius: 15px;
  }
  /* メインビジュアル */
  .mv {
    background: url(../img/top/mv_bg_sp.jpg) no-repeat center top;
    background-size: cover;
    padding: 0 10.2564102564%;
  }
  .mv__catch {
    font-size: 2.5rem;
    line-height: 1.6;
    padding: clamp(34px, 16.4102564103vw, 64px) 0 0;
  }
  .mv + .relatedBox {
    padding-top: 54px;
  }
  .bnrBox {
    position: relative;
    right: initial;
    bottom: -14px;
    margin-top: 3.5897435897vw;
  }
  .bnrBox__inner {
    display: block;
  }
  .bnrBox__item {
    width: 100%;
    border-radius: 5px;
    padding: 5.1282051282%;
  }
  .bnrBox__item:nth-child(2n) {
    margin-left: 0;
  }
  .bnrBox__link {
    font-size: 1.7rem;
    line-height: 1.58823;
    margin: 12px 0 0;
  }
  .bnrBox__link::before {
    width: 7px;
    height: 7px;
    margin: 0 7px 0 0;
  }
  .bnrBox__img::after {
    width: 35px;
    height: 35px;
    top: calc(50% + 17px);
    right: -5.1282051282%;
  }
}
/*=================================
  page--article
=================================*/
/* 記事一覧 */
.articleList {
  position: relative;
  z-index: 1;
}
.articleList__item {
  background: #F5F8FF;
  border-radius: 15px;
  max-width: 1000px;
}
@media screen and (min-width: 767px) {
  .articleList__item:hover .textWrap__ttl {
    text-decoration: underline !important;
  }
}
.articleList__item .itemWrap {
  padding: 40px 40px 40px 50px;
  display: flex;
  justify-content: flex-end;
  flex-direction: row-reverse;
  flex-wrap: wrap;
}
.articleList__item .itemWrap .thumb {
  width: 38%;
  max-width: 350px;
  margin-left: 2.8%;
  border-radius: 5px;
  overflow: hidden;
}
.articleList__item .itemWrap .thumb img {
  margin: 0 auto;
  width: 100%;
  height: 215px;
  object-fit: cover;
}
.articleList__item .itemWrap .thumb + .textWrap {
  width: 59.2%;
}
.articleList__item .itemWrap .textWrap {
  width: 100%;
}
.articleList__item .itemWrap .textWrap__date {
  font-size: 1.2rem;
  font-weight: 400;
  line-height: 1;
  margin-bottom: 14px;
}
.articleList__item .itemWrap .textWrap__ttl {
  font-size: 2.4rem;
  font-weight: 500;
  line-height: 1.66666;
  display: block;
  text-decoration: none;
  position: relative;
}
.articleList__item .itemWrap .textWrap__ttl::before {
  content: "";
  width: 8px;
  height: 8px;
  display: inline-block;
  background: #304A85;
  border-radius: 50px;
  position: absolute;
  top: 16px;
  left: -20px;
}
.articleList__item .itemWrap .textWrap__text {
  font-size: 1.6rem;
  font-weight: 400;
  line-height: 2;
  margin-top: 20px;
}
.articleList__item + .articleList__item {
  margin-top: 40px;
}

.articleListWrap {
  padding: 30px 0 102px calc((100% - 1000px) / 4);
  background: url(../img/under/bg_kv_pc.jpg) no-repeat center top;
  background-size: contain;
}
@media screen and (min-width: 767px) and (max-width: 1366px) {
  .articleListWrap {
    padding: 30px 0 102px 3%;
  }
}
.articleListWrap__in {
  padding: 40px 70px 40px;
  background: #fff;
  border-radius: 20px 0 0 0;
  position: relative;
}
@media screen and (min-width: 767px) and (max-width: 1366px) {
  .articleListWrap__in {
    padding: 70px 5.1244509517vw 40px;
  }
}
.articleListWrap__in .ttl--page {
  padding: 0 0 200px;
  margin-bottom: -130px;
  max-width: 1140px;
  background: url(../img/under/bg_articleWrap.png) no-repeat right top;
  background-size: contain;
}
@media screen and (min-width: 767px) and (max-width: 1366px) {
  .articleListWrap__in .ttl--page {
    padding: 0 0 150px;
    margin-bottom: -120px;
  }
}
.articleListWrap__in .pager + h1 {
  margin-top: 100px;
}
.articleListWrap__in .pager + h2 {
  margin-top: 100px;
}
.articleListWrap__in .pager + h3 {
  margin-top: 100px;
}
.articleListWrap__in .pager + h4 {
  margin-top: 100px;
}
.articleListWrap__in .pager + h5 {
  margin-top: 100px;
}
.articleListWrap__in .pager + h6 {
  margin-top: 100px;
}

/* singlePage */
.singlePage {
  padding: 0 1% 102px;
  margin-top: 30px;
  /*---------------------
    メインビジュアル
  ---------------------*/
  /*---------------------
    更新日
  ---------------------*/
  /*---------------------
    目次
  ---------------------*/
  /*---------------------
    関連記事
  ---------------------*/
}
.singlePage__in {
  padding: 70px 70px 40px;
  background: #fff;
  border-radius: 20px;
  max-width: 1140px;
  margin: 0 auto;
}
.singlePage__in h1, .singlePage__in h2, .singlePage__in h3, .singlePage__in h4, .singlePage__in h5, .singlePage__in h6 {
  clear: both;
}
.singlePage__in p:not([class]) {
  font-size: 1.6rem;
  font-weight: 400;
  line-height: 2;
}
.singlePage__in p:not([class]) + p {
  margin-top: 20px;
}
.singlePage__in h1:not([class]), .singlePage__in h1.wp-block-heading {
  font-size: 4.2rem;
  font-weight: 700;
  line-height: 1.33333;
  margin-top: 100px;
  margin-bottom: 40px;
  padding-left: 25px;
  position: relative;
}
.singlePage__in h1:not([class])::before, .singlePage__in h1.wp-block-heading::before {
  content: "";
  width: 12px;
  height: 12px;
  display: inline-block;
  vertical-align: middle;
  border-radius: 50px;
  background: #304A85;
  position: absolute;
  top: 25px;
  left: 0;
}
.singlePage__in h2:not([class]), .singlePage__in h2.wp-block-heading {
  font-size: 2.4rem;
  font-weight: 700;
  color: #fff;
  line-height: 1.66666;
  margin-top: 100px;
  margin-bottom: 40px;
  padding: 28px 30px;
  background: url(../img/common/bg_h2_pc.png) no-repeat right 27px top #304A85;
}
@media screen and (min-width: 767px) and (max-width: 1366px) {
  .singlePage__in h2:not([class]), .singlePage__in h2.wp-block-heading {
    padding: 2.0497803807vw 2.196193265vw;
  }
}
.singlePage__in h3:not([class]), .singlePage__in h3.wp-block-heading {
  font-size: 2rem;
  font-weight: 700;
  line-height: 1.5;
  margin-top: 60px;
  margin-bottom: 30px;
  padding-bottom: 20px;
  border-bottom: 3px solid #E3E3E3;
  position: relative;
}
.singlePage__in h3:not([class])::after, .singlePage__in h3.wp-block-heading::after {
  content: "";
  width: 9em;
  height: 3px;
  display: inline-block;
  background: #304A85;
  position: absolute;
  left: 0;
  bottom: -3px;
}
.singlePage__in h4:not([class]), .singlePage__in h4.wp-block-heading {
  font-size: 1.8rem;
  font-weight: 700;
  line-height: 1.5;
  margin-top: 60px;
  margin-bottom: 20px;
  padding-left: 29px;
  position: relative;
}
.singlePage__in h4:not([class])::before, .singlePage__in h4.wp-block-heading::before {
  content: "";
  width: 8px;
  height: 8px;
  display: inline-block;
  vertical-align: middle;
  border-radius: 50px;
  background: #304A85;
  position: absolute;
  top: 10px;
  left: 8px;
}
.singlePage__in h5:not([class]), .singlePage__in h5.wp-block-heading {
  font-size: 2rem;
  font-weight: 700;
  line-height: 1.44444;
  color: #304A85;
  margin-top: 40px;
  margin-bottom: 20px;
  padding: 10px 15px;
  border-left: 4px solid #304A85;
  background: #F9F9F9;
}
.singlePage__in h6:not([class]), .singlePage__in h6.wp-block-heading {
  font-size: 1.8rem;
  font-weight: 700;
  line-height: 1.77777;
  color: #304A85;
  margin-top: 40px;
  margin-bottom: 20px;
  padding-left: 20px;
  position: relative;
}
.singlePage__in h6:not([class])::before, .singlePage__in h6.wp-block-heading::before {
  content: "";
  width: 10px;
  height: 10px;
  display: inline-block;
  background: #304A85;
  position: absolute;
  top: 12px;
  left: 0;
}
.singlePage__in blockquote {
  position: relative;
  padding: 50px 20px 20px;
  margin: 50px 0 30px;
  box-sizing: border-box;
  font-style: italic;
  color: #464646;
  border: 2px solid #F5F8FF;
}
.singlePage__in blockquote::before {
  content: "“";
  font-size: 5rem;
  font-weight: 700;
  color: #304A85;
  display: inline-block;
  font-style: normal;
  width: 50px;
  height: 50px;
  line-height: 1.3;
  text-align: center;
  background: #F5F8FF;
  border-radius: 50px;
  position: absolute;
  top: -25px;
  left: 15px;
}
.singlePage__in blockquote h1 {
  margin: 0 0 20px !important;
  font-size: 1.6rem;
  line-height: 1.75;
  letter-spacing: 0.06em;
  position: relative;
}
.singlePage__in blockquote h2 {
  margin: 0 0 20px !important;
  font-size: 1.6rem;
  line-height: 1.75;
  letter-spacing: 0.06em;
  position: relative;
}
.singlePage__in blockquote h3 {
  margin: 0 0 20px !important;
  font-size: 1.6rem;
  line-height: 1.75;
  letter-spacing: 0.06em;
  position: relative;
}
.singlePage__in blockquote h4 {
  margin: 0 0 20px !important;
  font-size: 1.6rem;
  line-height: 1.75;
  letter-spacing: 0.06em;
  position: relative;
}
.singlePage__in blockquote h5 {
  margin: 0 0 20px !important;
  font-size: 1.6rem;
  line-height: 1.75;
  letter-spacing: 0.06em;
  position: relative;
}
.singlePage__in blockquote h6 {
  margin: 0 0 20px !important;
  font-size: 1.6rem;
  line-height: 1.75;
  letter-spacing: 0.06em;
  position: relative;
}
.singlePage__in blockquote p {
  font-style: normal;
}
.singlePage__in blockquote cite {
  display: block;
  text-align: right;
  line-height: 1;
  color: #888888;
  font-size: 0.9em;
  margin: 10px 0 0;
}
.singlePage__in .btnWrap {
  clear: both;
}
.singlePage__in img:not([class]), .singlePage__in.wp-block-heading {
  display: block;
  margin: 15px auto 20px;
}
.singlePage__in img.alignleft {
  float: left;
  margin: 0 30px 30px 0;
}
.singlePage__in img.alignright {
  float: right;
  margin: 0 0 30px 30px;
}
.singlePage__in img.aligncenter, .singlePage__in img.alignnone {
  margin: 0 auto;
  display: block;
}
.singlePage__in ul:not([class]) {
  margin: 30px 0;
  padding: 30px;
  background: #FFF;
  border: 2px solid #E3E3E3;
  border-radius: 10px;
  clear: both;
}
@media screen and (min-width: 767px) and (max-width: 1366px) {
  .singlePage__in ul:not([class]) {
    padding: 2.196193265vw;
  }
}
.singlePage__in ul:not([class]) li {
  font-size: 1.6rem;
  font-weight: 700;
  line-height: 2;
  padding-left: 18px;
  position: relative;
}
.singlePage__in ul:not([class]) li::before {
  content: "";
  width: 8px;
  height: 8px;
  border-radius: 50px;
  background: #304A85;
  display: inline-block;
  position: absolute;
  top: 13px;
  left: 0;
}
.singlePage__in ul:not([class]) li + li {
  margin-top: 10px;
}
.singlePage__in ul:not([class]) h1::before {
  content: none;
}
.singlePage__in ul:not([class]) h2::before {
  content: none;
}
.singlePage__in ul:not([class]) h3::before {
  content: none;
}
.singlePage__in ul:not([class]) h4::before {
  content: none;
}
.singlePage__in ul:not([class]) h5::before {
  content: none;
}
.singlePage__in ul:not([class]) h6::before {
  content: none;
}
.singlePage__in ol:not([class]) {
  margin: 30px 0;
  padding: 30px;
  background: #F5F8FF;
  border-radius: 10px;
  counter-reset: listNum;
  clear: both;
}
.singlePage__in ol:not([class]) li {
  font-size: 1.6rem;
  font-weight: 700;
  line-height: 2;
  padding-left: 38px;
  position: relative;
}
.singlePage__in ol:not([class]) li::before {
  counter-increment: listNum;
  content: counter(listNum);
  font-size: 1.6rem;
  font-weight: 700;
  color: #304A85;
  width: 28px;
  text-align: center;
  display: inline-block;
  position: absolute;
  top: 0;
  left: 0;
}
.singlePage__in ol:not([class]) li + li {
  margin-top: 10px;
}
.singlePage__in ol:not([class]) h1::before {
  content: none;
}
.singlePage__in ol:not([class]) h2::before {
  content: none;
}
.singlePage__in ol:not([class]) h3::before {
  content: none;
}
.singlePage__in ol:not([class]) h4::before {
  content: none;
}
.singlePage__in ol:not([class]) h5::before {
  content: none;
}
.singlePage__in ol:not([class]) h6::before {
  content: none;
}
@media screen and (min-width: 0) and (max-width: 767px) {
  .singlePage__in p:not([class]) {
    font-size: 1.6rem;
    line-height: 1.95714;
  }
  .singlePage__in p:not([class]) + p {
    margin-top: 10px;
  }
  .singlePage__in h1:not([class]), .singlePage__in h1.wp-block-heading {
    font-size: 2.4rem;
    margin-top: 54px;
    margin-bottom: 20px;
    padding-left: 15px;
  }
  .singlePage__in h1:not([class])::before, .singlePage__in h1.wp-block-heading::before {
    width: 8px;
    height: 8px;
    top: 13px;
  }
  .singlePage__in h2:not([class]), .singlePage__in h2.wp-block-heading {
    font-size: 1.8rem;
    line-height: 1.38888;
    margin-top: 54px;
    margin-bottom: 20px;
    margin-left: -9.2307692308%;
    padding: 15px 7.6923076923%;
    width: 117.4358974359%;
    background: url(../img/common/bg_h2_sp.png) no-repeat right 17px top #304A85;
    background-size: contain;
  }
  .singlePage__in h3:not([class]), .singlePage__in h3.wp-block-heading {
    font-size: 1.6rem;
    line-height: 1.625;
    margin-top: 40px;
    margin-bottom: 20px;
    padding-bottom: 14px;
  }
  .singlePage__in h3:not([class])::after, .singlePage__in h3.wp-block-heading::after {
    width: 6.5em;
  }
  .singlePage__in h4:not([class]), .singlePage__in h4.wp-block-heading {
    font-size: 1.6rem;
    line-height: 1.625;
    margin-top: 40px;
    padding-left: 20px;
  }
  .singlePage__in h4:not([class])::before, .singlePage__in h4.wp-block-heading::before {
    top: 10px;
    left: 5px;
  }
  .singlePage__in h5:not([class]), .singlePage__in h5.wp-block-heading {
    font-size: 1.5rem;
    line-height: 1.73333;
    padding: 10px 12px;
  }
  .singlePage__in h6:not([class]), .singlePage__in h6.wp-block-heading {
    font-size: 1.5rem;
    line-height: 1.73333;
    padding-left: 18px;
  }
  .singlePage__in h6:not([class])::before, .singlePage__in h6.wp-block-heading::before {
    width: 8px;
    height: 8px;
    top: 9px;
  }
  .singlePage__in blockquote {
    padding: 40px 3.8461538462% 20px;
    margin: 40px 0 30px;
    box-sizing: border-box;
    font-style: italic;
    color: #464646;
    border: 2px solid #F5F8FF;
  }
  .singlePage__in blockquote::before {
    font-size: 4rem;
    width: 45px;
    height: 45px;
    line-height: 1.5;
  }
  .singlePage__in blockquote h1 {
    font-size: 1.5rem;
  }
  .singlePage__in blockquote h2 {
    font-size: 1.5rem;
  }
  .singlePage__in blockquote h3 {
    font-size: 1.5rem;
  }
  .singlePage__in blockquote h4 {
    font-size: 1.5rem;
  }
  .singlePage__in blockquote h5 {
    font-size: 1.5rem;
  }
  .singlePage__in blockquote h6 {
    font-size: 1.5rem;
  }
  .singlePage__in img.alignleft {
    float: none;
    display: block;
    margin: 15px auto 20px;
  }
  .singlePage__in img.alignright {
    float: none;
    display: block;
    margin: 15px auto 20px;
  }
  .singlePage__in ul:not([class]) {
    margin: 20px 0;
    padding: 25px 6.4102564103%;
    border-radius: 5px;
  }
  .singlePage__in ul:not([class]) li {
    font-size: 1.4rem;
    line-height: 1.71428;
    padding-left: 14px;
  }
  .singlePage__in ul:not([class]) li::before {
    width: 6px;
    height: 6px;
    top: 8px;
  }
  .singlePage__in ol:not([class]) {
    margin: 20px 0;
    padding: 25px 3.8461538462%;
    border-radius: 5px;
  }
  .singlePage__in ol:not([class]) li {
    font-size: 1.4rem;
    line-height: 1.71428;
  }
  .singlePage__in ol:not([class]) li::before {
    font-size: 1.4rem;
  }
}
.singlePage__in iframe {
  width: 100%;
}
.singlePage__in > h1:first-child {
  margin-top: 0 !important;
}
.singlePage__in > h2:first-child {
  margin-top: 0 !important;
}
.singlePage__in > h3:first-child {
  margin-top: 0 !important;
}
.singlePage__in > h4:first-child {
  margin-top: 0 !important;
}
.singlePage__in > h5:first-child {
  margin-top: 0 !important;
}
.singlePage__in > h6:first-child {
  margin-top: 0 !important;
}
@media screen and (min-width: 767px) and (max-width: 1366px) {
  .singlePage__in {
    padding: 70px 5.1244509517vw 40px;
  }
}
.singlePage .articleMv {
  margin: 25px auto;
  text-align: center;
  overflow: hidden;
}
.singlePage .articleMv img {
  border-radius: 15px;
}
.singlePage .date {
  margin-bottom: 20px;
}
.singlePage .date > * {
  font-size: 1.4rem !important;
  color: #B8B8B8;
  display: inline-block;
  margin: 0 1em 0 0 !important;
  line-height: 1 !important;
}
.singlePage .tocBox {
  margin: 40px auto 100px;
}
.singlePage .tocBox__inner {
  background: #fff;
  border: 1px solid #DFDFDF;
  padding: 34px 4.0995607613vw;
}
.singlePage .tocBox__ttl {
  font-size: 2.9rem;
  font-weight: 700;
  line-height: 1;
  margin-bottom: 24px;
  margin-left: 32px;
}
.singlePage .tocBox__ttl::before {
  content: "";
  width: 8px;
  height: 8px;
  display: inline-block;
  background: #304A85;
  border-radius: 50px;
  vertical-align: middle;
  margin: -5px 11px 0 0;
}
.singlePage .tocBox .tocList {
  counter-reset: tocNum;
}
.singlePage .tocBox .tocList__item {
  position: relative;
  border-bottom: 1px dashed #D4D4D4;
}
.singlePage .tocBox .tocList__item::before {
  counter-increment: tocNum;
  content: counter(tocNum) ".";
  font-size: 1.6rem;
  font-weight: 400;
  color: #304A85;
  display: inline-block;
  position: absolute;
  top: 1px;
  left: 32px;
}
.singlePage .tocBox .tocList__item a {
  font-size: 1.6rem;
  font-weight: 400;
  display: inline-block;
  padding: 0 0 10px 60px;
}
@media screen and (min-width: 767px) {
  .singlePage .tocBox .tocList__item a:hover {
    text-decoration: underline;
    color: #304A85;
  }
}
.singlePage .tocBox .tocList__item + .tocList__item {
  margin-top: 5px;
}
.singlePage .tocBox .tocList__item .tocList {
  counter-reset: tocChildNum;
  padding-left: 26px;
}
.singlePage .tocBox .tocList__item .tocList__item::before {
  counter-increment: tocChildNum;
  content: counter(tocNum) " - " counter(tocChildNum) ".";
}
.singlePage .tocBox .tocList__item .tocList__item a {
  padding-left: 36px;
}
.singlePage .tocBox .tocList__item .tocList__item + .tocList__item {
  margin-top: 5px;
}
.singlePage .tocBox .tocMore {
  font-size: 1.5rem;
  font-weight: 500;
  background: #FECB3F;
  text-align: center;
  margin-top: 20px;
  padding: 8px 20px;
  display: inline-block;
  text-align: center;
  position: relative;
  left: 50%;
  transform: translateX(-50%);
  cursor: pointer;
}
@media screen and (min-width: 767px) {
  .singlePage .tocBox .tocMore:hover {
    color: #fff;
    background: #304A85;
  }
}
.singlePage .tocBox:not(.open) .tocBox__inner > .tocList > .tocList__item:nth-child(n+4) {
  display: none;
}
.singlePage .tocBox:not(.open) .tocMore::after {
  transform: rotate(0deg);
}
@media screen and (min-width: 767px) {
  .singlePage .tocBox:not(.open) .tocMore:hover::after {
    transform: translateY(2px) rotate(90deg);
  }
}
.singlePage .relrated {
  padding: 0 25px;
  margin: 88px 0 40px;
  position: relative;
}
@media screen and (min-width: 767px) and (max-width: 1366px) {
  .singlePage .relrated {
    padding: 0 1.8301610542vw;
  }
}
.singlePage .relrated__ttl {
  font-size: 1.6rem;
  font-weight: 500;
  line-height: 1.5;
  display: inline-block;
  background: #FECB3F;
  border-radius: 10px 10px 0 0;
  padding: 13px 20px 10px 60px;
  display: flex;
  align-items: center;
  position: absolute;
  top: -28px;
  left: 25px;
}
.singlePage .relrated__ttl::before {
  content: "";
  width: 25px;
  height: 25px;
  display: inline-block;
  background: url(../img/common/icon/icon_apartment.svg);
  background-size: contain;
  position: absolute;
  top: 13px;
  left: 20px;
}
.singlePage .relrated .relratedList {
  background: #F5F8FF;
  border-radius: 10px;
  padding: 30px;
}
.singlePage .relrated .relratedList__item .itemWrap {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
}
.singlePage .relrated .relratedList__item .itemWrap .thumb img {
  width: 425px;
  height: 145px;
  object-fit: cover;
  margin: 0;
  border-radius: 10px;
  overflow: hidden;
}
@media screen and (min-width: 767px) and (max-width: 1366px) {
  .singlePage .relrated .relratedList__item .itemWrap .thumb img {
    width: 31.1127379209vw;
    height: 10.6149341142vw;
  }
}
.singlePage .relrated .relratedList__item .itemWrap .thumb + .textWrap {
  width: calc(100% - 455px);
}
@media screen and (min-width: 767px) and (max-width: 1366px) {
  .singlePage .relrated .relratedList__item .itemWrap .thumb + .textWrap {
    width: calc(100% - 33.3089311859vw);
  }
}
.singlePage .relrated .relratedList__item .itemWrap .textWrap__ttl {
  font-size: 1.8rem;
  font-weight: 500;
  line-height: 1.7777;
  color: #304A85;
  text-decoration: underline;
}
.singlePage .relrated .relratedList__item .itemWrap .textWrap__text {
  font-size: 1.5rem;
  line-height: 1.5;
  margin: 10px 0 0;
}
.singlePage .relrated .relratedList__item + .relratedList__item {
  margin-top: 40px;
}
@media screen and (min-width: 0) and (max-width: 767px) {
  .singlePage {
    /*---------------------
      記事タイトル
    ---------------------*/
    /*---------------------
      メインビジュアル
    ---------------------*/
    /*---------------------
      更新日
    ---------------------*/
    /*---------------------
      目次
    ---------------------*/
    /*---------------------
      関連記事
    ---------------------*/
  }
  .singlePage .articleTtl {
    font-size: 2.2rem;
    line-height: 1.5;
    padding: 0 3.8461538462%;
  }
  .singlePage .articleMv {
    margin: 5px auto;
    padding: 0 3.8461538462%;
  }
  .singlePage .date {
    margin-bottom: 15px;
  }
  .singlePage .date > * {
    font-size: 1.2rem !important;
  }
  .singlePage .tocBox {
    margin: 30px auto;
    padding: 0 3.8461538462%;
  }
  .singlePage .tocBox__inner {
    padding: 20px 3.8461538462%;
  }
  .singlePage .tocBox__ttl {
    font-size: 1.5rem;
    line-height: 1;
    margin-bottom: 20px;
  }
  .singlePage .tocBox .tocList__item::before {
    top: 0;
  }
  .singlePage .tocBox .tocMore {
    font-size: 1.4rem;
  }
  .singlePage .relrated {
    padding: 0;
    margin: 58px 0 30px;
  }
  .singlePage .relrated__ttl {
    font-size: 1.2rem;
    line-height: 1.416666;
    border-radius: 5px 5px 0 0;
    padding: 11px 16px 11px 40px;
    left: 0;
  }
  .singlePage .relrated__ttl::before {
    width: 16px;
    height: 16px;
    top: 11px;
    left: 16px;
  }
  .singlePage .relrated .relratedList {
    border-radius: 5px;
    padding: 22px 20px 20px;
  }
  .singlePage .relrated .relratedList__item .itemWrap {
    display: block;
  }
  .singlePage .relrated .relratedList__item .itemWrap .thumb {
    margin: 0 0 10px;
  }
  .singlePage .relrated .relratedList__item .itemWrap .thumb img {
    width: 100%;
    height: auto;
    max-height: 100px;
    margin: 0 auto;
    display: block;
    border-radius: 5px;
  }
  .singlePage .relrated .relratedList__item .itemWrap .thumb + .textWrap {
    width: 100%;
  }
  .singlePage .relrated .relratedList__item .itemWrap .textWrap__ttl {
    font-size: 1.6rem;
    line-height: 1.625;
    text-decoration: none;
  }
  .singlePage .relrated .relratedList__item + .relratedList__item {
    margin-top: 30px;
  }
}

/* 記事詳細ページ */
.articleDetail {
  /*---------------------
    メインビジュアル
  ---------------------*/
  /*---------------------
    更新日
  ---------------------*/
  /*---------------------
    目次
  ---------------------*/
  /*---------------------
    関連記事
  ---------------------*/
  padding: 30px 0 102px calc((100% - 1000px) / 4);
  background: url(../img/under/bg_kv_pc.jpg) no-repeat center top;
  background-size: contain;
}
.articleDetail .articleMv {
  margin: 25px auto;
  text-align: center;
  overflow: hidden;
}
.articleDetail .articleMv img {
  border-radius: 15px;
}
.articleDetail .date {
  margin-bottom: 20px;
}
.articleDetail .date > * {
  font-size: 1.4rem !important;
  color: #B8B8B8;
  display: inline-block;
  margin: 0 1em 0 0 !important;
  line-height: 1 !important;
}
.articleDetail .tocBox {
  margin: 40px auto 100px;
}
.articleDetail .tocBox__inner {
  background: #fff;
  border: 1px solid #DFDFDF;
  padding: 34px 4.0995607613vw;
}
.articleDetail .tocBox__ttl {
  font-size: 2.9rem;
  font-weight: 700;
  line-height: 1;
  margin-bottom: 24px;
  margin-left: 32px;
}
.articleDetail .tocBox__ttl::before {
  content: "";
  width: 8px;
  height: 8px;
  display: inline-block;
  background: #304A85;
  border-radius: 50px;
  vertical-align: middle;
  margin: -5px 11px 0 0;
}
.articleDetail .tocBox .tocList {
  counter-reset: tocNum;
}
.articleDetail .tocBox .tocList__item {
  position: relative;
  border-bottom: 1px dashed #D4D4D4;
}
.articleDetail .tocBox .tocList__item::before {
  counter-increment: tocNum;
  content: counter(tocNum) ".";
  font-size: 1.6rem;
  font-weight: 400;
  color: #304A85;
  display: inline-block;
  position: absolute;
  top: 1px;
  left: 32px;
}
.articleDetail .tocBox .tocList__item a {
  font-size: 1.6rem;
  font-weight: 400;
  display: inline-block;
  padding: 0 0 10px 60px;
}
@media screen and (min-width: 767px) {
  .articleDetail .tocBox .tocList__item a:hover {
    text-decoration: underline;
    color: #304A85;
  }
}
.articleDetail .tocBox .tocList__item + .tocList__item {
  margin-top: 5px;
}
.articleDetail .tocBox .tocList__item .tocList {
  counter-reset: tocChildNum;
  padding-left: 26px;
}
.articleDetail .tocBox .tocList__item .tocList__item::before {
  counter-increment: tocChildNum;
  content: counter(tocNum) " - " counter(tocChildNum) ".";
}
.articleDetail .tocBox .tocList__item .tocList__item a {
  padding-left: 36px;
}
.articleDetail .tocBox .tocList__item .tocList__item + .tocList__item {
  margin-top: 5px;
}
.articleDetail .tocBox .tocMore {
  font-size: 1.5rem;
  font-weight: 500;
  background: #FECB3F;
  text-align: center;
  margin-top: 20px;
  padding: 8px 20px;
  display: inline-block;
  text-align: center;
  position: relative;
  left: 50%;
  transform: translateX(-50%);
  cursor: pointer;
}
@media screen and (min-width: 767px) {
  .articleDetail .tocBox .tocMore:hover {
    color: #fff;
    background: #304A85;
  }
}
.articleDetail .tocBox:not(.open) .tocBox__inner > .tocList > .tocList__item:nth-child(n+4) {
  display: none;
}
.articleDetail .tocBox:not(.open) .tocMore::after {
  transform: rotate(0deg);
}
@media screen and (min-width: 767px) {
  .articleDetail .tocBox:not(.open) .tocMore:hover::after {
    transform: translateY(2px) rotate(90deg);
  }
}
.articleDetail .relrated {
  padding: 0 25px;
  margin: 88px 0 40px;
  position: relative;
}
@media screen and (min-width: 767px) and (max-width: 1366px) {
  .articleDetail .relrated {
    padding: 0 1.8301610542vw;
  }
}
.articleDetail .relrated__ttl {
  font-size: 1.6rem;
  font-weight: 500;
  line-height: 1.5;
  display: inline-block;
  background: #FECB3F;
  border-radius: 10px 10px 0 0;
  padding: 13px 20px 10px 60px;
  display: flex;
  align-items: center;
  position: absolute;
  top: -28px;
  left: 25px;
}
.articleDetail .relrated__ttl::before {
  content: "";
  width: 25px;
  height: 25px;
  display: inline-block;
  background: url(../img/common/icon/icon_apartment.svg);
  background-size: contain;
  position: absolute;
  top: 13px;
  left: 20px;
}
.articleDetail .relrated .relratedList {
  background: #F5F8FF;
  border-radius: 10px;
  padding: 30px;
}
.articleDetail .relrated .relratedList__item .itemWrap {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
}
.articleDetail .relrated .relratedList__item .itemWrap .thumb img {
  width: 425px;
  height: 145px;
  object-fit: cover;
  margin: 0;
  border-radius: 10px;
  overflow: hidden;
}
@media screen and (min-width: 767px) and (max-width: 1366px) {
  .articleDetail .relrated .relratedList__item .itemWrap .thumb img {
    width: 31.1127379209vw;
    height: 10.6149341142vw;
  }
}
.articleDetail .relrated .relratedList__item .itemWrap .thumb + .textWrap {
  width: calc(100% - 455px);
}
@media screen and (min-width: 767px) and (max-width: 1366px) {
  .articleDetail .relrated .relratedList__item .itemWrap .thumb + .textWrap {
    width: calc(100% - 33.3089311859vw);
  }
}
.articleDetail .relrated .relratedList__item .itemWrap .textWrap__ttl {
  font-size: 1.8rem;
  font-weight: 500;
  line-height: 1.7777;
  color: #304A85;
  text-decoration: underline;
}
.articleDetail .relrated .relratedList__item .itemWrap .textWrap__text {
  font-size: 1.5rem;
  line-height: 1.5;
  margin: 10px 0 0;
}
.articleDetail .relrated .relratedList__item + .relratedList__item {
  margin-top: 40px;
}
@media screen and (min-width: 0) and (max-width: 767px) {
  .articleDetail {
    /*---------------------
      記事タイトル
    ---------------------*/
    /*---------------------
      メインビジュアル
    ---------------------*/
    /*---------------------
      更新日
    ---------------------*/
    /*---------------------
      目次
    ---------------------*/
    /*---------------------
      関連記事
    ---------------------*/
  }
  .articleDetail .articleTtl {
    font-size: 2.2rem;
    line-height: 1.5;
    padding: 0 3.8461538462%;
  }
  .articleDetail .articleMv {
    margin: 5px auto;
    padding: 0 3.8461538462%;
  }
  .articleDetail .date {
    margin-bottom: 15px;
  }
  .articleDetail .date > * {
    font-size: 1.2rem !important;
  }
  .articleDetail .tocBox {
    margin: 30px auto;
    padding: 0 3.8461538462%;
  }
  .articleDetail .tocBox__inner {
    padding: 20px 3.8461538462%;
  }
  .articleDetail .tocBox__ttl {
    font-size: 1.5rem;
    line-height: 1;
    margin-bottom: 20px;
  }
  .articleDetail .tocBox .tocList__item::before {
    top: 0;
  }
  .articleDetail .tocBox .tocMore {
    font-size: 1.4rem;
  }
  .articleDetail .relrated {
    padding: 0;
    margin: 58px 0 30px;
  }
  .articleDetail .relrated__ttl {
    font-size: 1.2rem;
    line-height: 1.416666;
    border-radius: 5px 5px 0 0;
    padding: 11px 16px 11px 40px;
    left: 0;
  }
  .articleDetail .relrated__ttl::before {
    width: 16px;
    height: 16px;
    top: 11px;
    left: 16px;
  }
  .articleDetail .relrated .relratedList {
    border-radius: 5px;
    padding: 22px 20px 20px;
  }
  .articleDetail .relrated .relratedList__item .itemWrap {
    display: block;
  }
  .articleDetail .relrated .relratedList__item .itemWrap .thumb {
    margin: 0 0 10px;
  }
  .articleDetail .relrated .relratedList__item .itemWrap .thumb img {
    width: 100%;
    height: auto;
    max-height: 100px;
    margin: 0 auto;
    display: block;
    border-radius: 5px;
  }
  .articleDetail .relrated .relratedList__item .itemWrap .thumb + .textWrap {
    width: 100%;
  }
  .articleDetail .relrated .relratedList__item .itemWrap .textWrap__ttl {
    font-size: 1.6rem;
    line-height: 1.625;
    text-decoration: none;
  }
  .articleDetail .relrated .relratedList__item + .relratedList__item {
    margin-top: 30px;
  }
}
@media screen and (min-width: 767px) and (max-width: 1366px) {
  .articleDetail {
    padding: 30px 0 102px 3%;
  }
}
.articleDetail__in {
  padding: 40px 70px 40px;
  background: #fff;
  border-radius: 20px 0 0 0;
  position: relative;
}
@media screen and (min-width: 767px) and (max-width: 1366px) {
  .articleDetail__in {
    padding: 70px 5.1244509517vw 40px;
  }
}
.articleDetail__in .tocBox + * {
  margin-top: 0 !important;
}
.articleDetail .articleCont {
  max-width: 1000px;
}
.articleDetail .articleCont h1, .articleDetail .articleCont h2, .articleDetail .articleCont h3, .articleDetail .articleCont h4, .articleDetail .articleCont h5, .articleDetail .articleCont h6 {
  clear: both;
}
.articleDetail .articleCont p:not([class]) {
  font-size: 1.6rem;
  font-weight: 400;
  line-height: 2;
}
.articleDetail .articleCont p:not([class]) + p {
  margin-top: 20px;
}
.articleDetail .articleCont h1:not([class]), .articleDetail .articleCont h1.wp-block-heading {
  font-size: 4.2rem;
  font-weight: 700;
  line-height: 1.33333;
  margin-top: 100px;
  margin-bottom: 40px;
  padding-left: 25px;
  position: relative;
}
.articleDetail .articleCont h1:not([class])::before, .articleDetail .articleCont h1.wp-block-heading::before {
  content: "";
  width: 12px;
  height: 12px;
  display: inline-block;
  vertical-align: middle;
  border-radius: 50px;
  background: #304A85;
  position: absolute;
  top: 25px;
  left: 0;
}
.articleDetail .articleCont h2:not([class]), .articleDetail .articleCont h2.wp-block-heading {
  font-size: 2.4rem;
  font-weight: 700;
  color: #fff;
  line-height: 1.66666;
  margin-top: 100px;
  margin-bottom: 40px;
  padding: 28px 30px;
  background: url(../img/common/bg_h2_pc.png) no-repeat right 27px top #304A85;
}
@media screen and (min-width: 767px) and (max-width: 1366px) {
  .articleDetail .articleCont h2:not([class]), .articleDetail .articleCont h2.wp-block-heading {
    padding: 2.0497803807vw 2.196193265vw;
  }
}
.articleDetail .articleCont h3:not([class]), .articleDetail .articleCont h3.wp-block-heading {
  font-size: 2rem;
  font-weight: 700;
  line-height: 1.5;
  margin-top: 60px;
  margin-bottom: 30px;
  padding-bottom: 20px;
  border-bottom: 3px solid #E3E3E3;
  position: relative;
}
.articleDetail .articleCont h3:not([class])::after, .articleDetail .articleCont h3.wp-block-heading::after {
  content: "";
  width: 9em;
  height: 3px;
  display: inline-block;
  background: #304A85;
  position: absolute;
  left: 0;
  bottom: -3px;
}
.articleDetail .articleCont h4:not([class]), .articleDetail .articleCont h4.wp-block-heading {
  font-size: 1.8rem;
  font-weight: 700;
  line-height: 1.5;
  margin-top: 60px;
  margin-bottom: 20px;
  padding-left: 29px;
  position: relative;
}
.articleDetail .articleCont h4:not([class])::before, .articleDetail .articleCont h4.wp-block-heading::before {
  content: "";
  width: 8px;
  height: 8px;
  display: inline-block;
  vertical-align: middle;
  border-radius: 50px;
  background: #304A85;
  position: absolute;
  top: 10px;
  left: 8px;
}
.articleDetail .articleCont h5:not([class]), .articleDetail .articleCont h5.wp-block-heading {
  font-size: 2rem;
  font-weight: 700;
  line-height: 1.44444;
  color: #304A85;
  margin-top: 40px;
  margin-bottom: 20px;
  padding: 10px 15px;
  border-left: 4px solid #304A85;
  background: #F9F9F9;
}
.articleDetail .articleCont h6:not([class]), .articleDetail .articleCont h6.wp-block-heading {
  font-size: 1.8rem;
  font-weight: 700;
  line-height: 1.77777;
  color: #304A85;
  margin-top: 40px;
  margin-bottom: 20px;
  padding-left: 20px;
  position: relative;
}
.articleDetail .articleCont h6:not([class])::before, .articleDetail .articleCont h6.wp-block-heading::before {
  content: "";
  width: 10px;
  height: 10px;
  display: inline-block;
  background: #304A85;
  position: absolute;
  top: 12px;
  left: 0;
}
.articleDetail .articleCont blockquote {
  position: relative;
  padding: 50px 20px 20px;
  margin: 50px 0 30px;
  box-sizing: border-box;
  font-style: italic;
  color: #464646;
  border: 2px solid #F5F8FF;
}
.articleDetail .articleCont blockquote::before {
  content: "“";
  font-size: 5rem;
  font-weight: 700;
  color: #304A85;
  display: inline-block;
  font-style: normal;
  width: 50px;
  height: 50px;
  line-height: 1.3;
  text-align: center;
  background: #F5F8FF;
  border-radius: 50px;
  position: absolute;
  top: -25px;
  left: 15px;
}
.articleDetail .articleCont blockquote h1 {
  margin: 0 0 20px !important;
  font-size: 1.6rem;
  line-height: 1.75;
  letter-spacing: 0.06em;
  position: relative;
}
.articleDetail .articleCont blockquote h2 {
  margin: 0 0 20px !important;
  font-size: 1.6rem;
  line-height: 1.75;
  letter-spacing: 0.06em;
  position: relative;
}
.articleDetail .articleCont blockquote h3 {
  margin: 0 0 20px !important;
  font-size: 1.6rem;
  line-height: 1.75;
  letter-spacing: 0.06em;
  position: relative;
}
.articleDetail .articleCont blockquote h4 {
  margin: 0 0 20px !important;
  font-size: 1.6rem;
  line-height: 1.75;
  letter-spacing: 0.06em;
  position: relative;
}
.articleDetail .articleCont blockquote h5 {
  margin: 0 0 20px !important;
  font-size: 1.6rem;
  line-height: 1.75;
  letter-spacing: 0.06em;
  position: relative;
}
.articleDetail .articleCont blockquote h6 {
  margin: 0 0 20px !important;
  font-size: 1.6rem;
  line-height: 1.75;
  letter-spacing: 0.06em;
  position: relative;
}
.articleDetail .articleCont blockquote p {
  font-style: normal;
}
.articleDetail .articleCont blockquote cite {
  display: block;
  text-align: right;
  line-height: 1;
  color: #888888;
  font-size: 0.9em;
  margin: 10px 0 0;
}
.articleDetail .articleCont .btnWrap {
  clear: both;
}
.articleDetail .articleCont img:not([class]), .articleDetail .articleCont.wp-block-heading {
  display: block;
  margin: 15px auto 20px;
}
.articleDetail .articleCont img.alignleft {
  float: left;
  margin: 0 30px 30px 0;
}
.articleDetail .articleCont img.alignright {
  float: right;
  margin: 0 0 30px 30px;
}
.articleDetail .articleCont img.aligncenter, .articleDetail .articleCont img.alignnone {
  margin: 0 auto;
  display: block;
}
.articleDetail .articleCont ul:not([class]) {
  margin: 30px 0;
  padding: 30px;
  background: #FFF;
  border: 2px solid #E3E3E3;
  border-radius: 10px;
  clear: both;
}
@media screen and (min-width: 767px) and (max-width: 1366px) {
  .articleDetail .articleCont ul:not([class]) {
    padding: 2.196193265vw;
  }
}
.articleDetail .articleCont ul:not([class]) li {
  font-size: 1.6rem;
  font-weight: 700;
  line-height: 2;
  padding-left: 18px;
  position: relative;
}
.articleDetail .articleCont ul:not([class]) li::before {
  content: "";
  width: 8px;
  height: 8px;
  border-radius: 50px;
  background: #304A85;
  display: inline-block;
  position: absolute;
  top: 13px;
  left: 0;
}
.articleDetail .articleCont ul:not([class]) li + li {
  margin-top: 10px;
}
.articleDetail .articleCont ul:not([class]) h1::before {
  content: none;
}
.articleDetail .articleCont ul:not([class]) h2::before {
  content: none;
}
.articleDetail .articleCont ul:not([class]) h3::before {
  content: none;
}
.articleDetail .articleCont ul:not([class]) h4::before {
  content: none;
}
.articleDetail .articleCont ul:not([class]) h5::before {
  content: none;
}
.articleDetail .articleCont ul:not([class]) h6::before {
  content: none;
}
.articleDetail .articleCont ol:not([class]) {
  margin: 30px 0;
  padding: 30px;
  background: #F5F8FF;
  border-radius: 10px;
  counter-reset: listNum;
  clear: both;
}
.articleDetail .articleCont ol:not([class]) li {
  font-size: 1.6rem;
  font-weight: 700;
  line-height: 2;
  padding-left: 38px;
  position: relative;
}
.articleDetail .articleCont ol:not([class]) li::before {
  counter-increment: listNum;
  content: counter(listNum);
  font-size: 1.6rem;
  font-weight: 700;
  color: #304A85;
  width: 28px;
  text-align: center;
  display: inline-block;
  position: absolute;
  top: 0;
  left: 0;
}
.articleDetail .articleCont ol:not([class]) li + li {
  margin-top: 10px;
}
.articleDetail .articleCont ol:not([class]) h1::before {
  content: none;
}
.articleDetail .articleCont ol:not([class]) h2::before {
  content: none;
}
.articleDetail .articleCont ol:not([class]) h3::before {
  content: none;
}
.articleDetail .articleCont ol:not([class]) h4::before {
  content: none;
}
.articleDetail .articleCont ol:not([class]) h5::before {
  content: none;
}
.articleDetail .articleCont ol:not([class]) h6::before {
  content: none;
}
@media screen and (min-width: 0) and (max-width: 767px) {
  .articleDetail .articleCont p:not([class]) {
    font-size: 1.6rem;
    line-height: 1.95714;
  }
  .articleDetail .articleCont p:not([class]) + p {
    margin-top: 10px;
  }
  .articleDetail .articleCont h1:not([class]), .articleDetail .articleCont h1.wp-block-heading {
    font-size: 2.4rem;
    margin-top: 54px;
    margin-bottom: 20px;
    padding-left: 15px;
  }
  .articleDetail .articleCont h1:not([class])::before, .articleDetail .articleCont h1.wp-block-heading::before {
    width: 8px;
    height: 8px;
    top: 13px;
  }
  .articleDetail .articleCont h2:not([class]), .articleDetail .articleCont h2.wp-block-heading {
    font-size: 1.8rem;
    line-height: 1.38888;
    margin-top: 54px;
    margin-bottom: 20px;
    margin-left: -9.2307692308%;
    padding: 15px 7.6923076923%;
    width: 117.4358974359%;
    background: url(../img/common/bg_h2_sp.png) no-repeat right 17px top #304A85;
    background-size: contain;
  }
  .articleDetail .articleCont h3:not([class]), .articleDetail .articleCont h3.wp-block-heading {
    font-size: 1.6rem;
    line-height: 1.625;
    margin-top: 40px;
    margin-bottom: 20px;
    padding-bottom: 14px;
  }
  .articleDetail .articleCont h3:not([class])::after, .articleDetail .articleCont h3.wp-block-heading::after {
    width: 6.5em;
  }
  .articleDetail .articleCont h4:not([class]), .articleDetail .articleCont h4.wp-block-heading {
    font-size: 1.6rem;
    line-height: 1.625;
    margin-top: 40px;
    padding-left: 20px;
  }
  .articleDetail .articleCont h4:not([class])::before, .articleDetail .articleCont h4.wp-block-heading::before {
    top: 10px;
    left: 5px;
  }
  .articleDetail .articleCont h5:not([class]), .articleDetail .articleCont h5.wp-block-heading {
    font-size: 1.5rem;
    line-height: 1.73333;
    padding: 10px 12px;
  }
  .articleDetail .articleCont h6:not([class]), .articleDetail .articleCont h6.wp-block-heading {
    font-size: 1.5rem;
    line-height: 1.73333;
    padding-left: 18px;
  }
  .articleDetail .articleCont h6:not([class])::before, .articleDetail .articleCont h6.wp-block-heading::before {
    width: 8px;
    height: 8px;
    top: 9px;
  }
  .articleDetail .articleCont blockquote {
    padding: 40px 3.8461538462% 20px;
    margin: 40px 0 30px;
    box-sizing: border-box;
    font-style: italic;
    color: #464646;
    border: 2px solid #F5F8FF;
  }
  .articleDetail .articleCont blockquote::before {
    font-size: 4rem;
    width: 45px;
    height: 45px;
    line-height: 1.5;
  }
  .articleDetail .articleCont blockquote h1 {
    font-size: 1.5rem;
  }
  .articleDetail .articleCont blockquote h2 {
    font-size: 1.5rem;
  }
  .articleDetail .articleCont blockquote h3 {
    font-size: 1.5rem;
  }
  .articleDetail .articleCont blockquote h4 {
    font-size: 1.5rem;
  }
  .articleDetail .articleCont blockquote h5 {
    font-size: 1.5rem;
  }
  .articleDetail .articleCont blockquote h6 {
    font-size: 1.5rem;
  }
  .articleDetail .articleCont img.alignleft {
    float: none;
    display: block;
    margin: 15px auto 20px;
  }
  .articleDetail .articleCont img.alignright {
    float: none;
    display: block;
    margin: 15px auto 20px;
  }
  .articleDetail .articleCont ul:not([class]) {
    margin: 20px 0;
    padding: 25px 6.4102564103%;
    border-radius: 5px;
  }
  .articleDetail .articleCont ul:not([class]) li {
    font-size: 1.4rem;
    line-height: 1.71428;
    padding-left: 14px;
  }
  .articleDetail .articleCont ul:not([class]) li::before {
    width: 6px;
    height: 6px;
    top: 8px;
  }
  .articleDetail .articleCont ol:not([class]) {
    margin: 20px 0;
    padding: 25px 3.8461538462%;
    border-radius: 5px;
  }
  .articleDetail .articleCont ol:not([class]) li {
    font-size: 1.4rem;
    line-height: 1.71428;
  }
  .articleDetail .articleCont ol:not([class]) li::before {
    font-size: 1.4rem;
  }
}
.articleDetail .articleCont iframe {
  width: 100%;
}

/* 関連記事 */
.relatedBox {
  padding: 100px 1% 50px;
  background: url(../img/under/bg_related.png) no-repeat right 40px top 50px;
}
.relatedBox.blue {
  background: url(../img/under/bg_articleWrap.png) no-repeat right 40px top 50px #F5F8FF;
}
.relatedBox__inner {
  max-width: 1140px;
  margin: 0 auto;
}
.relatedBox__ttl {
  font-size: 4.5rem;
  font-weight: 700;
  line-height: 1.5;
}
.relatedBox__ttl::before {
  content: "";
  width: 12px;
  height: 12px;
  display: inline-block;
  vertical-align: middle;
  border-radius: 50px;
  background: #304A85;
  margin: -5px 15px 0 0;
}
.relatedBox__text {
  font-size: 1.6rem;
  font-weight: 400;
  margin-top: 7px;
}
.relatedBox .relatedList {
  margin-top: 30px;
}

.relatedList {
  display: flex;
  flex-wrap: wrap;
}
.relatedList__item {
  width: calc((100% - 100px) / 3);
  margin-bottom: 50px;
}
.relatedList__item:not(:nth-child(3n+1)) {
  margin-left: 50px;
}
@media screen and (min-width: 767px) {
  .relatedList__item:hover .textWrap__ttl {
    text-decoration: underline !important;
  }
}
@media screen and (min-width: 767px) and (max-width: 1366px) {
  .relatedList__item {
    width: calc((100% - 7.3206442167vw) / 3);
    margin-bottom: 3.6603221083vw;
  }
  .relatedList__item:not(:nth-child(3n+1)) {
    margin-left: 3.6603221083vw;
  }
}
.relatedList__item .itemWrap {
  display: flex;
  justify-content: flex-end;
  flex-direction: row-reverse;
  flex-wrap: wrap;
}
.relatedList__item .itemWrap .thumb {
  width: 100%;
  margin-bottom: 10px;
  border-radius: 10px;
  overflow: hidden;
  position: relative;
}
.relatedList__item .itemWrap .thumb img {
  margin: 0 auto;
  width: 100%;
  height: 215px;
  object-fit: cover;
}
.relatedList__item .itemWrap .thumb .label {
  font-size: 1.2rem;
  font-weight: 400;
  color: #fff;
  display: inline-block;
  background: rgba(34, 34, 34, 0.6);
  padding: 7px 16px;
  position: absolute;
  bottom: 0;
  left: 0;
}
.relatedList__item .itemWrap .textWrap {
  width: 100%;
}
.relatedList__item .itemWrap .textWrap__date {
  font-size: 1.2rem;
  font-weight: 400;
  line-height: 1;
  margin-bottom: 14px;
}
.relatedList__item .itemWrap .textWrap__ttl {
  font-size: 1.6rem;
  font-weight: 500;
  line-height: 1.625;
  display: block;
  text-decoration: none;
}
.relatedList__item .itemWrap .textWrap__text {
  font-size: 1.4rem;
  font-weight: 400;
  line-height: 2;
  margin-top: 10px;
}

@media screen and (max-width: 767px) {
  /* singlePage */
  .singlePage {
    padding: 0 5.1282051282%;
    margin-top: 20px;
  }
  .singlePage__in {
    padding: 20px 3.8461538462% 42px;
    overflow: hidden;
  }
  .singlePage__in > h1:first-child {
    margin-top: -20px !important;
  }
  .singlePage__in > h2:first-child {
    margin-top: -20px !important;
  }
  .singlePage__in > h3:first-child {
    margin-top: -20px !important;
  }
  .singlePage__in > h4:first-child {
    margin-top: -20px !important;
  }
  .singlePage__in > h5:first-child {
    margin-top: -20px !important;
  }
  .singlePage__in > h6:first-child {
    margin-top: -20px !important;
  }
  /* 記事詳細ページ */
  .articleDetail {
    padding: 20px 0 47px 7.6923076923%;
    background: url(../img/under/bg_kv_sp.jpg) no-repeat center top;
    background-size: contain;
  }
  .articleDetail__in {
    padding: 20px 7.6923076923% 20px 0;
  }
  /* 関連記事 */
  .relatedBox {
    padding: 40px 10.2564102564%;
    background: url(../img/under/bg_related.png) no-repeat right -65px top 72px;
    background-size: 210px auto;
  }
  .relatedBox.blue {
    background: url(../img/under/bg_articleWrap.png) no-repeat right -80px top 40px #F5F8FF;
    background-size: 260px auto;
  }
  .relatedBox__ttl {
    font-size: 2.4rem;
    line-height: 1.083333;
  }
  .relatedBox__ttl::before {
    width: 6px;
    height: 6px;
    margin: -5px 8px 0 0;
  }
  .relatedBox__text {
    font-size: 1.4rem;
    margin-top: 10px;
  }
  .relatedList {
    display: block;
  }
  .relatedList__item {
    width: 100%;
    margin-bottom: 30px;
  }
  .relatedList__item:not(:nth-child(3n+1)) {
    margin-left: 0;
  }
  .relatedList__item .itemWrap {
    display: block;
  }
  .relatedList__item .itemWrap .thumb {
    margin-bottom: 12px;
    border-radius: 5px;
  }
  .relatedList__item .itemWrap .thumb .img {
    height: 180px;
  }
  .relatedList__item .itemWrap .thumb .label {
    font-size: 1rem;
  }
  .relatedList__item .itemWrap .textWrap__date {
    margin-bottom: 12px;
  }
  .relatedList__item .itemWrap .textWrap__ttl {
    font-size: 1.4rem;
    line-height: 1.71428;
  }
  .relatedList__item .itemWrap .textWrap__text {
    line-height: 1.66666;
    display: none;
  }
  /* 記事一覧ページ */
  .articleList__item {
    border-radius: 10px;
  }
  .articleList__item .itemWrap {
    padding: 20px 4.358974359% 20px 6.9230769231%;
    display: block;
  }
  .articleList__item .itemWrap .thumb {
    width: 100%;
    max-width: initial;
    margin: 0 0 15px;
    text-align: center;
  }
  .articleList__item .itemWrap .thumb img {
    height: 180px;
  }
  .articleList__item .itemWrap .thumb + .textWrap {
    width: 100%;
  }
  .articleList__item .itemWrap .textWrap__ttl {
    font-size: 1.6rem;
  }
  .articleList__item .itemWrap .textWrap__ttl::before {
    width: 6px;
    height: 6px;
    top: 12px;
    left: -4.358974359%;
  }
  .articleList__item .itemWrap .textWrap__text {
    font-size: 1.4rem;
    line-height: 1.66666;
    margin-top: 10px;
    display: none;
  }
  .articleList__item + .articleList__item {
    margin-top: 20px;
  }
  .articleListWrap {
    padding: 25px 0 25px 6.4102564103%;
    background: url(../img/under/bg_kv_sp.jpg) no-repeat center top;
    background-size: contain;
  }
  .articleListWrap__in {
    padding: 20px 7.6923076923% 20px 0;
  }
  .articleListWrap__in .ttl--page {
    padding: 0 0 60px 6.9230769231%;
    margin-bottom: -30px;
    background-size: 50% auto;
    background-position: right bottom;
  }
  .articleListWrap__in .ttl--page .ttl {
    padding-left: 0;
  }
  .articleListWrap__in .ttl--page .ttl::before {
    left: -4.358974359%;
  }
  .articleListWrap__in .pager + h1 {
    margin-top: 50px;
  }
  .articleListWrap__in .pager + h2 {
    margin-top: 50px;
  }
  .articleListWrap__in .pager + h3 {
    margin-top: 50px;
  }
  .articleListWrap__in .pager + h4 {
    margin-top: 50px;
  }
  .articleListWrap__in .pager + h5 {
    margin-top: 50px;
  }
  .articleListWrap__in .pager + h6 {
    margin-top: 50px;
  }
}
/*=================================
  お問い合わせ
=================================*/
.formBox h1 {
  margin-top: 40px !important;
  margin-bottom: 40px !important;
}
.formBox h2 {
  margin-top: 40px !important;
  margin-bottom: 40px !important;
}
.formBox h3 {
  margin-top: 40px !important;
  margin-bottom: 40px !important;
}
.formBox h4 {
  margin-top: 40px !important;
  margin-bottom: 40px !important;
}
.formBox h5 {
  margin-top: 40px !important;
  margin-bottom: 40px !important;
}
.formBox h6 {
  margin-top: 40px !important;
  margin-bottom: 40px !important;
}
.formBox .infoBox {
  display: flex;
  align-items: flex-start;
  margin-top: 50px;
}
.formBox .infoBox .thumb {
  width: 42%;
  max-width: 359px;
  margin-right: 46px;
}
.formBox .infoBox .thumb img {
  margin: 0;
}
.formBox .infoBox .contact {
  width: calc(100% - (42% + 46px));
}
.formBox .infoBox .contact .infoBoxTtl {
  font-size: 2rem;
  line-height: 1.8;
  font-weight: 400;
}
.formBox .infoBox .contact .address .info--tel {
  margin-top: 20px;
}
.formBox .infoBox .contact .address .info--tel .tel .num {
  font-size: 4.9rem;
  font-weight: bold;
  line-height: 1;
  text-decoration: none;
}
@media screen and (min-width: 767px) {
  .formBox .infoBox .contact .address .info--tel .tel .num {
    pointer-events: none;
  }
}
.formBox .infoBox .contact .address .info--tel .tel .num::before {
  content: "";
  width: 38px;
  height: 38px;
  display: inline-block;
  background: url(../img/common/icon/icon_tel_w.png) no-repeat center center;
  background-size: contain;
  margin: 0 10px -2px 0;
}
.formBox .infoBox .contact .address .info--tel .tel .num:hover {
  opacity: 0.8;
}
.formBox .infoBox .contact .address .info--tel .receptionTime {
  display: flex;
  font-size: 1.6rem;
  font-weight: 500;
  margin-top: 20px;
}
.formBox .infoBox .contact .address .info--tel .receptionTime dt {
  width: 5em;
  font-weight: bold;
}
.formBox .infoBox .contact .address .info--tel .receptionTime dd {
  width: calc(100% - 5em);
}
.formBox .step {
  display: inline-block;
  counter-reset: stepNum;
  margin-bottom: 55px;
  position: relative;
  left: 50%;
  transform: translateX(-50%);
}
.formBox .step::before {
  content: "";
  width: calc(100% - 5.5rem);
  height: 1px;
  display: inline-block;
  background: #D2D2D2;
  position: absolute;
  top: 0;
  left: 50%;
  transform: translateX(-50%);
}
.formBox .step__item {
  font-size: 1.8rem;
  font-weight: bold;
  text-align: center;
  padding-top: 26px;
  display: inline-block;
  position: relative;
}
.formBox .step__item::before {
  counter-increment: stepNum;
  content: "STEP0" counter(stepNum);
  display: block;
  font-size: 1.6rem;
  font-weight: 500;
  line-height: 1.625;
  color: #304A85;
  margin-bottom: 10px;
}
.formBox .step__item::after {
  content: "";
  width: 15px;
  height: 15px;
  display: inline-block;
  border-radius: 50px;
  background: #D2D2D2;
  position: absolute;
  top: -8px;
  left: 50%;
  transform: translateX(-50%);
}
.formBox .step__item.active::after {
  background: #304A85;
}
.formBox .step__item + .step__item {
  margin-left: 59px;
}
.formBox .btnWrap {
  margin-top: 40px;
}
.formBox .btn--form {
  display: block;
  text-align: center;
}
.formBox .btn--form:not(.back) input {
  font-size: 2rem;
  font-weight: bold;
  color: #fff;
  text-decoration: none;
  padding: 30px 68px 28px;
  border-radius: 5px;
  background: #304A85;
  box-shadow: 0px 5px 0 0px #162545;
  cursor: pointer;
}
@media screen and (min-width: 767px) {
  .formBox .btn--form:not(.back):hover input {
    box-shadow: none !important;
    transform: translateY(5px);
  }
}
.formBox .btn--form.back input {
  font-size: 1.4rem;
  color: #999;
  cursor: pointer;
}

/* =================================
  お問い合わせフォーム
================================= */
.text--security {
  font-size: 1.6rem;
  margin-bottom: 30px;
  text-align: center;
}

.contactForm table {
  width: 100%;
}
.contactForm th {
  font-size: 1.6rem;
  padding: 15px 65px 15px 20px;
  text-align: left;
  width: 240px;
  position: relative;
}
.contactForm th .sub {
  font-size: 1.4rem;
  display: block;
}
.contactForm th span:not(.sub) {
  font-size: 1.2rem;
  font-weight: normal;
  letter-spacing: 0.04em;
  line-height: 1;
  color: #fff;
  background: #D2D2D2;
  padding: 5px 12px;
  position: absolute;
  top: 20px;
  right: 10px;
}
.contactForm th .required {
  background: #FC2828 !important;
}
.contactForm td {
  font-size: 1.6rem;
  padding: 17px 15px 17px 20px;
  width: calc(100% - 240px);
  text-align: left;
}
.contactForm td p {
  line-height: 1.65;
}
.contactForm td * {
  font-size: 1.6rem;
}
.contactForm td input, .contactForm td textarea, .contactForm td select {
  background: #fff;
  border: 2px solid #D2D2D2;
  width: 100%;
  padding: 15px;
  box-sizing: border-box;
}
.contactForm td textarea {
  min-height: 170px;
}
.contactForm td .error {
  font-size: 1.4rem;
  color: #FC2828;
  margin: 10px 0 0;
  display: inline-block;
}
.contactForm .confirmingTxt {
  text-align: center;
  margin: 40px 0;
}
.contactForm .confirmingTxt p {
  font-size: 1.6rem;
}
.contactForm .confirmingTxt .checkText {
  font-size: 1.8rem;
  margin-top: 40px;
  display: inline-block;
}
.contactForm .confirmingTxt .checkText input {
  margin-right: 10px;
}
.contactForm .confirmingTxt input {
  -webkit-appearance: checkbox;
}
.contactForm .confirmingTxt a {
  font-weight: bold;
  text-decoration: none;
}
.contactForm .confirmingTxt a:hover {
  text-decoration: underline;
}

.consultForm__item {
  display: flex;
  align-items: center;
  background: #F5F8FF;
  padding: 38px 20px;
  border: 1px solid #D2D2D2;
}
.consultForm__item + .consultForm__item {
  margin-top: 20px;
}
.consultForm__ttl {
  font-size: 1.6rem;
  font-weight: bold;
  color: #304A85;
  width: 230px;
  padding-right: 55px;
  position: relative;
}
.consultForm__ttl .sub {
  font-size: 1.4rem;
  display: block;
}
.consultForm__ttl span:not(.sub) {
  font-size: 1.2rem;
  font-weight: normal;
  letter-spacing: 0.04em;
  line-height: 1;
  color: #fff;
  background: #D2D2D2;
  padding: 5px 12px;
  position: absolute;
  top: 50%;
  right: 0;
  transform: translateY(-50%);
}
.consultForm__ttl .required {
  background: #FC2828 !important;
}
.consultForm__list {
  font-size: 1.6rem;
  width: calc(93% - 230px);
  padding-left: 27px;
}
.consultForm__list p {
  line-height: 1.65;
}
.consultForm__list * {
  font-size: 1.6rem;
}
.consultForm__list input:not([type=radio]):not([type=checkbox]), .consultForm__list textarea, .consultForm__list select {
  background: #fff;
  border: 2px solid #D2D2D2;
  padding: 10px;
}
.consultForm__list input:not([type=radio]):not([type=checkbox]):not([class=widthNormal]), .consultForm__list textarea, .consultForm__list select {
  width: 100%;
  box-sizing: border-box;
}
.consultForm__list .mwform-radio-field {
  display: inline-block;
  margin-top: 1rem;
  margin-bottom: 1rem;
  margin-right: 3rem;
}
.consultForm__list .mwform-radio-field label {
  display: inline-block;
}
.consultForm__list input[type=radio] + span {
  padding-left: 2.8rem;
  display: inline-block;
  position: relative;
}
.consultForm__list input[type=radio] + span::before {
  content: "";
  width: 20px;
  height: 20px;
  box-sizing: border-box;
  margin: auto;
  border-radius: 50%;
  border: solid 2px #D2D2D2;
  background-color: #fff;
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
}
.consultForm__list input[type=radio]:checked + span::after {
  content: "";
  width: 12px;
  height: 12px;
  border-radius: 50%;
  margin: auto 0;
  background-color: #304A85;
  position: absolute;
  top: 0;
  bottom: 0;
  left: 4px;
}
.consultForm__list .mwform-checkbox-field {
  display: inline-block;
  margin-top: 1rem;
  margin-bottom: 1rem;
  margin-right: 3rem;
}
.consultForm__list .mwform-checkbox-field label {
  display: inline-block;
}
.consultForm__list input[type=checkbox] + span {
  padding-left: 2.8rem;
  display: inline-block;
  position: relative;
}
.consultForm__list input[type=checkbox] + span::before {
  content: "";
  width: 20px;
  height: 20px;
  box-sizing: border-box;
  margin: auto;
  border-radius: 4px;
  border: solid 2px #D2D2D2;
  background-color: #fff;
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
}
.consultForm__list input[type=checkbox]:checked + span::after {
  content: "";
  width: 11px;
  height: 7px;
  display: inline-block;
  background: url(../img/common/icon/icon_check.png) no-repeat center center;
  background-size: contain;
  position: absolute;
  top: 10px;
  left: 5px;
}
.consultForm__list textarea {
  min-height: 170px;
}
.consultForm__list .error {
  font-size: 1.4rem;
  color: #FC2828;
  margin: 10px 0 0;
  display: block;
}
.consultForm__list .yoyaku {
  display: flex;
  align-items: center;
}
.consultForm__list .yoyaku .rank {
  font-weight: bold;
  color: #304A85;
  width: 85px;
}
.consultForm__list .yoyaku .yoyakuBox {
  width: calc(100% - 85px);
  display: flex;
}
.consultForm__list .yoyaku .yoyakudate {
  width: calc((100% - 15px) / 2) !important;
}
.consultForm__list .yoyaku .yoyakujikan {
  width: calc((100% - 15px) / 2) !important;
  margin-left: 15px;
}
.consultForm__list .yoyaku + .yoyaku {
  margin-top: 15px;
}
.consultForm .confirmingTxt {
  text-align: center;
  margin: 37px 0;
}
.consultForm .confirmingTxt p {
  font-size: 1.4rem;
}
.consultForm .confirmingTxt input {
  -webkit-appearance: checkbox;
}
.consultForm .confirmingTxt a {
  text-decoration: underline;
}
.consultForm .confirmingTxt a:hover {
  color: #304A85;
}
.consultForm + .consultForm {
  border-top: 1px dashed #D2D2D2;
  padding-top: 20px;
  margin-top: 20px;
}

.completedForm .completedMessage {
  font-size: 1.8rem;
  font-weight: bold;
  line-height: 1;
  letter-spacing: 0.06em;
  display: inline-block;
  margin: 24px 0 0;
  position: relative;
  left: 50%;
  transform: translateX(-50%);
}
.completedForm .completedMessage::after {
  content: "";
  width: 100%;
  height: 3px;
  background: #304A85;
  display: inline-block;
}
.completedForm p {
  font-size: 1.5rem;
  text-align: center;
  margin: 40px 0 0;
}
.completedForm table {
  width: initial;
  margin: 40px auto 45px;
}
.completedForm th, .completedForm td {
  width: auto;
}
.completedForm th {
  font-size: 1.6rem;
  font-weight: bold;
  vertical-align: middle;
  padding: 15px 30px;
}
.completedForm td {
  vertical-align: middle;
  padding: 15px 30px;
}
.completedForm td .tel {
  font-size: 3.4rem;
  font-weight: bold;
  color: #304A85;
  line-height: 1;
  text-decoration: none;
  margin: 0;
}
@media screen and (min-width: 767px) {
  .completedForm td .tel {
    pointer-events: none;
  }
}
.completedForm td .receptionTime {
  display: flex;
  margin: 10px 0 0;
}
.completedForm td .receptionTime dt {
  font-size: 1.3rem;
  font-weight: bold;
  color: #304A85;
  width: 5.5em;
}
.completedForm td .receptionTime dd {
  font-size: 1.3rem;
  width: calc(100% - 5.5em);
}

@media screen and (max-width: 767px) {
  /*=================================
    お問い合わせ
  =================================*/
  .formBox h1 {
    margin-top: 30px !important;
    margin-bottom: 30px !important;
  }
  .formBox h2 {
    margin-top: 30px !important;
    margin-bottom: 30px !important;
  }
  .formBox h3 {
    margin-top: 30px !important;
    margin-bottom: 30px !important;
  }
  .formBox h4 {
    margin-top: 30px !important;
    margin-bottom: 30px !important;
  }
  .formBox h5 {
    margin-top: 30px !important;
    margin-bottom: 30px !important;
  }
  .formBox h6 {
    margin-top: 30px !important;
    margin-bottom: 30px !important;
  }
  .formBox .infoBox {
    display: block;
    margin-top: 0;
    overflow: hidden;
  }
  .formBox .infoBox .thumb {
    width: 30%;
    margin-right: 0;
    margin-bottom: 15px;
    float: left;
  }
  .formBox .infoBox .contact {
    width: 100%;
  }
  .formBox .infoBox .contact .infoBoxTtl {
    font-size: 1.5rem;
    line-height: 1.5;
    width: calc(100% - (30% + 15px));
    margin-left: auto;
  }
  .formBox .infoBox .contact .address .info--tel {
    margin-top: 0;
  }
  .formBox .infoBox .contact .address .info--tel .tel {
    width: calc(100% - (30% + 15px));
    margin-left: auto;
    margin-top: 15px;
  }
  .formBox .infoBox .contact .address .info--tel .tel .num {
    font-size: 1.8rem;
    color: #fff;
    background: #304A85;
    display: block;
    padding: 10px 10px;
    border-radius: 3px;
    text-align: center;
  }
  .formBox .infoBox .contact .address .info--tel .tel .num::before {
    width: 18px;
    height: 18px;
    display: inline-block;
    margin: 0 7px -3px 0;
  }
  .formBox .infoBox .contact .address .info--tel .receptionTime {
    width: 100%;
    font-size: 1.4rem;
    margin-top: 15px;
  }
  .formBox .step {
    margin-bottom: 40px;
  }
  .formBox .step::before {
    width: calc(100% - 5rem);
  }
  .formBox .step__item {
    font-size: 1.6rem;
    padding-top: 20px;
  }
  .formBox .step__item::before {
    font-size: 1.4rem;
    margin-bottom: 5px;
  }
  .formBox .step__item::after {
    width: 13px;
    height: 13px;
    top: -6px;
  }
  .formBox .step__item.active::after {
    background: #304A85;
  }
  .formBox .step__item + .step__item {
    margin-left: 15.1282051282vw;
  }
  .formBox .btnWrap {
    margin-top: 35px;
  }
  .formBox .btn--form:not(.back) input {
    font-size: 1.6rem;
    padding: 15px 3.5897435897% 13px;
    border-radius: 4px;
    box-shadow: 0px 4px 0 0px #162545;
  }
  /* =================================
    お問い合わせフォーム
  ================================= */
  .text--security {
    font-size: 1.4rem;
  }
  .contactForm table {
    display: table;
    white-space: initial;
  }
  .contactForm th {
    font-size: 1.5rem;
    padding: 15px 3.5897435897%;
    width: 100%;
    display: block;
  }
  .contactForm th span:not(.sub) {
    font-size: 1.1rem;
    letter-spacing: 0.4em;
    padding: 4px 4px 4px 8px;
    margin-left: 1.5rem;
    position: static;
    transform: translateY(0);
  }
  .contactForm td {
    padding: 15px 3.5897435897%;
    width: 100%;
    display: block;
  }
  .contactForm td p {
    line-height: 1.5;
  }
  .contactForm td input, .contactForm td textarea, .contactForm td select {
    padding: 12px;
  }
  .contactForm td .error {
    font-size: 1.3rem;
  }
  .contactForm .confirmingTxt {
    margin: 30px 0;
  }
  .contactForm .confirmingTxt p {
    font-size: 1.4rem;
  }
  .contactForm .confirmingTxt .checkText {
    font-size: 1.6rem;
    margin-top: 10px;
  }
  .consultForm__item {
    display: block;
    padding: 10px 3.5897435897% 20px;
  }
  .consultForm__ttl {
    font-size: 1.5rem;
    padding: 15px 0;
    width: 100%;
    display: block;
  }
  .consultForm__ttl .sub {
    font-size: 1.4rem;
    display: inline-block;
  }
  .consultForm__ttl span:not(.sub) {
    font-size: 1.1rem;
    letter-spacing: 0.4em;
    padding: 4px 4px 4px 8px;
    margin-left: 1.5rem;
    position: static;
    transform: translateY(0);
  }
  .consultForm__list {
    width: 100%;
    padding-left: 0;
  }
  .consultForm__list p {
    line-height: 1.5;
  }
  .consultForm__list .mwform-radio-field {
    margin-right: 0.5rem;
  }
  .consultForm__list .mwform-checkbox-field {
    margin-right: 0.5rem;
  }
  .consultForm__list input[type=checkbox]:checked + span::after {
    width: 15px;
    height: 10px;
    top: 6px;
    left: 3px;
  }
  .consultForm__list .error {
    font-size: 1.3rem;
  }
  .consultForm__list .yoyaku {
    flex-wrap: wrap;
  }
  .consultForm__list .yoyaku .rank {
    width: 100%;
    margin-bottom: 10px;
  }
  .consultForm__list .yoyaku .yoyakuBox {
    width: 100%;
    display: block;
  }
  .consultForm__list .yoyaku .yoyakudate {
    width: 100% !important;
  }
  .consultForm__list .yoyaku .yoyakujikan {
    width: 100% !important;
    margin: 10px 0 0;
  }
  .consultForm__list .yoyaku + .yoyaku {
    margin-top: 20px;
  }
  .consultForm .confirmingTxt {
    text-align: center;
    margin: 37px 0;
  }
  .consultForm .confirmingTxt p {
    font-size: 1.4rem;
  }
  .completedForm p {
    margin: 30px 0 0;
  }
  .completedForm table {
    display: table;
    white-space: initial;
    margin: 40px auto 45px;
  }
  .completedForm th, .completedForm td {
    width: auto;
  }
  .completedForm th {
    font-size: 1.4rem;
    padding: 15px 3.5897435897%;
  }
  .completedForm td {
    padding: 15px 3.5897435897%;
  }
  .completedForm td .tel {
    font-size: 3rem;
  }
  .completedForm td .receptionTime {
    text-align: left;
  }
}
/* =================================
  header
================================= */
.header {
  width: 100%;
  background: #fff;
  box-shadow: 0 5px 25px rgba(34, 34, 34, 0.05);
}
.header__logo {
  max-width: calc(1270px + 2%);
  width: 50%;
  margin-bottom: -82px;
  margin-right: auto;
  padding: 30px 1%;
}
.header__logo .logo {
  display: inline-block;
  vertical-align: middle;
  margin-right: 36px;
}
@media screen and (min-width: 767px) and (max-width: 1366px) {
  .header__logo .logo {
    margin-right: 2.635431918vw;
  }
}
.header__logo .logo a {
  display: block;
}
@media screen and (min-width: 767px) {
  .header__logo .logo a:hover {
    opacity: 0.8;
  }
}
.header__logo .text {
  font-size: 1.4rem;
  display: inline-block;
  vertical-align: middle;
  max-width: 13em;
}
.header .contact {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  width: 50%;
  margin: 0 calc((100% - 1270px) / 2) 19px auto;
  padding: 0 1%;
}
@media screen and (min-width: 767px) and (max-width: 1366px) {
  .header .contact {
    margin: 0 0 19px auto;
  }
}
.header .contact__wrap {
  text-align: center;
}
.header .contact__wrap .contactText {
  font-size: 1.1rem;
  font-weight: 600;
  line-height: 1;
  letter-spacing: 0.05em;
  display: block;
  margin: 5px 0 0;
}
.header .contact__wrap .contactText.sm {
  letter-spacing: 0;
}
.header .contact__wrap.tel {
  padding: 10px 30px;
  position: relative;
}
@media screen and (min-width: 767px) and (max-width: 1366px) {
  .header .contact__wrap.tel {
    padding: 0.7320644217vw 2.196193265vw;
  }
}
.header .contact__wrap.tel .telNum {
  font-family: "Lato", sans-serif;
  font-size: 2.4rem;
  font-weight: 700;
  line-height: 1.208333;
  color: #222;
  text-decoration: none;
  display: block;
  position: relative;
}
@media screen and (min-width: 767px) {
  .header .contact__wrap.tel .telNum {
    pointer-events: none;
  }
}
.header .contact__wrap.tel .telNum::before {
  content: "";
  width: 22px;
  height: 22px;
  display: inline-block;
  mask-image: url(../img/common/icon/icon_tel.svg);
  mask-size: contain;
  mask-repeat: no-repeat;
  mask-position: center;
  background-color: #222;
  background-size: contain;
  position: absolute;
  top: 5px;
  left: -30px;
}
@media screen and (min-width: 767px) and (max-width: 1366px) {
  .header .contact__wrap.tel .telNum::before {
    width: 20px;
    height: 20px;
    left: -25px;
  }
}
.header .contact__wrap.reservation {
  background: #FECB3F;
  border-radius: 5px;
  box-shadow: 0 3px 0 #957725;
  padding: 7px 20px 10px 48px;
  margin: 0 0 0 10px;
  position: relative;
  transition-property: all;
  transition-duration: 0s;
  transition-timing-function: ease-out;
}
@media screen and (min-width: 767px) and (max-width: 1366px) {
  .header .contact__wrap.reservation {
    padding: 0.5124450952vw 1.4641288433vw 0.7320644217vw 3.513909224vw;
  }
}
.header .contact__wrap.reservation::before {
  content: "";
  width: 20px;
  height: 14px;
  display: inline-block;
  mask-image: url(../img/common/icon/icon_mail.svg);
  mask-size: contain;
  mask-repeat: no-repeat;
  mask-position: center;
  background-color: #222;
  background-size: contain;
  position: absolute;
  top: 50%;
  left: 19px;
  transform: translateY(-50%);
}
@media screen and (min-width: 767px) and (max-width: 1366px) {
  .header .contact__wrap.reservation::before {
    width: 18px;
    height: 12px;
    left: 10px;
  }
}
@media screen and (min-width: 767px) {
  .header .contact__wrap.reservation:hover {
    box-shadow: none;
    transform: translateY(3px);
  }
}
.header .contact__wrap.reservation .reservationLink {
  font-size: 1.9rem;
  font-weight: 500;
  line-height: 1.421052;
  color: #222;
  text-decoration: none;
  display: block;
}
.header .gNav__inner {
  display: flex;
  flex-direction: column-reverse;
}
.header .gNavList {
  background: #304A85;
  padding: 0 1%;
}
.header .gNavList__inner {
  max-width: 1156px;
  margin: 0 auto;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
}
.header .gNavList__item {
  display: inline-block;
  position: relative;
}
.header .gNavList__item::after {
  content: "";
  width: 0;
  height: 4px;
  display: block;
  background: #fff;
  position: absolute;
  left: 50%;
  bottom: 0;
  transform: translateX(-50%);
  z-index: 0;
  transition-property: all;
  transition-duration: 0.1s;
  transition-timing-function: ease-out;
}
@media screen and (min-width: 767px) {
  .header .gNavList__item:hover::after {
    width: 100%;
  }
}
.header .gNavList__item a {
  font-size: 1.6rem;
  font-weight: 500;
  color: #fff;
  line-height: 1.428571;
  text-decoration: none;
  padding: 29px 8px;
  display: block;
  position: relative;
  z-index: 2;
}
.header .gNavList__item.current-menu-item a {
  color: #304A85;
}
.header .gNavList__item.current-menu-item a::after {
  width: 100%;
}

@media screen and (min-width: 920px) {
  .gNavList.fixedNav {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    width: 100%;
    box-shadow: 0 5px 25px rgba(34, 34, 34, 0.3);
    z-index: 999;
    animation: header-show 0.5s ease-in;
  }
}
@media screen and (max-width: 920px) {
  /* =================================
    header
  ================================= */
  .header {
    padding: 0;
    position: fixed;
    top: 0;
    left: 0;
    z-index: 9999;
    /*バーガーボタン設定*/
  }
  .header__inner {
    display: block;
  }
  .header__logo {
    width: calc(100% - 60px);
    min-height: 60px;
    padding: 11px 0 11px 3.3333333333%;
    background: #fff;
  }
  .header__logo .logo {
    width: 49.2307692308vw;
    line-height: 1;
  }
  .header__logo .text {
    display: none;
  }
  .header .contact {
    display: block;
    margin: 40px auto 0;
    width: 100%;
  }
  .header .contact__wrap .contactText {
    font-size: 1.4rem;
  }
  .header .contact__wrap .contactText.sm {
    font-size: 1.3rem;
  }
  .header .contact__wrap.tel {
    padding: 16px 7.6923076923%;
    border-radius: 5px;
    background: #fff;
    position: relative;
  }
  .header .contact__wrap.tel::before {
    content: "";
    width: 30px;
    height: 30px;
    display: inline-block;
    mask-image: url(../img/common/icon/icon_tel.svg);
    mask-size: contain;
    mask-repeat: no-repeat;
    mask-position: center;
    background-color: #222;
    background-size: contain;
    position: absolute;
    top: 50%;
    left: 7.6923076923%;
    transform: translateY(-50%);
  }
  .header .contact__wrap.tel .telNum {
    font-size: 3.1rem;
    line-height: 1.225806;
  }
  .header .contact__wrap.tel .telNum::before {
    content: none;
  }
  .header .contact__wrap.reservation {
    box-shadow: 0 4px 0 #957725;
    padding: 16px 7.6923076923%;
    margin: 20px 0 0;
  }
  .header .contact__wrap.reservation::before {
    width: 31px;
    height: 22px;
    left: 8.9743589744%;
  }
  .header .contact__wrap.reservation .reservationLink {
    font-size: 2.2rem;
    line-height: 1.454545;
  }
  .header .gNav {
    overflow-y: scroll;
    max-height: 90vh;
    width: 100%;
    margin: auto;
    padding: 40px 6.4102564103%;
    position: fixed;
    top: 60px;
    left: 0;
    z-index: 9999;
    transform: scale(0.7);
    opacity: 0;
    visibility: hidden;
    transition: opacity 0.5s ease-in-out, transform 0.3s ease-out, visibility 0.2s ease-out;
  }
  .header .gNav__inner {
    display: block;
  }
  .header .gNavList {
    background: none;
  }
  .header .gNavList__inner {
    display: block;
  }
  .header .gNavList__item {
    display: block;
    margin: 0;
  }
  .header .gNavList__item::after {
    content: none;
  }
  .header .gNavList__item a {
    font-size: 1.4rem;
    color: #222;
    padding: 20px 40px 20px 3.8461538462%;
    border-radius: 5px;
    background: #fff;
  }
  .header .gNavList__item a::after {
    content: "";
    width: 20px;
    height: 20px;
    display: block;
    background: url(../img/common/icon/icon_gnav_arrow.svg) no-repeat center center;
    background-size: contain;
    position: absolute;
    right: 12px;
    top: 50%;
    transform: translateY(-50%);
  }
  .header .gNavList__item + .gNavList__item {
    margin-top: 20px;
  }
  .header .gNav.show {
    transform: scale(1);
    visibility: visible;
    opacity: 1;
  }
  .header .gNav.hide {
    transform: scale(0.7);
    opacity: 0;
    visibility: hidden;
  }
  .header .headerNav__cover {
    height: 0;
    width: 100vw;
    background: url(../img/common/bg_burger_gray.svg) no-repeat right bottom #304A85;
    position: fixed;
    top: 60px;
    left: 0;
    display: none;
    z-index: 9998;
  }
  .header .headerNav__cover.show {
    display: block;
    animation: show 0.2s linear 0s;
  }
  .header .headerNav__cover.hide {
    display: none;
    animation: hide 0.2s linear 0s;
  }
  .header .burger {
    width: 60px;
    height: 60px;
    background: #fff;
    margin: auto;
    border-left: 2px solid #F6F5F3;
    position: absolute;
    right: 0;
    top: 0;
    cursor: pointer;
    z-index: 9999;
    /*クリック後、バツボタンになる*/
  }
  .header .burger::after {
    content: "";
    width: 18px;
    height: 16px;
    display: inline-block;
    background: url(../img/common/btn_burger_open.svg) no-repeat center center;
    background-size: contain;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }
  .header .burger.is-open::after {
    width: 16px;
    height: 16px;
    background: url(../img/common/btn_burger_close.svg) no-repeat center center;
  }
}
/*=================================
footer
=================================*/
.footer {
  background: #fff;
}
.footer__inner {
  max-width: calc(1260px + 2%);
  margin: 0 auto;
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  padding: 30px 1%;
}
.footer__logo {
  width: 200px;
}
.footer__sns {
  margin-left: 66px;
}
.footer__sns .snsList__item {
  width: 20px;
  display: inline-block;
  margin-left: 10px;
}
.footer__sns .snsList__item a {
  display: block;
  line-height: 1;
}
@media screen and (min-width: 767px) {
  .footer__sns .snsList__item a:hover img {
    filter: invert(26%) sepia(11%) saturate(3171%) hue-rotate(184deg) brightness(102%) contrast(93%);
  }
}
.footer__cont {
  margin-left: auto;
}
.footer .fNavList__item {
  display: inline-block;
  margin-left: 30px;
}
.footer .fNavList__item a {
  font-size: 1.4rem;
  font-weight: 400;
  color: #222;
  text-decoration: none;
}
@media screen and (min-width: 767px) {
  .footer .fNavList__item a:hover {
    text-decoration: underline;
    color: #304A85;
  }
}
.footer .copyWrap {
  background: #304A85;
  padding: 16px 1%;
}
.footer .copyWrap__inner {
  max-width: 1260px;
  margin: 0 auto;
  display: flex;
  justify-content: space-between;
}
.footer .copyWrap__inner > * {
  font-size: 1.2rem;
  line-height: 1.5;
  color: #fff;
}
.footer .copy {
  font-family: "Lato", sans-serif;
}

.links {
  padding: 46px 1% 48px;
  background: rgb(232, 236, 245);
  background: -moz-linear-gradient(95deg, rgb(232, 236, 245) 0%, rgb(230, 226, 215) 100%);
  background: -webkit-linear-gradient(95deg, rgb(232, 236, 245) 0%, rgb(230, 226, 215) 100%);
  background: linear-gradient(95deg, rgb(232, 236, 245) 0%, rgb(230, 226, 215) 100%);
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#e8ecf5",endColorstr="#e6e2d7",GradientType=1);
}
.links__text {
  font-size: 1.6rem;
  text-align: center;
}
.linksBox {
  max-width: 1000px;
  margin: 0 auto;
  display: flex;
  flex-wrap: wrap;
}
.linksBox .linksBox__item {
  display: flex;
  align-items: center;
  background: #fff;
  box-shadow: 0 3px 15px rgba(34, 34, 34, 0.05);
  border-radius: 10px;
  width: calc((100% - 40px) / 2);
  padding: 32px 24px;
  margin-top: 38px;
}
@media screen and (min-width: 767px) {
  .linksBox .linksBox__item:hover {
    box-shadow: 0 3px 15px rgba(34, 34, 34, 0.3);
  }
  .linksBox .linksBox__item:hover .textWrap .link {
    text-decoration: underline;
  }
}
@media screen and (min-width: 767px) and (max-width: 1366px) {
  .linksBox .linksBox__item {
    width: calc((100% - 2.9282576867vw) / 2);
  }
}
.linksBox .linksBox__item:nth-child(2n) {
  margin-left: 40px;
}
@media screen and (min-width: 767px) and (max-width: 1366px) {
  .linksBox .linksBox__item:nth-child(2n) {
    margin-left: 2.9282576867vw;
  }
}
.linksBox .linksBox__item.blue {
  position: relative;
  padding-right: 74px;
}
.linksBox .linksBox__item.blue::after {
  content: "";
  width: 43px;
  height: 43px;
  display: inline-block;
  background: url(../img/common/icon/icon_arrow_blue_pc.png) no-repeat center center;
  background-size: contain;
  position: absolute;
  top: 50%;
  right: 24px;
  transform: translateY(-50%);
}
@media screen and (min-width: 767px) {
  .linksBox .linksBox__item.blue:hover::after {
    right: 20px;
  }
}
.linksBox .linksBox__item.yellow {
  position: relative;
  padding-right: 74px;
}
.linksBox .linksBox__item.yellow::after {
  content: "";
  width: 43px;
  height: 43px;
  display: inline-block;
  background: url(../img/common/icon/icon_arrow_yellow_pc.png) no-repeat center center;
  background-size: contain;
  position: absolute;
  top: 50%;
  right: 24px;
  transform: translateY(-50%);
}
.linksBox .linksBox__item.yellow .textWrap .link::before {
  background: #776231;
}
@media screen and (min-width: 767px) {
  .linksBox .linksBox__item.yellow:hover::after {
    right: 20px;
  }
}
.linksBox .linksBox__item .img {
  width: 100px;
  height: auto;
  margin-right: 14px;
}
.linksBox .linksBox__item .img + .textWrap {
  width: calc(100% - 114px);
}
.linksBox .linksBox__item .textWrap .link {
  font-size: 1.8rem;
  font-weight: 700;
  color: #222;
  text-decoration: none;
  padding-left: 18px;
  position: relative;
}
.linksBox .linksBox__item .textWrap .link:before {
  content: "";
  width: 10px;
  height: 10px;
  display: inline-block;
  background: #304A85;
  border-radius: 50px;
  position: absolute;
  top: 10px;
  left: 0;
}
.linksBox .linksBox__item .textWrap .text {
  font-size: 1.4rem;
  line-height: 1.71428;
  padding-left: 17px;
  margin-top: 12px;
}

.fixedBnr {
  padding: 14px 5%;
  position: fixed;
  left: 0;
  bottom: 0;
  width: 100%;
  background: rgba(255, 234, 176, 0.8);
  backdrop-filter: blur(2px);
  opacity: 0;
  z-index: 99;
  transition-property: all;
  transition-duration: 0.2s;
  transition-timing-function: ease-out;
}
.fixedBnr__inner {
  max-width: 1140px;
  margin: 0 auto;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 20px;
}
.fixedBnr__ttl {
  font-size: 2rem;
  font-weight: 700;
}
.fixedBnr__ttl .em {
  font-size: 2.8rem;
  color: #dd1515;
}
.fixedBnr__btn {
  font-size: 2.4rem;
  font-weight: 700;
  color: #fff;
  text-decoration: none;
  background: #dd1515;
  box-shadow: 0 5px 0 #9f2b22;
  display: inline-block;
  border-radius: 5px;
  padding: 4px 20px;
  white-space: nowrap;
  transition-property: all;
  transition-duration: 0s;
  transition-timing-function: ease-out;
}
.fixedBnr__btn .em {
  font-size: 3rem;
  display: inline-block;
}
@media screen and (min-width: 767px) {
  .fixedBnr__btn:hover {
    box-shadow: none;
    transform: translateY(5px);
  }
}
.fixedBnr.fixed {
  opacity: 1;
}

@media screen and (min-width: 0) and (max-width: 767px) {
  /*=================================
    footer
  =================================*/
  .footer__inner {
    display: block;
    padding: 30px 2.8205128205%;
    text-align: center;
  }
  .footer__logo {
    width: 170px;
    margin: 0 auto;
  }
  .footer__sns {
    margin: 32px 0 0;
  }
  .footer__sns .snsList__item {
    width: 26px;
    margin: 0 10px;
  }
  .footer__cont {
    margin: 30px auto 0;
  }
  .footer .fNavList__item {
    margin: 0 10px;
  }
  .footer .copyWrap {
    padding: 19px 2.8205128205%;
    text-align: center;
  }
  .footer .copyWrap__inner {
    display: block;
  }
  .links {
    padding: 40px 4.358974359%;
  }
  .links__text {
    font-size: 1.4rem;
    line-height: 1.857142;
    text-align: left;
    margin-bottom: 30px;
  }
  .linksBox {
    display: block;
  }
  .linksBox .linksBox__item {
    align-items: flex-start;
    border-radius: 5px;
    width: 100%;
    padding: 20px 3.8461538462%;
    margin-top: 20px;
  }
  .linksBox .linksBox__item:nth-child(2n) {
    margin-left: 0;
  }
  .linksBox .linksBox__item.blue {
    padding-right: 16.6666666667%;
  }
  .linksBox .linksBox__item.blue::after {
    width: 32px;
    height: 32px;
    background: url(../img/common/icon/icon_arrow_blue_sp.png) no-repeat center center;
    background-size: contain;
    right: 5.1282051282%;
  }
  .linksBox .linksBox__item.yellow {
    padding-right: 16.6666666667%;
  }
  .linksBox .linksBox__item.yellow::after {
    width: 32px;
    height: 32px;
    background: url(../img/common/icon/icon_arrow_yellow_sp.png) no-repeat center center;
    background-size: contain;
    right: 5.1282051282%;
  }
  .linksBox .linksBox__item .img {
    width: 74px;
    margin-right: 10px;
  }
  .linksBox .linksBox__item .img + .textWrap {
    width: calc(100% - 84px);
  }
  .linksBox .linksBox__item .textWrap .link {
    font-size: 1.6rem;
  }
  .linksBox .linksBox__item .textWrap .link:before {
    width: 8px;
    height: 8px;
    top: 8px;
  }
  .linksBox .linksBox__item .textWrap .text {
    margin-top: 9px;
  }
  .fixedBnr {
    padding: 8px 2.8205128205% 12px;
  }
  .fixedBnr__inner {
    display: block;
  }
  .fixedBnr__ttl {
    font-size: 1.5rem;
    text-align: center;
  }
  .fixedBnr__ttl .em {
    font-size: 2rem;
  }
  .fixedBnr__btn {
    font-size: 2rem;
    box-shadow: 0 3px 0 #9f2b22;
    padding: 5px 2.8205128205%;
    display: block;
    text-align: center;
    margin-top: 10px;
  }
  .fixedBnr__btn .em {
    font-size: 2.2rem;
  }
}
/*=================================
  category
=================================*/
.category {
  background: url(../img/common/bg_category_pc.jpg) no-repeat center top;
  background-size: cover;
  padding: 100px 0 0;
  margin: 0 0 100px;
  position: relative;
}
.category::after {
  content: "";
  width: 100%;
  height: 112px;
  display: block;
  background: #fff;
  position: absolute;
  left: 0;
  bottom: 0;
  z-index: 0;
}
.category__ttl {
  color: #fff;
  text-align: center;
}
.category__ttl .ttl {
  font-size: 3.6rem;
  font-weight: 700;
  line-height: 1.5;
  margin: 0 0 56px;
}
.category__ttl .ttl::before {
  content: "";
  width: 10px;
  height: 10px;
  display: inline-block;
  vertical-align: middle;
  border-radius: 50px;
  background: #fff;
  margin: -5px 12px 0 0;
}
.category__ttl .text {
  font-size: 1.6rem;
  line-height: 2;
  margin: 7px 0 0;
}
.categoryWrap {
  background: #304A85;
  padding: 55px 80px 70px;
  max-width: 1140px;
  margin: 0 auto;
  position: relative;
  z-index: 1;
}
.categoryList {
  max-width: 1140px;
  margin: 0 auto;
  display: flex;
  flex-wrap: wrap;
}
.categoryList__item {
  display: flex;
  align-items: center;
  width: calc((100% - 20px) / 2);
  background: #fff;
  border-radius: 5px;
  margin: 0 0 20px;
  padding: 19px 28px;
}
.categoryList__item a {
  font-size: 2.2rem;
  font-weight: 700;
  text-decoration: none;
  color: #222;
}
@media screen and (min-width: 767px) {
  .categoryList__item a:hover {
    color: #304A85;
    text-decoration: underline;
  }
}
.categoryList__item img {
  display: inline-block;
  vertical-align: middle;
  margin-right: 16px;
}
.categoryList__item:nth-child(2n) {
  margin-left: 20px;
}

@media screen and (min-width: 0) and (max-width: 767px) {
  /*=================================
    category
  =================================*/
  .category {
    background: url(../img/common/bg_category_sp.jpg) no-repeat center top;
    background-size: cover;
    padding: 40px 5.1282051282% 40px 0;
    margin: 0 0 40px;
  }
  .category::after {
    content: none;
  }
  .category__ttl {
    text-align: left;
  }
  .category__ttl .ttl {
    font-size: 2.4rem;
    line-height: 1;
    margin: 0 0 27px;
  }
  .category__ttl .ttl::before {
    width: 8px;
    height: 8px;
    margin: -4px 9px 0 0;
  }
  .category__ttl .text {
    font-size: 1.4rem;
  }
  .categoryWrap {
    padding: 40px 7.6923076923% 28px;
  }
  .categoryList {
    display: block;
  }
  .categoryList__item {
    justify-content: left;
    width: 100%;
    border-radius: 3px;
    margin: 0 0 12px;
    padding: 13px 5.1282051282%;
  }
  .categoryList__item a {
    font-size: 1.6rem;
    line-height: 1.25;
  }
  .categoryList__item img {
    width: 30px;
    height: 30px;
    object-fit: contain;
  }
  .categoryList__item:nth-child(2n) {
    margin-left: 0;
  }
}
/*=================================
  cvBox
=================================*/
.cvBox {
  background: url(../img/common/bg_cv_pc.png) no-repeat left bottom 112px;
  background-size: contain;
  padding: 0 0 270px;
  position: relative;
}
@media screen and (min-width: 767px) and (max-width: 1366px) {
  .cvBox {
    background: url(../img/common/bg_cv_pc.png) no-repeat left bottom 8.1991215227vw;
    padding: 0 0 19.7657393851vw 13.4699853587vw;
  }
}
.cvBox__inner {
  max-width: 1140px;
  margin: 0 auto;
}
.cvBox__cont {
  max-width: 880px;
  margin: 0 0 0 auto;
  background: #fff;
  padding: 15px 0 32px 82px;
  position: relative;
}
@media screen and (min-width: 767px) and (max-width: 1366px) {
  .cvBox__cont {
    padding: 15px 6.0029282577vw 32px;
  }
}
.cvBox__cont::before {
  content: "";
  width: 317px;
  height: 536px;
  display: inline-block;
  background: url(../img/common/img_cv.png) no-repeat center center;
  background-size: contain;
  position: absolute;
  bottom: -158px;
  left: -184px;
  z-index: 1;
}
@media screen and (min-width: 767px) and (max-width: 1366px) {
  .cvBox__cont::before {
    width: 23.2064421669vw;
    height: 39.2386530015vw;
    bottom: -11.5666178624vw;
    left: -13.4699853587vw;
  }
}
.cvBox::after {
  content: "";
  width: 100%;
  height: 112px;
  display: block;
  background: #fff;
  position: absolute;
  left: 0;
  bottom: 0;
  z-index: 0;
}
@media screen and (min-width: 767px) and (max-width: 1366px) {
  .cvBox::after {
    height: 8.1991215227vw;
  }
}
.cvBox__ttl {
  font-size: 4.5rem;
  font-weight: 700;
}
@media screen and (min-width: 767px) and (max-width: 1366px) {
  .cvBox__ttl {
    font-size: clamp(3.5rem, 3.2942898975vw, 4.5rem);
  }
}
.cvBox__ttl::before {
  content: "";
  width: 12px;
  height: 12px;
  display: inline-block;
  vertical-align: middle;
  border-radius: 50px;
  background: #304A85;
  margin: -5px 15px 0 0;
}
.cvBox__text {
  font-size: 1.6rem;
  line-height: 1.5;
}
.cvBox .telBox {
  text-align: center;
  max-width: 80%;
  margin: 45px auto 0;
}
.cvBox .telBox__ttl {
  font-size: clamp(1.6rem, 1.6105417277vw, 2.2rem);
  font-weight: 700;
  margin: 0 0 20px;
}
.cvBox .telBox .btn {
  font-family: "Lato", sans-serif;
  font-size: 3.2rem;
  font-weight: 700;
  line-height: 1;
  text-decoration: none;
  color: #222;
  border-radius: 10px;
  padding: 22px 40px 16px;
  border: 3px solid #D8D8D8;
  background: #FECB3F;
  margin: 0 0 26px;
  display: block;
}
@media screen and (min-width: 767px) {
  .cvBox .telBox .btn {
    pointer-events: none;
  }
}
@media screen and (min-width: 767px) and (max-width: 1366px) {
  .cvBox .telBox .btn {
    font-size: clamp(2.7rem, 2.3426061493vw, 3.2rem);
  }
}
.cvBox .telBox .btn .text {
  font-family: "Noto Sans JP", "源ノ角ゴシック", "Yu Gothic Medium", "游ゴシック Medium", "游ゴシック", YuGothic, YuGothicM, "ヒラギノ角ゴ Pro W3", "Hiragino Kaku Gothic Pro", "メイリオ", Meiryo, "ＭＳ Ｐゴシック", sans-serif;
  font-size: 2.5rem;
  font-weight: 500;
  margin: 0 17px 0 0;
}
@media screen and (min-width: 767px) and (max-width: 1366px) {
  .cvBox .telBox .btn .text {
    font-size: clamp(2rem, 1.8301610542vw, 2.5rem);
  }
}
.cvBox .telBox .btn .time {
  font-family: "Noto Sans JP", "源ノ角ゴシック", "Yu Gothic Medium", "游ゴシック Medium", "游ゴシック", YuGothic, YuGothicM, "ヒラギノ角ゴ Pro W3", "Hiragino Kaku Gothic Pro", "メイリオ", Meiryo, "ＭＳ Ｐゴシック", sans-serif;
  font-size: 1.4rem;
  font-weight: 500;
  line-height: 1;
  display: block;
  margin: 9px 0 0;
}
.cvBox .telBox .btn img {
  width: 39px;
  height: auto;
  margin: 0 17px -5px 0;
  display: inline-block;
}
.cvBox .btnBox {
  text-align: center;
  max-width: 80%;
  margin: 45px auto 0;
}
.cvBox .btnBox a {
  text-decoration: none;
  color: #222;
}
.cvBox .btnBox .btn--satei {
  font-size: 2.7rem;
  font-weight: 700;
  color: #fff;
  line-height: 1.4444;
  background: #ff5a25;
  border-radius: 10px;
  box-shadow: 0 5px 0 #9f2b22;
  padding: 31px 40px 33px;
  margin: 100px 0 26px;
  display: block;
  position: relative;
  transition-property: all;
  transition-duration: 0s;
  transition-timing-function: ease-out;
}
@media screen and (min-width: 767px) and (max-width: 1366px) {
  .cvBox .btnBox .btn--satei {
    font-size: clamp(2.2rem, 1.9765739385vw, 2.7rem);
  }
}
.cvBox .btnBox .btn--satei .text {
  font-size: 70%;
  color: #222;
  display: block;
  width: 100%;
  position: absolute;
  top: -50px;
  left: 50%;
  transform: translateX(-50%);
}
.cvBox .btnBox .btn--satei .text .em {
  font-size: 140%;
  color: #ee063c;
}
.cvBox .btnBox .btn--satei img {
  margin: 0 17px 0px 0;
  display: inline-block;
}
@media screen and (min-width: 767px) {
  .cvBox .btnBox .btn--satei:hover {
    box-shadow: none;
    transform: translateY(3px);
  }
}
.cvBox .btnBox .btn--service {
  font-size: 2.7rem;
  font-weight: 700;
  line-height: 1.4444;
  background: #FECB3F;
  border-radius: 10px;
  box-shadow: 0 3px 0 #957725;
  padding: 31px 40px 33px;
  margin: 0 0 26px;
  display: block;
  transition-property: all;
  transition-duration: 0s;
  transition-timing-function: ease-out;
}
@media screen and (min-width: 767px) and (max-width: 1366px) {
  .cvBox .btnBox .btn--service {
    font-size: clamp(2.2rem, 1.9765739385vw, 2.7rem);
  }
}
.cvBox .btnBox .btn--service img {
  margin: 0 17px 0px 0;
  display: inline-block;
}
@media screen and (min-width: 767px) {
  .cvBox .btnBox .btn--service:hover {
    box-shadow: none;
    transform: translateY(3px);
  }
}
.cvBox .btnBox .btn--tel {
  font-family: "Lato", sans-serif;
  font-size: 3.2rem;
  font-weight: 700;
  line-height: 1;
  border-radius: 10px;
  padding: 22px 40px 16px;
  border: 3px solid #D8D8D8;
  background: #FECB3F;
  margin: 0 0 26px;
  display: block;
}
@media screen and (min-width: 767px) {
  .cvBox .btnBox .btn--tel {
    pointer-events: none;
  }
}
@media screen and (min-width: 767px) and (max-width: 1366px) {
  .cvBox .btnBox .btn--tel {
    font-size: clamp(2.7rem, 2.3426061493vw, 3.2rem);
  }
}
.cvBox .btnBox .btn--tel .text {
  font-family: "Noto Sans JP", "源ノ角ゴシック", "Yu Gothic Medium", "游ゴシック Medium", "游ゴシック", YuGothic, YuGothicM, "ヒラギノ角ゴ Pro W3", "Hiragino Kaku Gothic Pro", "メイリオ", Meiryo, "ＭＳ Ｐゴシック", sans-serif;
  font-size: 2.5rem;
  font-weight: 500;
  margin: 0 17px 0 0;
}
@media screen and (min-width: 767px) and (max-width: 1366px) {
  .cvBox .btnBox .btn--tel .text {
    font-size: clamp(2rem, 1.8301610542vw, 2.5rem);
  }
}
.cvBox .btnBox .btn--tel .time {
  font-family: "Noto Sans JP", "源ノ角ゴシック", "Yu Gothic Medium", "游ゴシック Medium", "游ゴシック", YuGothic, YuGothicM, "ヒラギノ角ゴ Pro W3", "Hiragino Kaku Gothic Pro", "メイリオ", Meiryo, "ＭＳ Ｐゴシック", sans-serif;
  font-size: 1.4rem;
  font-weight: 500;
  line-height: 1;
  display: block;
  margin: 9px 0 0;
}
.cvBox .btnBox .btn--tel img {
  width: 39px;
  height: auto;
  margin: 0 17px -5px 0;
  display: inline-block;
}
.cvBox.article__in {
  margin-top: 100px;
}
.cvBox.article__in .btnBox .btn--tel {
  background: #FECB3F;
  font-size: 2.8rem;
}
.cvBox.article__in .btnBox .btn--tel img {
  margin: 0 10px -5px 0;
}
.cvBox.article__in .cvBox__ttl {
  font-size: 4.5rem;
  font-weight: 700;
  margin: 0 0 20px;
}
@media screen and (min-width: 767px) and (max-width: 1366px) {
  .cvBox.article__in .cvBox__ttl {
    font-size: clamp(3.5rem, 3.2942898975vw, 4.5rem);
  }
}
.cvBox.article__in .cvBox__ttl::before {
  content: "";
  width: 12px;
  height: 12px;
  display: inline-block;
  vertical-align: middle;
  border-radius: 50px;
  background: #304A85;
  margin: -5px 15px 0 0;
}
.cvBox.article__in .cvBox__text {
  font-size: 2rem;
  font-weight: 600;
  line-height: 1.75;
}
@media screen and (min-width: 767px) and (max-width: 1366px) {
  .cvBox.article__in .cvBox__text {
    font-size: clamp(1.6rem, 1.317715959vw, 1.8rem);
  }
}

.cvBox--article {
  background: #F5F8FF;
  box-shadow: 0 5px 25px rgba(48, 74, 133, 0.12);
  border: 20px solid #fff;
  border-radius: 10px;
  padding: 50px 50px;
  margin: 100px auto;
}
.cvBox--article .cvBox__inner {
  max-width: 1140px;
  margin: 0 auto;
}
.cvBox--article .cvBox__ttl {
  font-size: 3.5rem;
  font-weight: 700;
  margin: 0 0 40px;
  width: 100%;
  text-align: center;
  background: #304A85;
  color: #fff;
  position: relative;
}
.cvBox--article .cvBox__ttl::after {
  content: "";
  border-style: solid;
  border-right: 12px solid transparent;
  border-left: 12px solid transparent;
  border-top: 20px solid #304A85;
  border-bottom: 0;
  position: absolute;
  bottom: -20px;
  left: 50%;
  transform: translateX(-50%);
}
@media screen and (min-width: 767px) and (max-width: 1366px) {
  .cvBox--article .cvBox__ttl::after {
    border-right: 0.878477306vw solid transparent;
    border-left: 0.878477306vw solid transparent;
    border-top: 1.4641288433vw solid #304A85;
    bottom: -1.317715959vw;
  }
}
.cvBox--article .cvBox__ttl::before {
  content: none;
}
@media screen and (min-width: 767px) and (max-width: 1366px) {
  .cvBox--article .cvBox__ttl {
    font-size: clamp(3rem, 2.5622254758vw, 3.5rem);
  }
}
.cvBox--article .cvBox__text {
  font-size: 2rem;
  font-weight: 600;
  line-height: 1.75;
  position: relative;
  padding-left: 270px;
  min-height: 154px;
}
@media screen and (min-width: 767px) and (max-width: 1366px) {
  .cvBox--article .cvBox__text {
    font-size: clamp(1.6rem, 1.317715959vw, 1.8rem);
    padding-left: 19.7657393851vw;
    min-height: 11.2737920937vw;
  }
}
.cvBox--article .cvBox__text::before {
  content: "";
  width: 230px;
  height: 154px;
  display: inline-block;
  background: url(../img/common/img_cv02.jpg) no-repeat center center;
  background-size: contain;
  position: absolute;
  top: 0;
  left: 0;
}
@media screen and (min-width: 767px) and (max-width: 1366px) {
  .cvBox--article .cvBox__text::before {
    width: 16.8374816984vw;
    height: 11.2737920937vw;
  }
}
.cvBox--article .telBox {
  text-align: center;
  max-width: 80%;
  margin: 45px auto 0;
}
.cvBox--article .telBox__ttl {
  font-size: clamp(1.6rem, 1.6105417277vw, 2.2rem);
  font-weight: 700;
  margin: 0 0 20px;
}
.cvBox--article .telBox .btn {
  font-family: "Lato", sans-serif;
  font-size: 3.2rem;
  font-weight: 700;
  line-height: 1;
  text-decoration: none;
  color: #222;
  border-radius: 10px;
  padding: 22px 40px 16px;
  border: 3px solid #D8D8D8;
  background: #FECB3F;
  margin: 0 0 26px;
  display: block;
}
@media screen and (min-width: 767px) {
  .cvBox--article .telBox .btn {
    pointer-events: none;
  }
}
@media screen and (min-width: 767px) and (max-width: 1366px) {
  .cvBox--article .telBox .btn {
    font-size: clamp(2.7rem, 2.3426061493vw, 3.2rem);
  }
}
.cvBox--article .telBox .btn .text {
  font-family: "Noto Sans JP", "源ノ角ゴシック", "Yu Gothic Medium", "游ゴシック Medium", "游ゴシック", YuGothic, YuGothicM, "ヒラギノ角ゴ Pro W3", "Hiragino Kaku Gothic Pro", "メイリオ", Meiryo, "ＭＳ Ｐゴシック", sans-serif;
  font-size: 2.5rem;
  font-weight: 500;
  margin: 0 17px 0 0;
}
@media screen and (min-width: 767px) and (max-width: 1366px) {
  .cvBox--article .telBox .btn .text {
    font-size: clamp(2rem, 1.8301610542vw, 2.5rem);
  }
}
.cvBox--article .telBox .btn .time {
  font-family: "Noto Sans JP", "源ノ角ゴシック", "Yu Gothic Medium", "游ゴシック Medium", "游ゴシック", YuGothic, YuGothicM, "ヒラギノ角ゴ Pro W3", "Hiragino Kaku Gothic Pro", "メイリオ", Meiryo, "ＭＳ Ｐゴシック", sans-serif;
  font-size: 1.4rem;
  font-weight: 500;
  line-height: 1;
  display: block;
  margin: 9px 0 0;
}
.cvBox--article .telBox .btn img {
  width: 39px;
  height: auto;
  margin: 0 17px -5px 0;
  display: inline-block;
}
.cvBox--article .btnBox {
  text-align: center;
  max-width: 80%;
  margin: 45px auto 0;
}
.cvBox--article .btnBox a {
  text-decoration: none;
  color: #222;
}
.cvBox--article .btnBox .btn--satei {
  font-size: 2.7rem;
  font-weight: 700;
  color: #fff;
  line-height: 1.4444;
  background: #ff5a25;
  border-radius: 10px;
  box-shadow: 0 5px 0 #9f2b22;
  padding: 31px 40px 33px;
  margin: 70px 0 26px;
  display: block;
  position: relative;
  transition-property: all;
  transition-duration: 0s;
  transition-timing-function: ease-out;
}
@media screen and (min-width: 767px) and (max-width: 1366px) {
  .cvBox--article .btnBox .btn--satei {
    font-size: clamp(2.2rem, 1.9765739385vw, 2.7rem);
  }
}
.cvBox--article .btnBox .btn--satei .text {
  font-size: 70%;
  color: #222;
  display: block;
  width: 100%;
  position: absolute;
  top: -50px;
  left: 50%;
  transform: translateX(-50%);
}
.cvBox--article .btnBox .btn--satei .text .em {
  font-size: 140%;
  color: #ee063c;
}
.cvBox--article .btnBox .btn--satei img {
  margin: 0 17px 0px 0;
  display: inline-block;
}
@media screen and (min-width: 767px) {
  .cvBox--article .btnBox .btn--satei:hover {
    box-shadow: none;
    transform: translateY(3px);
  }
}
.cvBox--article .btnBox .btn--service {
  font-size: 2.7rem;
  font-weight: 700;
  line-height: 1.4444;
  background: #FECB3F;
  border-radius: 10px;
  box-shadow: 0 3px 0 #957725;
  padding: 31px 40px 33px;
  margin: 0 0 26px;
  display: block;
  transition-property: all;
  transition-duration: 0s;
  transition-timing-function: ease-out;
}
@media screen and (min-width: 767px) and (max-width: 1366px) {
  .cvBox--article .btnBox .btn--service {
    font-size: clamp(2.2rem, 1.9765739385vw, 2.7rem);
  }
}
.cvBox--article .btnBox .btn--service img {
  margin: 0 17px 0px 0;
  display: inline-block;
}
@media screen and (min-width: 767px) {
  .cvBox--article .btnBox .btn--service:hover {
    box-shadow: none;
    transform: translateY(3px);
  }
}
.cvBox--article .btnBox .btn--tel {
  font-family: "Lato", sans-serif;
  font-size: 3.2rem;
  font-weight: 700;
  line-height: 1;
  border-radius: 10px;
  padding: 22px 40px 16px;
  border: 3px solid #D8D8D8;
  background: #FECB3F;
  margin: 0 0 26px;
  display: block;
}
@media screen and (min-width: 767px) {
  .cvBox--article .btnBox .btn--tel {
    pointer-events: none;
  }
}
@media screen and (min-width: 767px) and (max-width: 1366px) {
  .cvBox--article .btnBox .btn--tel {
    font-size: clamp(2.7rem, 2.3426061493vw, 3.2rem);
  }
}
.cvBox--article .btnBox .btn--tel .text {
  font-family: "Noto Sans JP", "源ノ角ゴシック", "Yu Gothic Medium", "游ゴシック Medium", "游ゴシック", YuGothic, YuGothicM, "ヒラギノ角ゴ Pro W3", "Hiragino Kaku Gothic Pro", "メイリオ", Meiryo, "ＭＳ Ｐゴシック", sans-serif;
  font-size: 2.5rem;
  font-weight: 500;
  margin: 0 17px 0 0;
}
@media screen and (min-width: 767px) and (max-width: 1366px) {
  .cvBox--article .btnBox .btn--tel .text {
    font-size: clamp(2rem, 1.8301610542vw, 2.5rem);
  }
}
.cvBox--article .btnBox .btn--tel .time {
  font-family: "Noto Sans JP", "源ノ角ゴシック", "Yu Gothic Medium", "游ゴシック Medium", "游ゴシック", YuGothic, YuGothicM, "ヒラギノ角ゴ Pro W3", "Hiragino Kaku Gothic Pro", "メイリオ", Meiryo, "ＭＳ Ｐゴシック", sans-serif;
  font-size: 1.4rem;
  font-weight: 500;
  line-height: 1;
  display: block;
  margin: 9px 0 0;
}
.cvBox--article .btnBox .btn--tel img {
  width: 39px;
  height: auto;
  margin: 0 17px -5px 0;
  display: inline-block;
}

@media screen and (min-width: 0) and (max-width: 767px) {
  /*=================================
    cvBox
  =================================*/
  .cvBox {
    background: url(../img/common/bg_cv_sp.png) no-repeat center bottom;
    background-size: cover;
    padding: 0 0 50px;
    margin: 40px 0;
  }
  .cvBox__cont {
    max-width: 84.6153846154%;
    padding: 40px 6.4102564103% 40px 8.4615384615%;
  }
  .cvBox__cont::before {
    content: none;
  }
  .cvBox::after {
    content: none;
  }
  .cvBox__ttl {
    font-size: 2.4rem;
    line-height: 1.3333;
  }
  .cvBox__ttl::before {
    width: 6px;
    height: 6px;
    margin: -5px 3px 0 0;
  }
  .cvBox__text {
    font-size: 1.4rem;
    line-height: 1.714285;
    margin: 11px 0 0;
  }
  .cvBox .telBox {
    max-width: initial;
    width: 125.8974358974%;
    margin: 40px 0 0 -25.8974358974%;
  }
  .cvBox .telBox__ttl {
    font-size: 1.6rem;
    margin: 0 0 10px;
  }
  .cvBox .telBox .btn {
    font-size: 3.1rem;
    border-radius: 5px;
    padding: 16px 8.9743589744%;
    border: 2px solid #D8D8D8;
  }
  .cvBox .telBox .btn .text {
    display: none;
  }
  .cvBox .telBox .btn .time {
    font-size: 1.3rem;
    margin: 0;
  }
  .cvBox .telBox .btn img {
    width: 30px;
    margin: 0 22px -8px 0;
  }
  .cvBox .btnBox {
    max-width: initial;
    width: 125.8974358974%;
    margin: 40px 0 0 -25.8974358974%;
  }
  .cvBox .btnBox a {
    text-align: center;
  }
  .cvBox .btnBox .btn--satei {
    font-size: 2rem;
    border-radius: 5px;
    box-shadow: 0 3px 0 #9f2b22;
    padding: 16px 3.8461538462%;
    margin: 60px 0 20px;
  }
  .cvBox .btnBox .btn--satei .text {
    top: -40px;
  }
  .cvBox .btnBox .btn--service {
    font-size: 2.2rem;
    font-weight: 500;
    line-height: 1.181818;
    border-radius: 5px;
    box-shadow: 0 4px 0 #957725;
    padding: 15px 6.9230769231% 16px;
    margin: 0 0 14px;
  }
  .cvBox .btnBox .btn--service img {
    width: 28px;
    margin: 0 15px -12px 0;
  }
  .cvBox .btnBox .btn--tel {
    font-size: 3.1rem;
    border-radius: 5px;
    padding: 16px 8.9743589744%;
    border: 2px solid #D8D8D8;
  }
  .cvBox .btnBox .btn--tel .text {
    display: none;
  }
  .cvBox .btnBox .btn--tel .time {
    font-size: 1.3rem;
    margin: 0;
  }
  .cvBox .btnBox .btn--tel img {
    width: 30px;
    margin: 0 22px -8px 0;
  }
  .cvBox.article__in {
    margin-top: 70px;
  }
  .cvBox.article__in .cvBox__cont::before {
    content: "";
    width: 28.2051282051vw;
    height: 47.6923076923vw;
    display: inline-block;
    background: url(../img/common/img_cv.png) no-repeat center center;
    background-size: contain;
    position: absolute;
    bottom: -50px;
    left: -28.2051282051%;
    z-index: 1;
  }
  .cvBox.article__in .cvBox__ttl {
    font-size: 2.4rem;
    line-height: 1.3333;
    margin: 0 0 20px;
  }
  .cvBox.article__in .cvBox__ttl::before {
    width: 6px;
    height: 6px;
    margin: -5px 3px 0 0;
  }
  .cvBox.article__in .cvBox__text {
    font-size: 1.4rem;
    line-height: 1.714285;
  }
  .cvBox.article__in .telBox {
    max-width: 100%;
    margin: 30px auto 0;
  }
  .cvBox.article__in .telBox__ttl {
    font-size: 1.6rem;
    margin: 0 0 10px;
  }
  .cvBox.article__in .telBox .btn {
    font-size: 2.2rem;
    border-radius: 5px;
    padding: 16px 3.8461538462%;
    border: 2px solid #D8D8D8;
  }
  .cvBox.article__in .telBox .btn .text {
    font-size: 1.3rem;
    margin: 0 17px 0 0;
  }
  .cvBox.article__in .telBox .btn img {
    width: 30px;
    margin: 0 10px -5px 0;
  }
  .cvBox.article__in .btnBox {
    max-width: 100%;
    margin: 30px auto 0;
  }
  .cvBox.article__in .btnBox .btn--satei {
    font-size: 1.7rem;
    border-radius: 5px;
    box-shadow: 0 3px 0 #9f2b22;
    padding: 16px 3.8461538462%;
    margin: 60px 0 20px;
  }
  .cvBox.article__in .btnBox .btn--satei .text {
    top: -40px;
  }
  .cvBox.article__in .btnBox .btn--service {
    font-size: 1.7rem;
    font-weight: 500;
    line-height: 1.181818;
    border-radius: 5px;
    box-shadow: 0 4px 0 #957725;
    padding: 15px 6.9230769231% 16px;
    margin: 0 0 14px;
  }
  .cvBox.article__in .btnBox .btn--service img {
    width: 28px;
    margin: 0 15px -12px 0;
  }
  .cvBox.article__in .btnBox .btn--tel {
    font-size: 2.2rem;
    border-radius: 5px;
    padding: 16px 8.9743589744%;
    border: 2px solid #D8D8D8;
  }
  .cvBox.article__in .btnBox .btn--tel .text {
    display: none;
  }
  .cvBox.article__in .btnBox .btn--tel .time {
    font-size: 1.3rem;
    margin: 0;
  }
  .cvBox.article__in .btnBox .btn--tel img {
    width: 30px;
    margin: 0 22px -8px 0;
  }
  .cvBox--article {
    border: 10px solid #fff;
    border-radius: 8px;
    padding: 30px 5.1282051282%;
    margin: 50px auto;
  }
  .cvBox--article .cvBox__ttl {
    font-size: 1.9rem;
    line-height: 1.3333;
    margin: 0 0 20px;
    padding: 10px 3.8461538462%;
  }
  .cvBox--article .cvBox__ttl::after {
    border-right: 8px solid transparent;
    border-left: 8px solid transparent;
    border-top: 12px solid #304A85;
    bottom: -12px;
  }
  .cvBox--article .cvBox__text {
    font-size: 1.4rem;
    line-height: 1.714285;
    padding-left: 0;
    min-height: inherit;
  }
  .cvBox--article .cvBox__text::before {
    width: 100%;
    height: 39.4871794872vw;
    background-size: cover;
    background-position: center top;
    display: block;
    margin: 0 auto 30px;
    position: static;
  }
  .cvBox--article .telBox {
    max-width: 100%;
    margin: 30px auto 0;
  }
  .cvBox--article .telBox__ttl {
    font-size: 1.6rem;
    margin: 0 0 10px;
  }
  .cvBox--article .telBox .btn {
    font-size: 2.8rem;
    border-radius: 5px;
    padding: 16px 3.8461538462%;
    border: 2px solid #D8D8D8;
  }
  .cvBox--article .telBox .btn .text {
    font-size: 1.3rem;
    margin: 0 17px 0 0;
  }
  .cvBox--article .telBox .btn img {
    width: 30px;
    margin: 0 10px -5px 0;
  }
  .cvBox--article .btnBox {
    max-width: 100%;
    margin: 30px auto 0;
  }
  .cvBox--article .btnBox .btn--satei {
    font-size: 2rem;
    border-radius: 5px;
    box-shadow: 0 3px 0 #9f2b22;
    padding: 16px 3.8461538462%;
    margin: 60px 0 20px;
  }
  .cvBox--article .btnBox .btn--satei .text {
    top: -40px;
  }
  .cvBox--article .btnBox .btn--service {
    font-size: 2.2rem;
    font-weight: 500;
    line-height: 1.181818;
    border-radius: 5px;
    box-shadow: 0 4px 0 #957725;
    padding: 15px 6.9230769231% 16px;
    margin: 0 0 14px;
  }
  .cvBox--article .btnBox .btn--service img {
    width: 28px;
    margin: 0 15px -12px 0;
  }
  .cvBox--article .btnBox .btn--tel {
    font-size: 3.1rem;
    border-radius: 5px;
    padding: 16px 8.9743589744%;
    border: 2px solid #D8D8D8;
  }
  .cvBox--article .btnBox .btn--tel .text {
    display: none;
  }
  .cvBox--article .btnBox .btn--tel .time {
    font-size: 1.3rem;
    margin: 0;
  }
  .cvBox--article .btnBox .btn--tel img {
    width: 30px;
    margin: 0 22px -8px 0;
  }
}
/*=================================
  sateiBox
=================================*/
.sateiBox {
  padding: 20px;
  margin: 85px 0 100px;
  border-radius: 20px;
  background: #fff;
  background-size: contain;
  box-shadow: 0 5px 25px rgba(48, 74, 133, 0.12);
}
.sateiBox__inner {
  border-radius: 10px;
  background: #F5F8FF;
  padding: 50px 40px;
  position: relative;
}
.sateiBox__inner::after {
  content: "";
  width: 242px;
  height: 385px;
  display: inline-block;
  background: url(../img/common/img_sateiBox.png) no-repeat center center;
  background-size: contain;
  position: absolute;
  right: 0;
  bottom: 0;
}
@media screen and (min-width: 767px) and (max-width: 1366px) {
  .sateiBox__inner::after {
    width: 17.7159590044vw;
    height: 28.1844802343vw;
  }
}
.sateiBox__ttl {
  font-size: 4.5rem;
  font-weight: 700;
  line-height: 1.25;
  padding-left: 27px;
  position: relative;
}
.sateiBox__ttl::before {
  content: "";
  width: 12px;
  height: 12px;
  display: inline-block;
  vertical-align: middle;
  border-radius: 50px;
  background: #304A85;
  position: absolute;
  top: 25px;
  left: 0;
}
.sateiBox__text {
  font-size: 1.6rem;
  line-height: 2;
  margin: 7px 0 0;
}
.sateiBox__form {
  margin: 10px 0 0;
}
.sateiBox__form form {
  display: flex;
  flex-wrap: wrap;
  position: relative;
  z-index: 1;
}
.sateiBox__form .selectWrap {
  display: flex;
  flex-wrap: wrap;
  max-width: 550px;
}
@media screen and (min-width: 767px) and (max-width: 1366px) {
  .sateiBox__form .selectWrap {
    max-width: 500px;
  }
}
.sateiBox__form .selectWrap .design-select-box {
  font-size: 1.6rem;
  border: none;
  background: url(../img/common/icon/icon_select_down.svg) no-repeat right 22px center #fff;
  background-size: 14px auto;
  border-radius: 5px;
  padding: 14px 40px 14px 20px;
  box-shadow: 0 2px 1px rgba(0, 0, 0, 0.16);
  width: 255px;
  margin: 20px 20px 0 0;
  cursor: pointer;
  appearance: none;
}
@media screen and (min-width: 767px) and (max-width: 1366px) {
  .sateiBox__form .selectWrap .design-select-box {
    width: 230px;
  }
}
.sateiBox__form .btn {
  font-size: 2.1rem;
  font-weight: 700;
  background: #FECB3F;
  border-radius: 10px;
  box-shadow: 0 3px 0 #957725;
  padding: 46px 24px 52px;
  margin: 20px 0 0;
  position: relative;
  cursor: pointer;
  width: 240px;
  text-align: center;
}
@media screen and (min-width: 767px) {
  .sateiBox__form .btn:hover {
    box-shadow: none;
    transform: translateY(3px);
  }
}
.sateiBox__form .btn::before {
  content: "";
  width: 31px;
  height: 31px;
  display: inline-block;
  background: url(../img/common/icon/icon_home.svg) no-repeat center center;
  background-size: contain;
  vertical-align: middle;
  margin: -10px 14px 0 0;
}

.sateiBoxWrap {
  background: #F5F8FF;
  padding: 100px 1%;
  text-align: center;
}
.sateiBoxWrap__ttl {
  font-size: 4.5rem;
  font-weight: 700;
  line-height: 1.25;
  padding-left: 27px;
}
.sateiBoxWrap__ttl::before {
  content: "";
  width: 12px;
  height: 12px;
  display: inline-block;
  vertical-align: middle;
  border-radius: 50px;
  background: #304A85;
  margin: -10px 15px 0 0;
}
.sateiBoxWrap .sateiBox {
  max-width: 1140px;
  margin: 65px auto 0;
  padding: 30px;
}
.sateiBoxWrap .sateiBox__inner {
  border-radius: 15px;
  padding: 30px;
}
.sateiBoxWrap .sateiBox__inner::after {
  right: 8px;
  bottom: -30px;
}
.sateiBoxWrap .sateiBox__ttl {
  font-size: 2.5rem;
  color: #fff;
  line-height: 1.2;
  padding: 12px;
  background: #304A85;
  border-radius: 20px 20px 0 0;
  width: 50%;
  position: absolute;
  top: -70px;
  left: -30px;
}
.sateiBoxWrap .sateiBox__ttl::before {
  content: none;
}
.sateiBoxWrap .sateiBox__text {
  font-size: 1.8rem;
  font-weight: 700;
  text-align: left;
  line-height: 1.77777;
  margin: 0 0 7px;
}
.sateiBoxWrap .sateiBox__form {
  margin: 10px 0 0;
}

/*=================================
  zeroensatei
=================================*/
.zeroensatei {
  margin: 65px auto 0;
  padding: 30px;
  border-radius: 20px;
  background: #fff;
  background-size: contain;
  box-shadow: 0 5px 25px rgba(48, 74, 133, 0.12);
  max-width: 1140px;
}
.zeroensatei__inner {
  border-radius: 15px;
  background: #F5F8FF;
  padding: 30px;
  position: relative;
}
.zeroensatei__inner::after {
  content: "";
  width: 242px;
  height: 385px;
  display: inline-block;
  background: url(../img/common/img_sateiBox.png) no-repeat center center;
  background-size: contain;
  position: absolute;
  right: 0;
  bottom: -30px;
}
@media screen and (min-width: 767px) and (max-width: 1366px) {
  .zeroensatei__inner::after {
    width: 17.7159590044vw;
    height: 28.1844802343vw;
  }
}
.zeroensatei__ttl {
  font-size: 2.5rem;
  font-weight: 700;
  line-height: 1.2;
  color: #fff;
  padding: 12px;
  background: #304A85;
  border-radius: 20px 20px 0 0;
  width: 50%;
  position: absolute;
  top: -70px;
  left: -30px;
  text-align: center;
}
.zeroensatei__text {
  font-size: 2.2rem;
  font-weight: 700;
  line-height: 1.77777;
  margin: 0 0 20px;
  text-align: center;
}
.zeroensatei__text .lg {
  font-size: 120%;
  background: linear-gradient(transparent 50%, #fff932 50%);
  color: #fc2828;
  padding: 0 0.25em;
  margin: 0 0.5em;
}
.zeroensatei__img img {
  margin: 0;
}
.zeroensatei__form {
  margin: 10px 0 0;
}
.zeroensatei__form .btnWrap {
  margin: 0;
}
.zeroensatei__form .btn {
  font-size: 3rem;
  font-weight: 700;
  background: #FECB3F;
  border-radius: 10px;
  color: #222;
  box-shadow: 0 3px 0 #957725;
  padding: 26px 24px 32px;
  margin: 20px auto 0;
  position: relative;
  cursor: pointer;
  text-decoration: none;
  width: 100%;
  max-width: 600px;
  text-align: center;
}
@media screen and (min-width: 767px) {
  .zeroensatei__form .btn:hover {
    box-shadow: none;
    transform: translateY(3px);
  }
}
.zeroensatei__form .btn::before {
  content: "";
  width: 31px;
  height: 31px;
  display: inline-block;
  background: url(../img/common/icon/icon_home.svg) no-repeat center center;
  background-size: contain;
  vertical-align: middle;
  margin: -10px 14px 0 0;
}

.zeroensateiWrap {
  margin: 80px 0 50px;
}
.zeroensateiWrap__ttl {
  font-size: 4.5rem;
  font-weight: 700;
  line-height: 1.25;
  margin-bottom: 85px;
  text-align: center;
}
.zeroensateiWrap__ttl::before {
  content: "";
  width: 12px;
  height: 12px;
  display: inline-block;
  vertical-align: middle;
  border-radius: 50px;
  background: #304A85;
  margin: -10px 15px 0 0;
}

.cvbtnBox {
  padding: 30px 30px;
  margin: 50px 0;
  background: #F5F8FF;
  position: relative;
  text-align: center;
  box-shadow: 0 3px 20px rgba(48, 74, 133, 0.12);
}
.cvbtnBox__text {
  font-size: 2.4rem;
  font-weight: 600;
  line-height: 1.5;
  background: #fff;
  display: inline-block;
  border-radius: 5px;
  padding: 10px 30px;
  animation: bound 2.5s ease-in-out 0s infinite alternate;
}
.cvbtnBox__text .em {
  font-size: 3rem;
  font-weight: 700;
  color: #ee063c;
}
@keyframes bound {
  0% {
    transform: scale(1);
  }
  89% {
    transform: scale(1);
  }
  90% {
    transform: scale(1.03);
  }
  95% {
    transform: scale(0.98);
  }
  100% {
    transform: scale(1);
  }
}
.cvbtnBox__link {
  font-size: 3rem;
  font-weight: 700;
  background: #FECB3F;
  border-radius: 5px;
  color: #222;
  box-shadow: 0 3px 0 #957725;
  padding: 16px 24px 22px;
  margin: 20px auto 0;
  position: relative;
  cursor: pointer;
  text-decoration: none;
  width: 100%;
  max-width: 600px;
  display: block;
  text-align: center;
}
@media screen and (min-width: 767px) {
  .cvbtnBox__link:hover {
    box-shadow: none;
    transform: translateY(3px);
  }
}
.cvbtnBox__link::before {
  content: "";
  width: 31px;
  height: 31px;
  display: inline-block;
  background: url(../img/common/icon/icon_home.svg) no-repeat center center;
  background-size: contain;
  vertical-align: middle;
  margin: -10px 14px 0 0;
}

@media screen and (min-width: 0) and (max-width: 767px) {
  /*=================================
    sateiBox
  =================================*/
  .articleDetail__in .sateiBox {
    width: 117.4358974359%;
    margin-left: -9.2307692308% !important;
  }
  .sateiBox {
    border-radius: 0;
    background: #F5F8FF;
    padding: 40px 0 40px 7.6923076923%;
    box-shadow: none;
    margin: 40px 0;
  }
  .sateiBox__inner {
    border-radius: 0;
    padding: 0;
  }
  .sateiBox__inner::after {
    content: none;
  }
  .sateiBox__ttl {
    font-size: 2.4rem;
    line-height: 1.08;
    padding-left: 9px;
    padding-right: 7.6923076923%;
  }
  .sateiBox__ttl::before {
    width: 6px;
    height: 6px;
    top: 10px;
  }
  .sateiBox__text {
    font-size: 1.4rem;
    line-height: 1.71428;
    margin: 11px 0 0;
    padding-right: 7.6923076923%;
  }
  .sateiBox__form {
    margin: 15px 0 0;
  }
  .sateiBox__form form {
    display: block;
  }
  .sateiBox__form .selectWrap {
    display: block;
    background: url(../img/common/img_sateiBox.png) no-repeat right bottom;
    background-size: 173px auto;
    max-width: initial;
  }
  .sateiBox__form .selectWrap .design-select-box {
    font-size: 1.7rem;
    padding: 16px 20px;
    max-width: 70%;
    width: 100%;
    margin: 15px 0 0 0;
  }
  .sateiBox__form .btn {
    border-radius: 5px;
    box-shadow: 0 4px 0 #957725;
    padding: 22px 2.5641025641% 24px;
    width: 92.3076923077%;
    margin: 20px auto 0;
  }
  .sateiBoxWrap {
    padding: 40px 0;
    margin: 40px 0 0;
    text-align: left;
  }
  .sateiBoxWrap__ttl {
    font-size: 2.4rem;
    line-height: 1.08;
    padding-left: 9px;
    padding-right: 7.6923076923%;
    margin-left: 7.6923076923%;
    position: relative;
  }
  .sateiBoxWrap__ttl::before {
    width: 6px;
    height: 6px;
    display: inline-block;
    vertical-align: middle;
    border-radius: 50px;
    background: #304A85;
    position: absolute;
    top: 20px;
    left: 0;
  }
  .sateiBoxWrap .sateiBox {
    margin: 30px auto 0;
    padding: 0;
  }
  .sateiBoxWrap .sateiBox__inner {
    border-radius: 0;
    padding: 0 0 0 7.6923076923%;
  }
  .sateiBoxWrap .sateiBox__ttl {
    font-size: 2rem;
    border-radius: 0;
    width: 108.2051282051%;
    margin-left: -8.2051282051%;
    position: static;
  }
  .sateiBoxWrap .sateiBox__ttl::before {
    content: none;
  }
  .sateiBoxWrap .sateiBox__text {
    font-size: 1.4rem;
    font-weight: 500;
    line-height: 1.714285;
    margin: 20px 0 0;
  }
  /*=================================
    zeroensatei
  =================================*/
  .articleDetail__in .zeroensateiWrap {
    width: 117.4358974359%;
    margin-left: -9.2307692308% !important;
  }
  .zeroensatei {
    margin: 30px auto 0;
    padding: 0 0 40px;
    border-radius: 0;
    background: #F5F8FF;
    box-shadow: none;
  }
  .zeroensatei__inner {
    border-radius: 0;
    padding: 0;
  }
  .zeroensatei__inner::after {
    width: 121px;
    height: 192px;
    bottom: -40px;
  }
  .zeroensatei__ttl {
    font-size: 2rem;
    line-height: 1.08;
    padding-left: 9px;
    padding-right: 7.6923076923%;
    border-radius: 0;
    width: 108.2051282051%;
    margin-left: -8.2051282051%;
    position: static;
  }
  .zeroensatei__text {
    font-size: 1.6rem;
    font-weight: 500;
    line-height: 1.714285;
    margin: 20px 0 0;
  }
  .zeroensatei__text .lg {
    margin: 0 0.25em;
  }
  .zeroensatei__img {
    width: 90%;
    margin: 0 auto;
  }
  .zeroensatei__img img {
    margin: 0;
  }
  .zeroensatei__form {
    margin: 15px 0 0;
    padding: 0 3.8461538462%;
  }
  .zeroensatei__form .btn {
    font-size: 2.2rem;
    border-radius: 5px;
    box-shadow: 0 4px 0 #957725;
    padding: 22px 3.8461538462% 24px;
    margin: 45px 0 0;
    display: block;
    width: 79%;
  }
  .zeroensatei__form .btn::before {
    width: 28px;
    height: 28px;
    margin: -10px 8px 0 0;
  }
  .zeroensateiWrap {
    margin: 40px 0;
  }
  .zeroensateiWrap__ttl {
    font-size: 2.4rem;
    line-height: 1.08;
    margin-bottom: 30px;
  }
  .zeroensateiWrap__ttl::before {
    width: 6px;
    height: 6px;
    margin: -7px 5px 0 0;
  }
  .cvbtnBox {
    width: 117.4358974359%;
    margin: 40px 0 40px -9.2307692308%;
    padding: 30px 9.2307692308%;
    box-shadow: none;
  }
  .cvbtnBox__text {
    font-size: 2rem;
    padding: 10px 15px;
  }
  .cvbtnBox__text .em {
    font-size: 2.8rem;
  }
  .cvbtnBox__link {
    font-size: 2.5rem;
    border-radius: 5px;
    box-shadow: 0 4px 0 #957725;
    padding: 22px 7.6923076923% 24px;
    margin: 20px auto 0;
  }
}
/*=================================
  ikkatsuBox
=================================*/
.ikkatsuBox {
  margin: 100px 0;
}
.ikkatsuBox__ttl {
  font-size: 4.5rem;
  font-weight: 700;
  line-height: 1.25;
  padding-left: 27px;
  position: relative;
}
.ikkatsuBox__ttl::before {
  content: "";
  width: 12px;
  height: 12px;
  display: inline-block;
  vertical-align: middle;
  border-radius: 50px;
  background: #304A85;
  position: absolute;
  top: 25px;
  left: 0;
}
.ikkatsuBox__text {
  font-size: 1.6rem;
  line-height: 2;
  margin: 7px 0 0;
}
.ikkatsuBox__cont {
  border: 5px solid #F5F8FF;
  border-radius: 10px;
  background: #fff;
  padding: 35px 7%;
  margin: 40px 0 0;
}
.ikkatsuBox__cont .contWrap {
  display: flex;
  justify-content: space-between;
}
.ikkatsuBox__cont .contWrap__item {
  width: 46%;
  text-align: center;
}
.ikkatsuBox__cont .contWrap__item:nth-child(2n) {
  position: relative;
}
.ikkatsuBox__cont .contWrap__item:nth-child(2n)::before {
  content: "";
  width: 1px;
  height: 95%;
  display: block;
  border-left: 1px dashed #D4D4D4;
  position: absolute;
  top: 2.5%;
  left: -9%;
}
.ikkatsuBox__cont .contWrap__ttl {
  font-size: 1.8rem;
  font-weight: 700;
  color: #fff;
  background: #304A85;
  text-align: center;
  border-radius: 10px 10px 0 0;
  width: 88%;
  padding: 12px 0;
  margin: 0 auto 22px;
}
.ikkatsuBox__cont .contWrap__text {
  font-size: clamp(1.8rem, 1.4641288433vw, 2rem);
  font-weight: 500;
  line-height: 1.75;
  margin: 0 0 20px;
}
.ikkatsuBox__cont .contWrap__conclusion {
  font-size: clamp(2rem, 1.756954612vw, 2.4rem);
  font-weight: 700;
  line-height: 1.5714;
  letter-spacing: 0.1em;
  margin: 35px 0 0;
}
.ikkatsuBox__cont .contWrap__conclusion .em {
  color: #FC2828;
  background: linear-gradient(transparent 82%, #FFDFDF 82%);
  padding: 0 5px 5px;
}
.ikkatsuBox__cont .btnWrap {
  margin: 40px 0 0;
}

.ikkatsuBoxWrap {
  background: url(../img/common/bg_ikkatsuBox.jpg) no-repeat center top;
  background-size: cover;
  padding: 100px 1%;
}
.ikkatsuBoxWrap .ikkatsuBox {
  max-width: 1140px;
  margin: 0 auto;
}
.ikkatsuBoxWrap .ikkatsuBox__ttl {
  color: #fff;
}
.ikkatsuBoxWrap .ikkatsuBox__ttl::before {
  background: #fff;
}
.ikkatsuBoxWrap .ikkatsuBox__text {
  color: #fff;
  margin: 12px 0 0;
}

@media screen and (min-width: 0) and (max-width: 767px) {
  /*=================================
    ikkatsuBox
  =================================*/
  .ikkatsuBox {
    margin: 40px 0;
  }
  .ikkatsuBox__ttl {
    font-size: 2.4rem;
    line-height: 1.083333;
    padding: 0 2.5641025641% 0 5.1282051282%;
  }
  .ikkatsuBox__ttl::before {
    width: 6px;
    height: 6px;
    top: 12px;
    left: 2.5641025641%;
  }
  .ikkatsuBox__text {
    font-size: 1.4rem;
    line-height: 1.71428;
    margin: 13px 0 0;
    padding: 0 2.5641025641%;
  }
  .ikkatsuBox__cont {
    border: none;
    padding: 0;
    margin: 20px 0 0;
  }
  .ikkatsuBox__cont .contWrap {
    display: block;
  }
  .ikkatsuBox__cont .contWrap__item {
    width: 100%;
    border: 3px solid #F5F8FF;
    border-radius: 10px;
    padding: 30px 2.5641025641%;
  }
  .ikkatsuBox__cont .contWrap__item:nth-child(2n) {
    margin: 20px 0 0;
  }
  .ikkatsuBox__cont .contWrap__item:nth-child(2n)::before {
    content: none;
  }
  .ikkatsuBox__cont .contWrap__ttl {
    font-size: 1.6rem;
    letter-spacing: 0.09em;
    line-height: 1.5;
    width: 85%;
    padding: 8px 0;
    margin: 0 auto 12px;
  }
  .ikkatsuBox__cont .contWrap__text {
    font-size: 1.6rem;
    line-height: 1.3125;
    margin: 0 0 17px;
  }
  .ikkatsuBox__cont .contWrap__conclusion {
    font-size: 1.9rem;
    line-height: 1.59;
    margin: 17px 0 0;
  }
  .ikkatsuBox__cont .btnWrap {
    margin: 20px 0 0;
  }
  .ikkatsuBoxWrap {
    background: none;
    padding: 40px 7.6923076923%;
  }
  .ikkatsuBoxWrap .ikkatsuBox__ttl {
    color: #222;
  }
  .ikkatsuBoxWrap .ikkatsuBox__ttl::before {
    background: #304A85;
  }
  .ikkatsuBoxWrap .ikkatsuBox__text {
    color: #222;
    margin: 12px 0 0;
  }
}
/*=================================
  area
=================================*/
.areaInfo {
  background: #F5F8FF;
  padding: 86px 30px 46px;
}
.areaInfo__inner {
  max-width: 1140px;
  margin: 0 auto;
  padding: 50px 30px 30px;
  border-radius: 20px;
  background: #fff;
  background-size: contain;
  box-shadow: 0 5px 25px rgba(48, 74, 133, 0.12);
  position: relative;
}
.areaInfo__inner .areaList {
  padding: 30px 30px 30px;
  border-radius: 15px;
  background: #F5F8FF;
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr 1fr;
  gap: 20px;
}
.areaInfo__inner .areaList__item {
  text-align: center;
  font-size: 1.8rem;
  overflow: hidden;
  position: relative;
}
@media screen and (min-width: 767px) {
  .areaInfo__inner .areaList__item:hover::after {
    content: "";
    width: 100%;
    height: 100%;
    display: block;
    background: rgba(0, 0, 0, 0.5);
    position: absolute;
    top: 0;
    left: 0;
    z-index: 1;
  }
  .areaInfo__inner .areaList__item:hover a {
    transform: translateY(0);
  }
}
.areaInfo__inner .areaList__item::before {
  content: "≫";
  display: inline-block;
  color: #fff;
  position: absolute;
  top: 50%;
  right: 15px;
  transform: translateY(-50%);
  z-index: 2;
}
.areaInfo__inner .areaList__item::after {
  content: "";
  width: 100%;
  height: 100%;
  display: block;
  background: rgb(37, 60, 112);
  background: -moz-linear-gradient(90deg, rgba(37, 60, 112, 0.7959558824) 0%, rgba(6, 13, 29, 0.8) 100%);
  background: -webkit-linear-gradient(90deg, rgba(37, 60, 112, 0.7959558824) 0%, rgba(6, 13, 29, 0.8) 100%);
  background: linear-gradient(90deg, rgba(37, 60, 112, 0.7959558824) 0%, rgba(6, 13, 29, 0.8) 100%);
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#253c70",endColorstr="#060d1d",GradientType=1);
  position: absolute;
  top: 0;
  left: 0;
  z-index: 1;
}
.areaInfo__inner .areaList__item .img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  position: absolute;
  top: 0;
  left: 0;
  z-index: 0;
}
.areaInfo__inner .areaList__item a {
  font-weight: 500;
  color: #fff;
  background: transparent;
  box-shadow: none;
  padding: 10px;
  overflow: hidden;
  text-decoration: none;
  border-radius: 5px;
  display: block;
  position: relative;
  z-index: 2;
}
.areaInfo__ttl {
  position: absolute;
  top: -30px;
  left: 0px;
  width: 50%;
  padding: 12px;
  border-radius: 20px 20px 0 0;
  background: #304A85;
  font-size: 3rem;
  font-weight: 700;
  line-height: 1.2;
  letter-spacing: 0.1em;
  text-align: center;
  color: #fff;
}
.areaInfo__text {
  font-size: 1.7rem;
  padding: 20px 0 30px;
}

.mv + .areaInfo {
  background: rgb(245, 244, 240);
  background: -moz-linear-gradient(90deg, rgb(245, 244, 240) 0%, rgb(245, 248, 255) 100%);
  background: -webkit-linear-gradient(90deg, rgb(245, 244, 240) 0%, rgb(245, 248, 255) 100%);
  background: linear-gradient(90deg, rgb(245, 244, 240) 0%, rgb(245, 248, 255) 100%);
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#dceeff",endColorstr="#f5f8ff",GradientType=1);
  padding: 40px 1% 80px;
  margin-top: -100px;
}
.mv + .areaInfo .areaInfo__inner {
  max-width: 1140px;
  margin: 0 auto;
  padding: 40px 30px;
  border-radius: 0;
  background: #fff;
  background-size: contain;
  box-shadow: 0 5px 25px rgba(48, 74, 133, 0.12);
  position: relative;
}
.mv + .areaInfo .areaInfo__inner .areaList {
  padding: 40px 30px 0;
  border-radius: 0;
  background: transparent;
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr 1fr;
  gap: 20px;
}
.mv + .areaInfo .areaInfo__inner .areaList__item {
  text-align: center;
  font-size: 1.8rem;
  overflow: hidden;
  position: relative;
}
@media screen and (min-width: 767px) {
  .mv + .areaInfo .areaInfo__inner .areaList__item:hover::after {
    content: "";
    width: 100%;
    height: 100%;
    display: block;
    background: rgba(0, 0, 0, 0.5);
    position: absolute;
    top: 0;
    left: 0;
    z-index: 1;
  }
  .mv + .areaInfo .areaInfo__inner .areaList__item:hover a {
    transform: translateY(0);
  }
}
.mv + .areaInfo .areaInfo__inner .areaList__item::before {
  content: "≫";
  display: inline-block;
  color: #fff;
  position: absolute;
  top: 50%;
  right: 15px;
  transform: translateY(-50%);
  z-index: 2;
}
.mv + .areaInfo .areaInfo__inner .areaList__item::after {
  content: "";
  width: 100%;
  height: 100%;
  display: block;
  background: rgb(37, 60, 112);
  background: -moz-linear-gradient(90deg, rgba(37, 60, 112, 0.7959558824) 0%, rgba(6, 13, 29, 0.8) 100%);
  background: -webkit-linear-gradient(90deg, rgba(37, 60, 112, 0.7959558824) 0%, rgba(6, 13, 29, 0.8) 100%);
  background: linear-gradient(90deg, rgba(37, 60, 112, 0.7959558824) 0%, rgba(6, 13, 29, 0.8) 100%);
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#253c70",endColorstr="#060d1d",GradientType=1);
  position: absolute;
  top: 0;
  left: 0;
  z-index: 1;
}
.mv + .areaInfo .areaInfo__inner .areaList__item .img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  position: absolute;
  top: 0;
  left: 0;
  z-index: 0;
}
.mv + .areaInfo .areaInfo__inner .areaList__item a {
  font-weight: 500;
  color: #fff;
  background: transparent;
  box-shadow: none;
  padding: 10px;
  overflow: hidden;
  border-radius: 5px;
  overflow: hidden;
  display: block;
  position: relative;
  z-index: 2;
}
.mv + .areaInfo .areaInfo__ttl {
  position: static;
  padding: 0;
  width: initial;
  border-radius: 0;
  background: transparent;
  font-size: 4.5rem;
  font-weight: 700;
  color: #222;
  line-height: 1.5;
  text-align: left;
}
.mv + .areaInfo .areaInfo__ttl::before {
  content: "";
  width: 12px;
  height: 12px;
  display: inline-block;
  border-radius: 50px;
  background: #304A85;
  vertical-align: middle;
  margin: -5px 15px 0 0;
}
.mv + .areaInfo .areaInfo__text {
  padding: 30px 30px 0;
}

.regionInfo {
  margin: 120px auto 80px;
  background: #F5F8FF;
  padding: 50px 30px 30px;
  position: relative;
}
.regionInfo .regionList {
  padding: 30px 30px 30px;
  border-radius: 15px;
  background: #fff;
}
.regionInfo .regionList__item {
  display: inline-block;
  text-align: center;
  font-size: 1.6rem;
  margin: 8px 2px;
}
@media screen and (min-width: 767px) {
  .regionInfo .regionList__item:hover a {
    box-shadow: none;
    transform: translateY(3px);
  }
}
.regionInfo .regionList__item a {
  color: #222;
  text-decoration: none;
  background: #FECB3F;
  box-shadow: 0 3px 0 #957725;
  padding: 0px 10px;
  border-radius: 5px;
  display: block;
  transition-property: all;
  transition-duration: 0s;
  transition-timing-function: ease-out;
}
.regionInfo .regionList__item span {
  background: #e4e4e4;
  box-shadow: 0 3px 0 #b8b8b8;
  padding: 0px 10px;
  border-radius: 5px;
  display: block;
}
.regionInfo__ttl {
  position: absolute;
  width: 23em;
  top: -40px;
  left: 50%;
  transform: translateX(-50%);
  padding: 12px;
  border-radius: 20px 20px 0 0;
  background: #304A85;
  font-size: 3rem;
  font-weight: 700;
  line-height: 1.2;
  letter-spacing: 0.1em;
  text-align: center;
  color: #fff;
}
.regionInfo__text {
  font-size: 1.7rem;
  padding: 20px 0 30px;
}

@media screen and (min-width: 0) and (max-width: 767px) {
  /*=================================
    area
  =================================*/
  .areaInfo {
    padding: 70px 4.358974359% 40px;
  }
  .areaInfo__inner {
    padding: 30px 4.358974359% 30px;
    border-radius: 10px;
    box-shadow: 0 3px 15px rgba(48, 74, 133, 0.05);
  }
  .areaInfo__inner .areaList {
    padding: 20px 3.0769230769%;
    border-radius: 10px;
    display: block;
  }
  .areaInfo__inner .areaList__item {
    font-size: 1.5rem;
    display: inline-block;
    margin: 5px 3px;
    vertical-align: bottom;
  }
  .areaInfo__inner .areaList__item::before {
    font-size: 1.2rem;
    right: 8px;
  }
  .areaInfo__inner .areaList__item a {
    border-radius: 0;
    padding: 10px 30px 10px 12px;
  }
  .areaInfo__inner .areaList__item span {
    padding: 5px 8px;
    border-radius: 3px;
  }
  .areaInfo__ttl {
    width: 100%;
    padding: 8px;
    border-radius: 10px 10px 0 0;
    background: #304A85;
    font-size: 1.8rem;
    line-height: 1.5;
  }
  .areaInfo__text {
    font-size: 1.4rem;
    line-height: 1.71428;
    padding: 8px 0 12px;
  }
  .mv + .areaInfo {
    padding: 50px 4.358974359%;
    margin-top: 0;
  }
  .mv + .areaInfo .areaInfo__inner {
    padding: 30px 4.358974359%;
    border-radius: 0;
    background: #fff;
    background-size: contain;
    box-shadow: 0 5px 25px rgba(48, 74, 133, 0.12);
    position: relative;
  }
  .mv + .areaInfo .areaInfo__inner .areaList {
    padding: 20px 3.0769230769% 0;
    border-radius: 0;
    display: block;
  }
  .mv + .areaInfo .areaInfo__inner .areaList__item {
    font-size: 1.5rem;
  }
  .mv + .areaInfo .areaInfo__inner .areaList__item::before {
    font-size: 1.2rem;
    right: 8px;
  }
  .mv + .areaInfo .areaInfo__inner .areaList__item a {
    padding: 10px 30px 10px 12px;
    border-radius: 0;
  }
  .mv + .areaInfo .areaInfo__ttl {
    font-size: 2.4rem;
    line-height: 1.083333;
  }
  .mv + .areaInfo .areaInfo__ttl::before {
    width: 6px;
    height: 6px;
    margin: -5px 8px 0 0;
  }
  .mv + .areaInfo .areaInfo__text {
    padding: 20px 3.5897435897% 0;
  }
  .regionInfo {
    margin: 70px 5.1282051282% 50px;
    padding: 20px 4.358974359%;
  }
  .regionInfo .regionList {
    padding: 20px 4.358974359%;
    border-radius: 10px;
  }
  .regionInfo .regionList__item {
    font-size: 1.5rem;
    margin: 8px 2px;
  }
  .regionInfo .regionList__item a {
    padding: 0px 8px;
    border-radius: 2px;
  }
  .regionInfo .regionList__item span {
    padding: 0px 8px;
    border-radius: 2px;
  }
  .regionInfo__ttl {
    width: 100%;
    top: -40px;
    left: 0;
    transform: translateX(0);
    padding: 8px;
    border-radius: 10px 10px 0 0;
    font-size: 1.8rem;
    line-height: 1.5;
  }
  .regionInfo__text {
    font-size: 1.4rem;
    line-height: 1.71428;
    padding: 8px 0 12px;
  }
}
@use "../global" as *;
/*=================================
  category
=================================*/
.category {
  background: url(../img/common/bg_category_pc.jpg) no-repeat center top;
  background-size: cover;
  padding: 100px 0 0;
  margin: 0 0 100px;
  position: relative;
  &::after {
    content: "";
    width: 100%;
    height: 112px;
    display: block;
    background: #fff;
    position: absolute;
    left: 0;
    bottom: 0;
    z-index: 0;
  }
  &__ttl {
    color: #fff;
    text-align: center;
    .ttl {
      font-size: 3.6rem;
      font-weight: 700;
      line-height: 1.5;
      margin: 0 0 56px;
      &::before {
        content: "";
        width: 10px;
        height: 10px;
        display: inline-block;
        vertical-align: middle;
        border-radius: 50px;
        background: #fff;
        margin: -5px 12px 0 0;
      }
    }
    .text {
      font-size: 1.6rem;
      line-height: 2;
      margin: 7px 0 0;
    }
  }
  &Wrap {
    background: $color-theme;
    padding: 55px 80px 70px;
    max-width: $base-width;
    margin: 0 auto;
    position: relative;
    z-index: 1;
  }
  &List {
    max-width: $base-width;
    margin: 0 auto;
    display: flex;
    flex-wrap: wrap;
    &__item {
      display: flex;
      align-items: center;
      width: calc((100% - 20px) / 2);
      background: #fff;
      border-radius: 5px;
      margin: 0 0 20px;
      padding: 19px 28px;
      a {
        font-size: 2.2rem;
        font-weight: 700;
        text-decoration: none;
        color: $font-color-base;
        @include mq(pc) {
          &:hover {
            color: $color-theme;
            text-decoration: underline;
          }
        }
      }
      img {
        display: inline-block;
        vertical-align: middle;
        margin-right: 16px;
      }
      &:nth-child(2n) {
        margin-left: 20px;
      }
    }
  }
}

@include mq(sp) {
  /*=================================
    category
  =================================*/
  .category {
    background: url(../img/common/bg_category_sp.jpg) no-repeat center top;
    background-size: cover;
    padding: 40px per(20) 40px 0;
    margin: 0 0 40px;
    &::after {
      content: none;
    }
    &__ttl {
      text-align: left;
      .ttl {
        font-size: 2.4rem;
        line-height: 1;
        margin: 0 0 27px;
        &::before {
          width: 8px;
          height: 8px;
          margin: -4px 9px 0 0;
        }
      }
      .text {
        font-size: 1.4rem;
      }
    }
    &Wrap {
      padding: 40px per(30) 28px;
    }
    &List {
      display: block;
      &__item {
        justify-content: left;
        width: 100%;
        border-radius: 3px;
        margin: 0 0 12px;
        padding: 13px per(20);
        a {
          font-size: 1.6rem;
          line-height: 1.25;
        }
        img {
          width: 30px;
          height: 30px;
          object-fit: contain;
        }
        &:nth-child(2n) {
          margin-left: 0;
        }
      }
    }
  }

}
@use "../global" as *;
/* =================================
  header
================================= */
.header {
  width: 100%;
  background: #fff;
  box-shadow: 0 5px 25px rgba(34, 34, 34, .05);
  &__logo {
    max-width: calc(1270px + 2%);
    width: 50%;
    margin-bottom: -82px;
    margin-right: auto;
    padding: 30px 1%;
    .logo {
      display: inline-block;
      vertical-align: middle;
      margin-right: 36px;
      @include mq(tab) {
        margin-right: vw(36);
      }
      a {
        display: block;
        @include mq(pc) {
          &:hover {
            opacity: .8;
          }
        }
      }
    }
    .text {
      font-size: 1.4rem;
      display: inline-block;
      vertical-align: middle;
      max-width: 13em;
    }
  }
  .contact {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    width: 50%;
    margin: 0 calc((100% - 1270px) / 2) 19px auto;
    padding: 0 1%;
    @include mq(tab) {
      margin: 0 0 19px auto;
    }
    &__wrap {
      text-align: center;
      .contactText {
        font-size: 1.1rem;
        font-weight: 600;
        line-height: 1;
        letter-spacing: .05em;
        display: block;
        margin: 5px 0 0;
        &.sm {
          letter-spacing: 0;
        }
      }
      &.tel {
        padding: 10px 30px;
        position: relative;
        @include mq(tab) {
          padding: vw(10) vw(30);
        }
        .telNum {
          @include font-lato;
          font-size: 2.4rem;
          font-weight: 700;
          line-height: 1.208333;
          color: $font-color-base;
          text-decoration: none;
          display: block;
          position: relative;
          @include mq(pc) {
            pointer-events: none;
          }
          &::before {
            content: "";
            width: 22px;
            height: 22px;
            display: inline-block;
            mask-image: url(../img/common/icon/icon_tel.svg);
            mask-size: contain;
            mask-repeat: no-repeat;
            mask-position: center;
            background-color: $font-color-base;
            background-size: contain;
            position: absolute;
            top: 5px;
            left: -30px;
            @include mq(tab) {
              width: 20px;
              height: 20px;
              left: -25px;
            }
          }
        }
      }
      &.reservation {
        background: $color-yellow;
        border-radius: 5px;
        box-shadow: 0 3px 0 #957725;
        padding: 7px 20px 10px 48px;
        margin: 0 0 0 10px;
        position: relative;
        @include mq(tab) {
          padding: vw(7) vw(20) vw(10) vw(48);
        }
        &::before {
          content: "";
          width: 20px;
          height: 14px;
          display: inline-block;
          mask-image: url(../img/common/icon/icon_mail.svg);
          mask-size: contain;
          mask-repeat: no-repeat;
          mask-position: center;
          background-color: $font-color-base;
          background-size: contain;
          position: absolute;
          top: 50%;
          left: 19px;
          transform: translateY(-50%);
          @include mq(tab) {
            width: 18px;
            height: 12px;
            left: 10px;
          }
        }
        @include animation-base(all,0s);
        @include mq(pc) {
          &:hover {
            box-shadow: none;
            transform: translateY(3px);
          }
        }
        .reservationLink {
          font-size: 1.9rem;
          font-weight: 500;
          line-height: 1.421052;
          color: $font-color-base;
          text-decoration: none;
          display: block;
        }
      }
    }
  }
  .gNav {
    &__inner {
      display: flex;
      flex-direction: column-reverse;
    }
    &List {
      background: $color-theme;
      padding: 0 1%;
      &__inner {
        max-width: calc($base-width + 16px);
        margin: 0 auto;
        display: flex;
        flex-wrap: wrap;
        justify-content: space-between;
      }
      &__item {
        display: inline-block;
        position: relative;
        &::after {
          content: "";
          width: 0;
          height: 4px;
          display: block;
          background: #fff;
          position: absolute;
          left: 50%;
          bottom: 0;
          transform: translateX(-50%);
          z-index: 0;
          @include animation-base(all,.1s);
        }
        @include mq(pc) {
          &:hover {
            &::after {
              width: 100%;
            }
          }
        }
        a {
          font-size: 1.6rem;
          font-weight: 500;
          color: #fff;
          line-height: 1.428571;
          text-decoration: none;
          padding: 29px 8px;
          display: block;
          position: relative;
          z-index: 2;
        }
        &.current-menu-item {
          a {
            color: $color-theme;
            &::after {
              width: 100%;
            }
          }
        }
      }
    }
  }
}

@media screen and (min-width: 920px) {
  .gNavList {
    &.fixedNav {
      position: fixed;
      top: 0;
      left: 0;
      right: 0;
      width: 100%;
      box-shadow: 0 5px 25px rgba(34, 34, 34, .3);
      z-index: 999;
      animation: header-show .5s ease-in;
    }
  }
}
@media screen and (max-width: 920px) {
  /* =================================
    header
  ================================= */
  .header {
    padding: 0;
    position: fixed;
    top: 0;
    left: 0;
    z-index: 9999;
    &__inner {
      display: block;
    }
    &__logo {
      width: calc(100% - 60px);
      min-height: 60px;
      padding: 11px 0 11px per(13);
      background: #fff;
      .logo {
        width: vw(192,$sp-width);
        line-height: 1;
      }
      .text {
        display: none;
      }
    }
    .contact {
      display: block;
      margin: 40px auto 0;
      width: 100%;
      &__wrap {
        .contactText {
          font-size: 1.4rem;
          &.sm {
            font-size: 1.3rem;
          }
        }
        &.tel {
          padding: 16px per(30);
          border-radius: 5px;
          background: #fff;
          position: relative;
          &::before {
            content: "";
            width: 30px;
            height: 30px;
            display: inline-block;
            mask-image: url(../img/common/icon/icon_tel.svg);
            mask-size: contain;
            mask-repeat: no-repeat;
            mask-position: center;
            background-color: $font-color-base;
            background-size: contain;
            position: absolute;
            top: 50%;
            left: per(30);
            transform: translateY(-50%);
          }
          .telNum {
            font-size: 3.1rem;
            line-height: 1.225806;
            &::before {
              content: none;
            }
          }
        }
        &.reservation {
          box-shadow: 0 4px 0 #957725;
          padding: 16px per(30);
          margin: 20px 0 0;
          &::before {
            width: 31px;
            height: 22px;
            left: per(35);
          }
          .reservationLink {
            font-size: 2.2rem;
            line-height: 1.454545;
          }
        }
      }
    }
    .gNav {
      overflow-y: scroll;
      max-height: 90vh;
      width: 100%;
      margin: auto;
      padding: 40px per(25);
      position: fixed;
      top: 60px;
      left: 0;
      z-index: 9999;
      transform: scale(.7);
      opacity: 0;
      visibility: hidden;
      transition: opacity .5s ease-in-out, transform .3s ease-out,visibility .2s ease-out;
      &__inner {
        display: block;
      }
      &List {
        background: none;
        &__inner {
          display: block;
        }
        &__item {
          display: block;
          margin: 0;
          &::after {
            content: none;
          }
          a {
            font-size: 1.4rem;
            color: $font-color-base;
            padding: 20px 40px 20px per(15);
            border-radius: 5px;
            background: #fff;
            &::after {
              content: "";
              width: 20px;
              height: 20px;
              display: block;
              background: url(../img/common/icon/icon_gnav_arrow.svg) no-repeat center center;
              background-size: contain;
              position: absolute;
              right: 12px;
              top: 50%;
              transform: translateY(-50%);
            }
          }
          & + .gNavList__item {
            margin-top: 20px;
          }
        }
      }
      &.show {
        transform: scale(1);

        visibility: visible;
        opacity: 1;
      }
      &.hide {
        transform: scale(.7);
        opacity: 0;
        visibility: hidden;
      }
    }
    .headerNav__cover {
      height: 0;
      width: 100vw;
      background: url(../img/common/bg_burger_gray.svg) no-repeat right bottom $color-theme;
      position: fixed;
      top: 60px;
      left: 0;
      display: none;
      z-index: 9998;
      &.show {
        display: block;
        animation: show .2s linear 0s;
      }
      &.hide {
        display: none;
        animation: hide .2s linear 0s;
      }
    }

    /*バーガーボタン設定*/
    .burger {
      width: 60px;
      height: 60px;
      background: #fff;
      margin: auto;
      border-left: 2px solid #F6F5F3;
      position: absolute;
      right: 0;
      top: 0;
      cursor: pointer;
      z-index: 9999;
      &::after {
        content: "";
        width: 18px;
        height: 16px;
        display: inline-block;
        background: url(../img/common/btn_burger_open.svg) no-repeat center center;
        background-size: contain;
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%,-50%);
      }
      /*クリック後、バツボタンになる*/
      &.is-open {
        &::after {
          width: 16px;
          height: 16px;
          background: url(../img/common/btn_burger_close.svg) no-repeat center center;
        }
      }
    }
  }
}
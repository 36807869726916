@use "../global" as *;
/*=================================
footer
=================================*/
.footer {
  background: #fff;
  &__inner {
    max-width: calc(1260px + 2%);
    margin: 0 auto;
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    padding: 30px 1%;
  }
  &__logo {
    width: 200px;
  }
  &__sns {
    margin-left: 66px;
    .snsList {
      &__item {
        width: 20px;
        display: inline-block;
        margin-left: 10px;
        a {
          display: block;
          line-height: 1;
          @include mq(pc) {
            &:hover {
              img {
                filter: invert(26%) sepia(11%) saturate(3171%) hue-rotate(184deg) brightness(102%) contrast(93%);
              }
            }
          }
        }
      }
    }
  }
  &__cont {
    margin-left: auto;
  }
  .fNavList {
    &__item {
      display: inline-block;
      margin-left: 30px;
      a {
        font-size: 1.4rem;
        font-weight: 400;
        color: $font-color-base;
        text-decoration: none;
        @include mq(pc) {
          &:hover {
            text-decoration: underline;
            color: $color-link;
          }
        }
      }
    }
  }
  .copyWrap {
    background: $color-theme;
    padding: 16px 1%;
    &__inner {
      max-width: 1260px;
      margin: 0 auto;
      display: flex;
      justify-content: space-between;
      > * {
        font-size: 1.2rem;
        line-height: 1.5;
        color: #fff;
      }
    }
  }
  .copy {
    @include font-lato;
  }
}

.links {
  padding: 46px 1% 48px;
  background: rgb(232,236,245);
  background: -moz-linear-gradient(95deg, rgba(232,236,245,1) 0%, rgba(230,226,215,1) 100%);
  background: -webkit-linear-gradient(95deg, rgba(232,236,245,1) 0%, rgba(230,226,215,1) 100%);
  background: linear-gradient(95deg, rgba(232,236,245,1) 0%, rgba(230,226,215,1) 100%);
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#e8ecf5",endColorstr="#e6e2d7",GradientType=1);
  &__text {
    font-size: 1.6rem;
    text-align: center;
  }
  &Box {
    max-width: 1000px;
    margin: 0 auto;
    display: flex;
    flex-wrap: wrap;
    .linksBox__item {
      display: flex;
      align-items: center;
      background: #fff;
      box-shadow: 0 3px 15px rgba(34,34,34,.05);
      border-radius: 10px;
      width: calc((100% - 40px) / 2);
      padding: 32px 24px;
      margin-top: 38px;
      @include mq(pc) {
        &:hover {
          box-shadow: 0 3px 15px rgba(34,34,34,.3);
          .textWrap .link {
            text-decoration: underline;
          }
        }
      }
      @include mq(tab) {
        width: calc((100% - vw(40)) / 2);
      }
      &:nth-child(2n) {
        margin-left: 40px;
        @include mq(tab) {
          margin-left: vw(40);
        }
      }
      &.blue {
        position: relative;
        padding-right: 74px;
        &::after {
          content: "";
          width: 43px;
          height: 43px;
          display: inline-block;
          background: url(../img/common/icon/icon_arrow_blue_pc.png) no-repeat center center;
          background-size: contain;
          position: absolute;
          top: 50%;
          right: 24px;
          transform: translateY(-50%);
        }
        @include mq(pc) {
          &:hover {
            &::after {
              right: 20px;
            }
          }
        }
      }
      &.yellow {
        position: relative;
        padding-right: 74px;
        &::after {
          content: "";
          width: 43px;
          height: 43px;
          display: inline-block;
          background: url(../img/common/icon/icon_arrow_yellow_pc.png) no-repeat center center;
          background-size: contain;
          position: absolute;
          top: 50%;
          right: 24px;
          transform: translateY(-50%);
        }
        .textWrap .link {
          &::before {
            background: #776231;
          }
        }
        @include mq(pc) {
          &:hover {
            &::after {
              right: 20px;
            }
          }
        }
      }
      .img {
        width: 100px;
        height: auto;
        margin-right: 14px;
        & + .textWrap {
          width: calc(100% - 114px);
        }
      }
      .textWrap {
        .link {
          font-size: 1.8rem;
          font-weight: 700;
          color: $font-color-base;
          text-decoration: none;
          padding-left: 18px;
          position: relative;
          &:before {
            content: "";
            width: 10px;
            height: 10px;
            display: inline-block;
            background: $color-theme;
            border-radius: 50px;
            position: absolute;
            top: 10px;
            left: 0;
          }
        }
        .text {
          font-size: 1.4rem;
          line-height: 1.71428;
          padding-left: 17px;
          margin-top: 12px;
        }
      }
    }
  }
}

.fixedBnr {
  padding: 14px 5%;
  position: fixed;
  left: 0;
  bottom: 0;
  width: 100%;
  background: rgba(255, 234, 176, .8);
  backdrop-filter: blur(2px);
  opacity: 0;
  z-index: 99;
  @include animation-base;
  &__inner {
    max-width: $base-width;
    margin: 0 auto;
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 20px;
  }
  &__ttl {
    font-size: 2rem;
    font-weight: 700;
    .em {
      font-size: 2.8rem;
      color: #dd1515;
    }
  }
  &__btn {
    font-size: 2.4rem;
    font-weight: 700;
    color: #fff;
    text-decoration: none;
    background: #dd1515;
    box-shadow: 0 5px 0 #9f2b22;
    display: inline-block;
    border-radius: 5px;
    padding: 4px 20px;
    white-space: nowrap;
    @include animation-base(all, 0s);
    .em {
      font-size: 3rem;
      display: inline-block;
    }
    @include mq(pc) {
      &:hover {
        box-shadow: none;
        transform: translateY(5px);
      }
    }
  }
  &.fixed {
    opacity: 1;
  }
}

@include mq(sp) {
  /*=================================
    footer
  =================================*/
  .footer {
    &__inner {
      display: block;
      padding: 30px per(11);
      text-align: center;
    }
    &__logo {
      width: 170px;
      margin: 0 auto;
    }
    &__sns {
      margin: 32px 0 0;
      .snsList {
        &__item {
          width: 26px;
          margin: 0 10px;
        }
      }
    }
    &__cont {
      margin: 30px auto 0;
    }
    .fNavList {
      &__item {
        margin: 0 10px;
      }
    }
    .copyWrap {
      padding: 19px per(11);
      text-align: center;
      &__inner {
        display: block;
      }
    }
  }
  .links {
    padding: 40px per(17);
    &__text {
      font-size: 1.4rem;
      line-height: 1.857142;
      text-align: left;
      margin-bottom: 30px;
    }
    &Box {
      display: block;
      .linksBox__item {
        align-items: flex-start;
        border-radius: 5px;
        width: 100%;
        padding: 20px per(15);
        margin-top: 20px;
        &:nth-child(2n) {
          margin-left: 0;
        }
        &.blue {
          padding-right: per(65);
          &::after {
            width: 32px;
            height: 32px;
            background: url(../img/common/icon/icon_arrow_blue_sp.png) no-repeat center center;
            background-size: contain;
            right: per(20);
          }
        }
        &.yellow {
          padding-right: per(65);
          &::after {
            width: 32px;
            height: 32px;
            background: url(../img/common/icon/icon_arrow_yellow_sp.png) no-repeat center center;
            background-size: contain;
            right: per(20);
          }
        }
        .img {
          width: 74px;
          margin-right: 10px;
          & + .textWrap {
            width: calc(100% - 84px);
          }
        }
        .textWrap {
          .link {
            font-size: 1.6rem;
            &:before {
              width: 8px;
              height: 8px;
              top: 8px;
            }
          }
          .text {
            margin-top: 9px;
          }
        }
      }
    }
  }
  .fixedBnr {
    padding: 8px per(11) 12px;
    &__inner {
      display: block;
    }
    &__ttl {
      font-size: 1.5rem;
      text-align: center;
      .em {
        font-size: 2rem;
      }
    }
    &__btn {
      font-size: 2rem;
      box-shadow: 0 3px 0 #9f2b22;
      padding: 5px per(11);
      display: block;
      text-align: center;
      margin-top: 10px;
      .em {
        font-size: 2.2rem;
      }
    }
  }  
}

@use "../../global" as *;

/*=================================
  title
=================================*/
.ttl--base {
  font-size: 4.5rem;
  font-weight: 700;
  line-height: 1.5;
  margin-bottom: 50px;
  &::before {
    content: "";
    width: 12px;
    height: 12px;
    display: inline-block;
    vertical-align: middle;
    border-radius: 50px;
    background: $color-theme;
    margin: -5px 15px 0 0;
  }
  &:not(.left) {
    text-align: center;
  }
  &.white {
    color: #fff;
    &::before {
      background: #fff;
    }
  }
  & + .ttl--text {
    margin-top: -40px;
    margin-bottom: 50px;
  }
}
.ttl--page {
  padding: 30px 1% 0;
  margin-bottom: 30px;
  &__inner {
    max-width: $base-width;
    margin: 0 auto;
  }
  .ttl {
    font-size: 4.5rem;
    font-weight: 700;
    line-height: 1.33333;
    padding-left: 25px;
    position: relative;
    &::before {
      content: "";
      width: 12px;
      height: 12px;
      display: inline-block;
      vertical-align: middle;
      border-radius: 50px;
      background: $color-theme;
      position: absolute;
      top: 25px;
      left: 0;
    }
  }
  .text {
    font-size: 1.6rem;
    font-weight: 400;
    margin-top: 7px;
  }
}

@media screen and (max-width: $display-width-s) {
  /*=================================
    title
  =================================*/
  .ttl--base {
    font-size: 2rem;
    line-height: 1.55;
    margin-bottom: 20px;
    & + .ttl--text {
      margin-top: -10px;
      margin-bottom: 20px;
    }
  }
  .ttl--page {
    padding: 0 per(20);
    margin-bottom: 30px;
    .ttl {
      font-size: 2.4rem;
      padding-left: 15px;
      &::before {
        width: 8px;
        height: 8px;
        top: 13px;
      }
    }
    .text {
      font-size: 1.4rem;
      font-weight: 400;
      line-height: 1.714285;
    }
  }
}

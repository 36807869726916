@use "../../global" as *;

/*=================================
  button
=================================*/
.btnWrap {
  margin: 20px auto 30px;
  text-align: center;
  &.center {
    text-align: center;
  }
  &.right {
    text-align: right;
  }

  > * + * {
    margin-top: 9px;
  }
}
.btn--blue {
  font-size: 1.4rem;
  font-weight: 500;
  color: #fff;
  display: inline-block;
  text-align: center;
  text-decoration: none;
  background: $color-theme;
  padding: 15px;
}
.btn--yellow {
  font-size: 2.1rem;
  font-weight: 700;
  color: $font-color-base;
  display: inline-block;
  text-align: center;
  text-decoration: none;
  background: #FECB3F;
  border-radius: 5px;
  box-shadow: 0 4px 0 #957725;
  padding: 22px per(5);
  position: relative;
  width: 100%;
  text-align: center;
  .icon {
    width: 28px;
    margin: 0 10px 4px 0;
  }
}
.btn--gray {
  font-size: 1.9rem;
  font-weight: 700;
  line-height: 1.05;
  color: #fff;
  display: inline-block;
  text-align: center;
  text-decoration: none;
  background: #333333;
  box-shadow: 0 5px 0 #202427;
  display: block;
  padding: 28px 30px;
  position: relative;
  @include animation-base(all,0s);
  @include mq(tab) {
    padding: vw(33) vw(225);
  }
  &::after {
    content: "";
    width: 12px;
    height: 19px;
    display: inline-block;
    vertical-align: middle;
    background: url(../img/icon/icon_arrow_w.png) no-repeat center center;
    background-size: contain;
    position: absolute;
    top: 50%;
    right: 16px;
    transform: translateY(-50%);
  }
}
.btn--blueShadow {
  font-size: 1.4rem;
  font-weight: 500;
  color: #fff;
  display: inline-block;
  text-align: center;
  background: $color-blue-dark;
  box-shadow: 0 3px 0 $color-theme;
  border-radius: 4px;
  padding: 23px 22px;
  text-decoration: none;
}
.btn--ghost {
  font-size: 1.5rem;
  font-weight: 500;
  color: #fff;
  display: inline-block;
  text-align: center;
  text-decoration: none;
  padding: 15px 58px;
  border: 1px solid #fff;
  background: rgba(255,255,255,.3);
  position: relative;
  &::after {
    content: "";
    width: 9px;
    height: 15px;
    display: inline-block;
    vertical-align: middle;
    background: url(../img/icon/icon_arrow_w.png) no-repeat center center;
    background-size: contain;
    position: absolute;
    top: 50%;
    right: 24px;
    transform: translateY(-50%);
  }
}
.btn--border {
  font-size: 1.6rem;
  font-weight: 500;
  line-height: 1;
  color: $color-theme;
  border: 3px solid $color-theme;
  background: #fff;
  text-decoration: none;
  text-align: center;
  display: block;
  margin: 0 auto;
  padding: 16px 1%;
  &::before {
    content: "";
    display: inline-block;
    width: 17px;
    height: 17px;
    background: url(../img/btn_arrow.png) no-repeat center center;
    background-size: contain;
    vertical-align: middle;
  }
}
.btn--more {
  font-size: 1.6rem;
  font-weight: 700;
  line-height: 1.375;
  color: $font-color-base;
  border: 1px solid $color-theme;
  text-decoration: none;
  text-align: left;
  display: inline-block;
  margin: 0 auto;
  padding: 17px per(63) 17px per(38);
  background: url(../img/common/icon/icon_arrow_right_sp.png) no-repeat right per(14) center #fff;
  background-size: 29px auto;
  border-radius: 7px;
  position: relative;
  &::before {
    content: "";
    width: 10px;
    height: 10px;
    background: $color-theme;
    display: inline-block;
    vertical-align: middle;
    border-radius: 50px;
    position: absolute;
    top: 24px;
    left: per(20);
  }
}

@include mq(pc) {
  /*=================================
    button
  =================================*/
  .btnWrap {
    display: flex;
    justify-content: center;
    > * + * {
      margin: 0 20px;
    }
  }
  .btn--blue {
    font-size: 2.2rem;
    padding: 23px 37px;
    &:hover {
      opacity: .9;
    }
  }
  .btn--yellow {
    font-size: 2.3rem;
    border-radius: 10px;
    box-shadow: 0 3px 0 #957725;
    padding: 22px 10px;
    max-width: 504px;
    @include animation-base(all,0s);
    .icon {
      width: 37px;
      position: absolute;
      top: 26px;
      left: 45px;
      margin: 0;
    }
    &:hover {
      box-shadow: none;
      transform: translateY(3px);
    }
  }
  .btn--gray {
    font-size: 2rem;
    padding: 33px 225px;
    &::after {
      width: 14px;
      height: 24px;
      right: 16px;
    }
    &:hover {
      box-shadow: none;
      transform: translateY(5px);
    }
  }
  .btn--blueShadow {
    font-size: 2.2rem;
    box-shadow: 0 5px 0 $color-theme;
    padding: 24px 30px;
    transition: none;
    &:hover {
      transform: translateY(5px);
      box-shadow: none;
    }
  }
  .btn--ghost {
    font-weight: 700;
    background: transparent;
    &:hover {
      background: rgba(255,255,255,.2);
    }
  }
  .btn--border {
    font-size: 2.2rem;
    border-width: 4px;
    padding: 20px 66px;
    margin: 0;
    display: inline-block;
    &::before {
      width: 24px;
      height: 24px;
      margin: 0 6px 3px 0;
    }
    &:hover {
      background: $color-blue;
    }
  }
  .btn--more {
    font-size: 2rem;
    line-height: 2;
    text-align: center;
    display: block;
    margin: 0 auto;
    padding: 25px 48px 25px 10px;
    background: url(../img/common/icon/icon_arrow_right_pc.png) no-repeat right 20px center #fff;
    background-size: 38px auto;
    width: 100%;
    max-width: 600px;
    &::before {
      width: 12px;
      height: 12px;
      position: static;
      margin: 0 8px 0 0;
    }
    &:hover {
      color: #fff;
      background: url(../img/common/icon/icon_arrow_right_pc.png) no-repeat right 20px center $color-theme;
      &::before {
        background: #fff;
      }
    }
  }
}